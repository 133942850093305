import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Box, Divider, Grid, Stack } from "@mui/material";
import ApiService from "app/services/config";
import { consultarCEP } from "app/utils/consultarCEP";
import axios from "axios";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import { updateInputValue } from "app/utils/appHelpers";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import { ESTADOS_BRASIL } from "app/utils/estados";
import ActionButton from "app/components/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import { PermissionContext } from "app/contexts/PermissionContext";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const CAMPOS_OBRIGATORIOS = {
  nomeUnidade: "Nome da Unidade",
  cnpj: "CNPJ",
};

const validationSchema = yup.object().shape({
  userId: yup.number().optional(),
  nomeUnidade: yup.string("Campo obrigatório").required("Campo obrigatório"),
  cnpj: yup
    .string()
    .nullable()
    .test("valid-cnpj-length", "CNPJ inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 14;
    }),
  cep: yup
    .string()
    .nullable()
    .test("valid-cep-length", "CEP inválido", (value) => {
      if (!value) return true;
      return value.replace(/\D/g, "").length === 8;
    })
    .typeError("CEP Inválido"),
});

const UnidadeClienteForm = () => {
  const initialValues = {
    userId: "",
    nomeUnidade: "",
    razaoSocial: "",
    cnpj: "",
    inscricaoEstadual: "",
    inscricaoMunicipal: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    setorQuadraLote: "",
    estado: "",
    cidade: "",
  };
  const { id, clienteId } = useParams();
  const userId = localStorage.getItem("id");
  const { theme } = useJumboTheme();
  const Swal = useSwalWrapper();
  const location = useLocation();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [unidade, setUnidade] = useState(initialValues);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [skipped, setSkipped] = React.useState(new Set());
  const [cnpjFormatado, setCnpjFormatado] = useState();
  const [cepFormatado, setCepFormatado] = useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);
  const permissionCreate = hasPermission("Unidades", "create");
  const permissionUpdate = hasPermission("Unidades", "update");
  const permissionDelete = hasPermission("Unidades", "delete");

  if (!permissionCreate) {
    navigate(`/app/cliente/unidades/${clienteId}`);
  }

  const formikRef = useRef();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setMunicipios(response.data);
          }
        });
    },
    [estados]
  );

  const handleChangeCEP = async (cep) => {
    formikRef.current.setFieldValue("cep", cep);
    if (cep.length === 9) {
      try {
        const cepConsultado = await consultarCEP(cep);
        if (cepConsultado) {
          formikRef.current.setFieldValue(
            "estado",
            cepConsultado.dados.stateShortname
          );
          await getMunicipios(cepConsultado.dados.stateShortname);
          formikRef.current.setFieldValue("cidade", cepConsultado.dados.city);
          formikRef.current.setFieldValue(
            "logradouro",
            cepConsultado.dados.street
          );
          formikRef.current.setFieldValue(
            "bairro",
            cepConsultado.dados.district
          );
        }
        setCepFormatado(
          String(cep)
            .slice(0, 5)
            .concat(`-${String(cep).slice(5, 9)}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getUnidade = async () => {
    try {
      await ApiService.get(`/unidade/${id}`).then(async (response) => {
        if (response.status === 200) {
          const { cnpj, cep, estado } = response.data;
          setUnidade({
            ...response.data,
            userId: Number(userId),
          });
          setCepFormatado(cep);
          setCnpjFormatado(cnpj);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    if (Object.entries(formikRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }
    const allValuesEdit = {
      ...values,
      cliente_id: Number(clienteId),
    };
    delete allValuesEdit.deletedAt;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.alvaraLicenca;
    delete allValuesEdit.alvaraLicencaComplementar;
    delete allValuesEdit.id;
    delete allValuesEdit.tipoUnidade;
    delete allValuesEdit.responsavel;
    delete allValuesEdit.is_arquivada;
    delete allValuesEdit.documentos_complementares;
    delete allValuesEdit.licenca;
    delete allValuesEdit.condicionantes;
    delete allValuesEdit.contrato_id;

    if (id) {
      ApiService.put(`/unidade/${id}`, allValuesEdit)
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate(`/app/cliente/unidades/${clienteId}`);
          }
        })
        .catch((error) => {
          let message = error.response?.data?.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response?.data);
            console.log(error.response?.status);
            console.log(error.response?.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/unidade/criar", allValuesEdit)
        .then((response) => {
          toast("success", "Criado com sucesso");
          // resetForm();
          if (response.status === 201) {
            navigate(`/app/cliente/unidades/${clienteId}`);
          }
        })
        .catch((error) => {
          let message = error?.response?.data?.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response?.data);
            console.log(error.response?.status);
            console.log(error.response?.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error?.message);
          }
        });
    }
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/clientes/breadcrumbs?clienteId=${clienteId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await Promise.all([getEstados()]);
    };
    fetchData();
    if (id) {
      getUnidade();
    }
    setUnidade((prevDados) => ({
      ...prevDados,
      userId: Number(userId),
    }));
    getBreadCrumbsInfo();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
    setIsDadosCarregados(true);
  }, []);

  useEffect(async () => {
    if (unidade) {
      await getMunicipios(unidade?.estado);
    }
  }, [unidade]);

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar a unidade?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteUnidade(id);
      }
    });
  };

  const deleteUnidade = useCallback(async (id) => {
    try {
      ApiService.delete(`/unidade/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(`/app/cliente/unidades/${clienteId}`);
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <JumboContentLayout layoutOptions={layoutOptions}>
        <BotaoVoltarBreadCrumbs
          handleBack={() => {
            navigate(`/app/cliente/unidades/${clienteId}`);
          }}
        />
        <BadgeNomeCliente
          nome={breadCrumbsInfo?.nome}
          linkTo={`/app/editar-cliente/${clienteId}`}
        />
        <PageHeader
          title={id ? `Editar unidade` : "Unidade > Nova unidade"}
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />
        {isDadosCarregados && (
          <Formik
            innerRef={formikRef}
            initialValues={unidade}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnChange={true}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
              setSubmitting,
              errors,
              isValid,
              resetForm,
            }) => (
              <Form style={{ width: "100%" }} noValidate autoComplete="off">
                <>
                  <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={6}>
                      <InputAmbiental
                        name={"nomeUnidade"}
                        label={"Nome ou apelido da unidade"}
                        required={true}
                        onChange={handleChange}
                        value={values.nomeUnidade}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputAmbiental
                        name={"razaoSocial"}
                        label={"Razão social"}
                        required={false}
                        onChange={handleChange}
                        value={values.razaoSocial}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={6}>
                      <InputAmbiental
                        name={"cnpj"}
                        label={"CNPJ"}
                        required={false}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "cnpj")}
                        fullWidth={true}
                        hasMask={true}
                        maskType={"cnpj"}
                      />
                      {errors && errors.cnpj ? (
                        <div style={{ color: "red" }}>{errors.cnpj}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={3}>
                      <InputAmbiental
                        name={"inscricaoEstadual"}
                        label={"Inscrição Estadual"}
                        required={false}
                        onChange={handleChange}
                        value={values.inscricaoEstadual}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputAmbiental
                        name={"inscricaoMunicipal"}
                        label={"Inscrição Municipal"}
                        required={false}
                        onChange={handleChange}
                        value={values.inscricaoMunicipal}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Box my={2} p={2}>
                    <h2>Endereço</h2>
                    <Divider sx={{ my: 2 }} />
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                  >
                    <Grid item xs={2} alignItems={"flex-start"}>
                      <InputAmbiental
                        label={"CEP"}
                        required={false}
                        name={"cep"}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "cep")}
                        fullWidth={true}
                        hasMask={true}
                        maskType={"cep"}
                      />
                      {errors && errors.cep ? (
                        <div style={{ color: "red" }}>{errors.cep}</div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <InputAmbiental
                        label={"Logradouro"}
                        required={false}
                        name={"logradouro"}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "logradouro")}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputAmbiental
                        label={"Número"}
                        required={false}
                        name={"numero"}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "numero")}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                  >
                    <Grid item xs={5} alignItems={"flex-start"}>
                      <InputAmbiental
                        label={"Bairro"}
                        required={false}
                        name={"bairro"}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "bairro")}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <InputAmbiental
                        label={"Cidade"}
                        required={false}
                        name={"cidade"}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "cidade")}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SelectAmbiental
                        name={"estado"}
                        label={"UF"}
                        value={values.estado}
                        options={ESTADOS_BRASIL}
                        fullWidth={true}
                        nameOptionValue={"sigla"}
                        nameOptionDescription={"nome"}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignContent={"center"}
                    justifyContent={"center"}
                    display={"flex"}
                    width={"100%"}
                  >
                    <Grid item xs={12} alignItems={"flex-start"}>
                      <InputAmbiental
                        label={"Complemento"}
                        required={false}
                        name={"complemento"}
                        onChange={handleChange}
                        value={updateInputValue(unidade, values, "complemento")}
                        fullWidth={true}
                      />
                    </Grid>
                    <input
                      hidden
                      type="number"
                      name="userId"
                      value={Number(userId)}
                    />
                  </Grid>
                </>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  my={3}
                  sx={{
                    position: "sticky",
                    right: 0,
                    bottom: 10,
                  }}
                >
                  {isDeleteMode ? (
                    <ActionButton
                      title={"Excluir Unidade"}
                      to={null}
                      color="red"
                      icon={<DeleteIcon />}
                      isSubmitAction={false}
                      disabled={!permissionDelete}
                      action={() => {
                        modalAlert(id);
                      }}
                    />
                  ) : (
                    <ActionButton
                      title={id ? "Atualizar" : "Criar"}
                      to={null}
                      color="blue"
                      icon={<BorderColorIcon />}
                      disabled={!permissionCreate || !permissionUpdate}
                      isSubmitAction={true}
                      action={() => {
                        handleSubmit(
                          values,
                          setSubmitting,
                          resetForm,
                          setFieldValue
                        );
                      }}
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </JumboContentLayout>
    </Box>
  );
};

export default UnidadeClienteForm;
