import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import CardPainel from "app/components/CardPainel";
import { PermissionContext } from "app/contexts/PermissionContext";
import React, { useContext, useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ApiService from "app/services/config";

const PainelControle = () => {
  const [estatisticas, setEstatisticas] = useState({
    contratos: {
      total: 0,
      porAno: {},
    },
    clientes: 0,
    colaboradores: 0,
  });

  const anoAtual = new Date().getFullYear();

  const handleGetEstatisticas = async () => {
    try {
      await ApiService.get(`/contratos/ano/${anoAtual}`).then((response) => {
        if (response.status === 200) {
          setEstatisticas((prevEstatisticas) => ({
            ...prevEstatisticas,
            contratos: {
              total: response.data.totalGeralContratos,
              porAno: {
                anoAtual: response.data.totalContratosAnoAtual,
                anoPassado: response.data.totalContratosAnoPassado,
                anoRetrasado: response.data.totalContratosAnoRetrasado,
              },
            },
          }));
        }
      });
      await ApiService.get('/clientes/total/clientes')
      .then((response) => {
        if(response.status === 200) {
          setEstatisticas((prevEstatisticas) => ({
            ...prevEstatisticas,
            clientes: response.data.totalClientes
          }));
        }
      })
      await ApiService.get('/users/colaboradores/total')
      .then((response) => {
        if(response.status === 200) {
          setEstatisticas((prevEstatisticas) => ({
            ...prevEstatisticas,
            colaboradores: response.data.totalColaboradores
          }));
        }
      })
    } catch (error) {
      console.error(error);
    }
  };

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Painel de controle", "read")) {
    window.location.href = "/";
  }

  useEffect(() => {
    handleGetEstatisticas();
  }, []);

  return (
    <JumboContentLayout>
      <CardPainel
        icon={
          <DescriptionIcon
            style={{ color: "#4E5526", width: "120px", height: "auto" }}
          />
        }
        totalGeral={`${estatisticas.contratos.total} contratos`}
        totalPorAno={[
          `${anoAtual} - ${estatisticas.contratos.porAno["anoAtual"]} contratos`,
          `${anoAtual - 1} - ${
            estatisticas.contratos.porAno["anoPassado"]
          } contratos`,
          `${anoAtual - 2} - ${
            estatisticas.contratos.porAno["anoRetrasado"]
          } contratos`,
        ]}
        to={"/app/listar-contratos"}
      />
      <CardPainel
        icon={
          <HandshakeIcon
            style={{ color: "#4E5526", width: "120px", height: "auto" }}
          />
        }
        totalGeral={`${estatisticas.clientes} clientes`}
        to={"/app/listar-clientes"}
        marginTop={10}
      />
      <CardPainel
        icon={
          <GroupIcon
            style={{ color: "#4E5526", width: "120px", height: "auto" }}
          />
        }
        totalGeral={`${estatisticas.colaboradores} colaboradores`}
        to={"/app/listar-colaboradores"}
        marginTop={10}
      />
    </JumboContentLayout>
  );
};

export default PainelControle;
