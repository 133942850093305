export const mascaraCNPJ = (cnpj) => {
    return cnpj?.replace(/\D/g, "")?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  };
  
  export const mascaraCPF = (cpf) => {
    return cpf?.replace(/\D/g, "")?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  };
  
  export const mascaraCEP = (cep) => {
    return cep?.replace(/\D/g, "")?.replace(/^(\d{5})(\d{3})$/, "$1-$2");
  };
  
  export const mascaraCelular = (celular) => {
    return celular?.replace(/\D/g, "")?.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  };
  
  export const mascaraFixo = (fixo) => {
    return fixo?.replace(/\D/g, "")?.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
  };
  