import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Description from "@mui/icons-material/Description";
import moment from "moment";
import { tiposArquivos } from "app/utils/tiposArquivos";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import ActionButton from "app/components/ActionButton/ActionButton";
import DescriptionIcon from "@mui/icons-material/Description";
import QueueIcon from "@mui/icons-material/Queue";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import PaginationComponent from "app/components/Pagination/Pagination";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const DocumentosServicosList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId, servicoId, procadmin } =
    useParams();
  const [documentos, setDocumentos] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [selectedDocumentosIds, setSelectedDocumentosIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [todosDocumentosSelecionados, setTodosDocumentosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosDocumentos = () => {
    const todosIdsDocumentos = documentos.map((documento) => documento.id);
    if (!todosDocumentosSelecionados) {
      setSelectedDocumentosIds(todosIdsDocumentos);
    } else {
      setSelectedDocumentosIds([]);
    }
    setTodosDocumentosSelecionados(!todosDocumentosSelecionados);
  };

  const handleDeletarMuitosDocumentos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os documentos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosDocumentos();
      }
    });
  };

  const deleteMuitosDocumentos = useCallback(async () => {
    try {
      await ApiService.put(
        "/documentos/servicos/deletar/varios",
        selectedDocumentosIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Serviços removidos com sucesso!");
          getDocumentos();
          setSelectedDocumentosIds([]);
          setPage(0);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os serviços selecionados");
      console.log(error);
    }
  }, [selectedDocumentosIds]);

  const getDocumentos = useCallback(async () => {
    try {
      let apiUrl = `/documentos/servicos/servico/${servicoId}?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.documentos.map((documento) => ({
            ...documento,
            isSelected: selectedDocumentosIds.includes(documento.id),
          }));
          setDocumentos(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedDocumentosIds]);

  useEffect(() => {
    getDocumentos();
  }, [getDocumentos]);

  const handleCheckboxChange = (documentoId) => {
    setDocumentos((prevDocs) =>
      prevDocs.map((documento) =>
        documento.id === documentoId
          ? { ...documento, isSelected: !documento.isSelected }
          : documento
      )
    );

    setSelectedDocumentosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(documentoId)) {
        return prevSelectedIds.filter((id) => id !== documentoId);
      } else {
        return [...prevSelectedIds, documentoId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedDocumentosIds.length > 0);
    setTodosDocumentosSelecionados(false);
  };

  const modalAlert = (documentoId, isDeleteEmMassa) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o documento?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (!isDeleteEmMassa) {
          deleteDocumento(documentoId);
        } else {
          handleDeletarMuitosDocumentos();
        }
      }
    });
  };

  const deleteDocumento = useCallback(async (documentoId) => {
    try {
      ApiService.delete(`/documentos/servicos/${documentoId}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            getDocumentos();
            setPage(0);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const documentoId = tableMeta.rowData[1];
          const documento = documentos.find(
            (documento) => documento.id === documentoId
          );
          return (
            <Checkbox
              checked={documento.isSelected}
              onChange={() => handleCheckboxChange(documentoId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "path",
      label: "Caminho",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "nome",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const pathDocumento = tableMeta.rowData[2];
          const extensao = String(pathDocumento).split(".")[1];
          return (
            <>
              <Tooltip
                title={`${tiposArquivos[extensao].tipo} ${tiposArquivos[extensao].nome}`}
              >
                <Box
                  style={{
                    background: tiposArquivos[extensao]?.cor,
                    color: "#FFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30px",
                    height: "30px",
                    padding: 1,
                  }}
                  borderRadius={1}
                >
                  {tiposArquivos[extensao]?.nome}
                </Box>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const documentoId = tableMeta.rowData[1];
          const pathDocumento = tableMeta.rowData[2];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Visualizar Documento">
                <Link
                  to={`${process.env.REACT_APP_API_KEY}/public/uploads/documentos/servicos/${pathDocumento}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <VisibilityIcon
                    cursor="pointer"
                    color="info"
                    disabled={!permission}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Editar Documento">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/documentos/${documentoId}/editar`,
                      {
                        state: {
                          contrato:
                            location?.state?.descricao ??
                            documentos[0]?.servico?.processo_contrato?.contrato
                              ?.descricao,
                          processo:
                            location?.state?.processoDescricao ??
                            documentos[0]?.servico?.processo_contrato
                              ?.descricao,
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Documento">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    modalAlert(documentoId);
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedDocumentosIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&servicoId=${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <Box>
      <BackNavigation
        url={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`}
      />
      <Tag label={breadCrumbsInfo?.descricao} sx={{ marginBottom: 3 }} />

      <Typography variant={"h5"} fontWeight={600} mb={3}>
        {
          breadCrumbsInfo?.processos_contratos?.[0]?.processo_administrativo
            ?.descricao
        }
      </Typography>

      <JumboContentLayout
        header={
          <PageHeader
            title={"Documentos"}
            icon="desc"
            btn={
              <ActionButton
                action={() => {
                  navigate(
                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/documentos/novo`,
                    {
                      state: {
                        contrato:
                          location?.state?.descricao ??
                          documentos[0]?.servico?.processo_contrato?.contrato
                            ?.descricao,
                        processo:
                          location?.state?.processoDescricao ??
                          documentos[0]?.servico?.processo_contrato?.descricao,
                      },
                    }
                  );
                }}
                title="Novo documento"
                color="dustyTeal"
                icon={<QueueIcon />}
              />
            }
          />
        }
        layoutOptions={layoutOptions}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField
              variant="standard"
              placeholder="Pesquisar..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
                sx: {
                  borderRadius: 2,
                  border: "1px solid #4E5526",
                  paddingLeft: 2,
                  height: "36px",
                  "&:hover": { borderColor: "#3B4220" },
                  "&.Mui-focused": { borderColor: "#4E5526" },
                  color: "#4E5526",
                  "&::placeholder": { color: "red", opacity: 1 },
                  width: 350,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        backgroundColor: "#4E5526",
                        borderRadius: 2,
                        minWidth: "36px",
                        height: "36px",
                        padding: 0,
                        "&:hover": { backgroundColor: "#3B4220" },
                      }}
                      onClick={() => handleSearch(inputValue)}
                    >
                      <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            {selectedDocumentosIds.length > 0 && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeletarMuitosDocumentos}
                sx={{
                  borderRadius: 2,
                  paddingX: 2,
                  backgroundColor: "#C84E4D",
                  textTransform: "none",
                }}
              >
                Excluir {selectedDocumentosIds.length} documentos
              </Button>
            )}
          </Box>
        </Div>

        <Card
          sx={{
            p: 2,
            width: "100%",
            minHeight: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item lg={1}>
              <Checkbox
                sx={{
                  color: "#696969",
                  "&.Mui-checked": {
                    color: "#95A64A",
                  },
                }}
                onChange={handleSelecionarTodosDocumentos}
              />
            </Grid>
            <Grid item lg={7}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Descrição
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Tipo
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}>
              <Box>
                <Typography fontSize={24} fontWeight={600}>
                  Ações
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>

        <React.Fragment>
          <Grid container mt={3} gap={1}>
            {documentos.length > 0 ? (
              <>
                {documentos.map((d, key) => (
                  <Card
                    sx={{
                      p: 2,
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      // gap={2}
                    >
                      <Grid item lg={1}>
                        <Checkbox
                          sx={{
                            color: "#696969",
                            "&.Mui-checked": {
                              color: "#95A64A",
                            },
                          }}
                          key={d.id}
                          checked={d.isSelected}
                          onChange={() => handleCheckboxChange(d.id)}
                        />
                      </Grid>
                      <Grid item lg={7}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18}>{d.nome}</Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Tooltip
                            title={`${
                              tiposArquivos[String(d.path).split(".")[1]].tipo
                            } ${
                              tiposArquivos[String(d.path).split(".")[1]].nome
                            }`}
                          >
                            <Box
                              style={{
                                background:
                                  tiposArquivos[String(d.path).split(".")[1]]
                                    ?.cor,
                                color: "#FFF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "30px",
                                height: "30px",
                                padding: 1,
                              }}
                              borderRadius={1}
                            >
                              {
                                tiposArquivos[String(d.path).split(".")[1]]
                                  ?.nome
                              }
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>

                      <Grid item lg={2}>
                        <Box display={"flex"} gap={"1rem"}>
                          <Tooltip title="Visualizar Documento">
                            <Link
                              to={`${process.env.REACT_APP_API_KEY}/public/uploads/documentos/servicos/${d.path}`}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <VisibilityIcon
                                cursor="pointer"
                                color="info"
                                disabled={!permission}
                              />
                            </Link>
                          </Tooltip>
                          <Tooltip title="Editar Documento">
                            <EditIcon
                              cursor="pointer"
                              color="info"
                              disabled={!permission}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/documentos/${d.id}/editar`,
                                  {
                                    state: {
                                      contrato:
                                        location?.state?.descricao ??
                                        documentos[0]?.servico
                                          ?.processo_contrato?.contrato
                                          ?.descricao,
                                      processo:
                                        location?.state?.processoDescricao ??
                                        documentos[0]?.servico
                                          ?.processo_contrato?.descricao,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Excluir Documento">
                            <DeleteIcon
                              cursor="pointer"
                              color="error"
                              onClick={() => {
                                modalAlert(d.id);
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum resultado encontrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            <PaginationComponent
              page={page}
              setPage={setPage}
              count={count}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Grid>
        </React.Fragment>
      </JumboContentLayout>
    </Box>
  );
};

export default DocumentosServicosList;
