const exportarDadosCSV = (nome_arquivo, dados, colunas) => {
  const linhasPlanilha = [];

  linhasPlanilha.push(colunas.join(","));

  const acessarValor = (obj, caminho) => {
    const chaves = caminho.split(".");
    let valor = obj;
  
    for (let chave of chaves) {
      if (Array.isArray(valor)) {
        valor = valor.map(item => acessarValor(item, chave)).join(", ");
      } else if (valor && valor.hasOwnProperty(chave)) {
        valor = valor[chave];
      } else {
        valor = "";
        break;
      }
    }
    return valor;
  };

  dados?.forEach((dado) => {
    let linha = {};
    for (const coluna of colunas) {
      let valor = acessarValor(dado, coluna);

      if (coluna === 'elaboradores_orcamento' && Array.isArray(valor)) {
        valor = valor.map(item => item.usuario?.name || "Nome não disponível").join("; ");
      }

      if (coluna === 'roles' && Array.isArray(valor)) {
        valor = valor.map(item => item.Role?.name || "Nome não disponível").join("; ");
      }

      if (valor) {
        linha[coluna] =
          typeof valor === "object" && valor !== null
            ? JSON.stringify(valor)
            : valor.toString();
      }
    }
    linhasPlanilha.push(Object.values(linha).join(","));
  });

  const conteudoCSV = linhasPlanilha.join("\n");

  const planilha = new Blob([conteudoCSV], {
    type: "text/csv;charset=utf-8;",
  });
  const urlPlanilha = URL.createObjectURL(planilha);

  const link = document.createElement("a");
  link.setAttribute("href", urlPlanilha);
  link.setAttribute(
    "download",
    `${nome_arquivo}${new Date().toLocaleDateString("pt-BR")}.csv`
  );
  link.style.display = "none";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default exportarDadosCSV;
