import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import moment from "moment";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import DescriptionIcon from "@mui/icons-material/Description";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import PaginationComponent from "app/components/Pagination/Pagination";
import SearchAndDelete from "app/components/SearchAndDelete/SearchAndDelete";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import OrdenacaoLista from "app/components/OrdenacaoLista";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ServicosContratoList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId, procadmin } = useParams();
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [processoAdministrativo, setProcessoAdministrativo] = useState(null);
  const [selectedProcessosIds, setSelectedProcessosIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState({
    id: null,
    nome: null,
  });
  const [todosProcessosSelecionados, setTodosProcessosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [
    isModalAdicionarImovelClienteAberto,
    setIsModalAdicionarImovelClienteAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }
  const [inputValue, setInputValue] = useState("");

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosProcessos = () => {
    const todosIdsProcessos = processosAdministrativos.map(
      (processo) => processo.id
    );
    if (!todosProcessosSelecionados) {
      setSelectedProcessosIds(todosIdsProcessos);
    } else {
      setSelectedProcessosIds([]);
    }
    setTodosProcessosSelecionados(!todosProcessosSelecionados);
  };

  const handleDeleteMuitosProcessos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os processos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosProcessos();
      }
    });
  };

  const deleteMuitosProcessos = useCallback(async () => {
    try {
      await ApiService.put("/servicos/delete/many", selectedProcessosIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Serviços removidos com sucesso!");
            getProcessos();
            setSelectedProcessosIds([]);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar os serviços selecionados");
      console.log(error);
    }
  }, [selectedProcessosIds]);

  const getProcessos = useCallback(async () => {
    try {
      let apiUrl = `/servicos/contrato/${contratoId}/processo/${processoId}/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.servicos.map((processo) => ({
            ...processo,
            isSelected: selectedProcessosIds.includes(processo.id),
          }));
          setProcessosAdministrativos(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedProcessosIds]);

  useEffect(() => {
    getProcessos();
  }, [getProcessos]);

  const handleCheckboxChange = (processoId) => {
    setProcessosAdministrativos((prevProcessos) =>
      prevProcessos.map((processo) =>
        processo.id === processoId
          ? { ...processo, isSelected: !processo.isSelected }
          : processo
      )
    );

    setSelectedProcessosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(processoId)) {
        return prevSelectedIds.filter((id) => id !== processoId);
      } else {
        return [...prevSelectedIds, processoId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedProcessosIds.length > 0);
    setTodosProcessosSelecionados(false);
  };

  const handleOpenModalAdicionarImovelCliente = () => {
    setIsModalAdicionarImovelClienteAberto(
      !isModalAdicionarImovelClienteAberto
    );
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const processoId = tableMeta.rowData[1];
          const processo = processosAdministrativos.find(
            (processo) => processo.id === processoId
          );
          return (
            <Checkbox
              checked={processo.isSelected}
              onChange={() => handleCheckboxChange(processoId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "tarefas_servico",
      label: "Tarefa",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value[0]?.descricao ?? "N/I"}</>;
        },
      },
    },
    {
      name: "quantidade",
      label: "Quantidade",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quantidade = tableMeta.rowData[3];
          const valor = tableMeta.rowData[4];
          return (
            <>
              {Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </>
          );
        },
      },
    },
    {
      name: "valor_total",
      label: "Valor Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quantidade = tableMeta.rowData[3];
          const valor = tableMeta.rowData[4];
          return (
            <>
              {Number(quantidade * valor).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </>
          );
        },
      },
    },
    {
      name: "prazo",
      label: "Prazo",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? moment.utc(value).format("DD/MM/YYYY") : "N/I"}</>;
        },
      },
    },
    {
      name: "is_paralisado",
      label: "Paralisado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <CustomChipLicencasUnidade
                situacao={value === false ? "Não" : value}
              />
            </>
          );
        },
      },
    },
    {
      name: "status_andamento_servico",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <CustomChipLicencasUnidade situacao={value ?? "N/I"} />
            </>
          );
        },
      },
    },
    {
      name: "servicocolaborador",
      label: "Colaboradores",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value && value.length > 0 ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                  {value.map((colaborador) => (
                    <Tooltip
                      key={colaborador?.id}
                      title={colaborador?.users?.name}
                    >
                      <Avatar
                        sx={{ width: "24px", height: "24px" }}
                        src={`${process.env.REACT_APP_API_KEY}/public/uploads/${colaborador?.users?.avatarUrl}`}
                      >
                        {String(colaborador?.users?.name)
                          .charAt(0)
                          .toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Box>
              ) : (
                "N/I"
              )}
            </>
          );
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const servicoId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Acompanhar Serviço">
                <VisibilityIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/detalhes`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            processosAdministrativos[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2],
                          idProcesso: tableMeta.rowData[1],
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Editar Serviço">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/editar/${servicoId}`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            processosAdministrativos[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2],
                          idProcesso: tableMeta.rowData[1],
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Serviço">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/deletar`
                    );
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedProcessosIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <Box>
      <BotaoVoltarBreadCrumbs
        handleBack={() => {
          navigate(
            `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
          );
        }}
      />
      <BadgeNomeCliente
        nome={breadCrumbsInfo?.descricao}
        linkTo={`/app/contrato/${contratoId}/detalhes`}
      />
      {breadCrumbsInfo ? (
        <Typography
          variant={"h4"}
          fontWeight={600}
          style={{ cursor: "pointer", margin: "10px 0" }}
          onClick={() => {
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`
            );
          }}
        >
          {
            breadCrumbsInfo?.processos_contratos?.[0]?.processo_administrativo
              ?.descricao
          }
        </Typography>
      ) : null}

      <JumboContentLayout
        header={
          <PageHeader
            title={`Serviços`}
            icon="desc"
            btn={
              <ActionButton
                title="Novo Serviço"
                color="green"
                icon={<QueueIcon />}
                action={() =>
                  navigate(
                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/novo`,
                    {
                      state: {
                        descricao:
                          location?.state?.descricao ??
                          processosAdministrativos[0]?.contrato?.descricao,
                      },
                    }
                  )
                }
              />
            }
          />
        }
        layoutOptions={layoutOptions}
      >
        <SearchAndDelete
          title={selectedProcessosIds.length > 1 ? "serviços" : "serviço"}
          handleDelete={handleDeleteMuitosProcessos}
          selected={selectedProcessosIds}
          setInputValue={setInputValue}
          inputValue={inputValue}
          handleSearch={handleSearch}
          tituloDocumento={`Serviços do Contrato ${breadCrumbsInfo?.descricao}`}
          dadosPlanilha={processosAdministrativos}
          nomeArquivoPlanilha={`servicos_${breadCrumbsInfo?.descricao}_`}
          colunasPlanilha={[
            "tarefas_servico",
            "quantidade",
            "valor",
            "prazo",
            "is_paralisado",
            "createdAt",
          ]}
        />

        <React.Fragment>
          <Box mt={3}>
            <Card
              sx={{
                p: 2,
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <Checkbox
                    sx={{
                      color: "#696969",
                      "&.Mui-checked": {
                        color: "#95A64A",
                      },
                    }}
                    onChange={handleSelecionarTodosProcessos}
                  />
                </Grid>
                <Grid item lg={3}>
                  <Box>
                    <Typography fontSize={18} fontWeight={600}>
                      Tarefa
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={18} fontWeight={600}>
                      Quantidade
                    </Typography>
                    <OrdenacaoLista
                      column={"quantidade"}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      setSortBy={setSortBy}
                      setSortOrder={setSortOrder}
                    />
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={18} fontWeight={600}>
                      Valor
                    </Typography>
                    <OrdenacaoLista
                      column={"valor"}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      setSortBy={setSortBy}
                      setSortOrder={setSortOrder}
                    />
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box>
                    <Typography fontSize={18} fontWeight={600}>
                      Valor total
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={18} fontWeight={600}>
                      Prazo
                    </Typography>
                    <OrdenacaoLista
                      column={"prazo"}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      setSortBy={setSortBy}
                      setSortOrder={setSortOrder}
                    />
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box>
                    <Typography fontSize={18} fontWeight={600}>
                      Paralisado
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box>
                    <Typography fontSize={18} fontWeight={600}>
                      Status
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1}>
                  <Box>
                    <Typography fontSize={18} fontWeight={600}>
                      Ações
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <Box mt={3}>
              {processosAdministrativos.length > 0 ? (
                <>
                  {processosAdministrativos.map((p, key) => (
                    <Card
                      sx={{
                        p: 2,
                        minHeight: 100,
                        display: "flex",
                        my: 1,
                      }}
                      key={key}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        // gap={2}
                      >
                        <Grid item lg={1}>
                          <Checkbox
                            sx={{
                              color: "#696969",
                              "&.Mui-checked": {
                                color: "#95A64A",
                              },
                            }}
                            key={p.processo_administrativo.id}
                            checked={p.isSelected}
                            onChange={() => handleCheckboxChange(processoId)}
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18} textAlign={"left"}>
                              {p?.tarefas_servico[0]?.descricao ?? "N/I"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <Typography fontSize={18} textAlign={"left"}>
                              {p.quantidade}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18}>
                              {Number(p.valor).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18}>
                              {Number(p.quantidade * p.valor).toLocaleString(
                                "pt-BR",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18}>
                              {p?.prazo
                                ? moment.utc(p.prazo).format("DD/MM/YYYY")
                                : "N/I"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <CustomChipLicencasUnidade
                              situacao={
                                p.is_paralisado === false ? "Não" : "Sim"
                              }
                              bgColor={"#FFCB3C"}
                              color={"#484646"}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <CustomChipLicencasUnidade
                              situacao={p.status_andamento_servico ?? "N/I"}
                              bgColor={"#2FB5C9"}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={1.5}>
                          <Box display={"flex"} gap={"1rem"}>
                            <Tooltip title="Acompanhar Serviço">
                              <VisibilityIcon
                                cursor="pointer"
                                color="info"
                                disabled={!permission}
                                onClick={() => {
                                  navigate(
                                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${p.id}/detalhes`,
                                    {
                                      state: {
                                        descricao:
                                          location?.state?.descricao ??
                                          processosAdministrativos[0]?.contrato
                                            ?.descricao,
                                        processoDescricao:
                                          p.tarefas_servico?.descricao,
                                        idProcesso: p.id,
                                      },
                                    }
                                  );
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Documentos do Serviço">
                              <PlagiarismIcon
                                cursor="pointer"
                                color="info"
                                disabled={!permission}
                                onClick={() => {
                                  navigate(
                                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${p.id}/documentos`,
                                    {
                                      state: {
                                        descricao:
                                          location?.state?.descricao ??
                                          processosAdministrativos[0]?.contrato
                                            ?.descricao,
                                        processoDescricao:
                                          p.tarefas_servico?.descricao,
                                        idProcesso: p.id,
                                      },
                                    }
                                  );
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Editar Serviço">
                              <EditIcon
                                cursor="pointer"
                                color="info"
                                disabled={!permission}
                                onClick={() => {
                                  navigate(
                                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/editar/${p.id}`,
                                    {
                                      state: {
                                        descricao:
                                          location?.state?.descricao ??
                                          processosAdministrativos[0]?.contrato
                                            ?.descricao,
                                        processoDescricao:
                                          p.tarefas_servico?.descricao,
                                        idProcesso: p.id,
                                      },
                                    }
                                  );
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Excluir Serviço">
                              <DeleteIcon
                                cursor="pointer"
                                color="error"
                                onClick={() => {
                                  navigate(
                                    `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${p.id}/deletar`
                                  );
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <Grid container>
                  <Grid item style={{ width: "100%" }}>
                    <Box my={5} textAlign={"center"}>
                      <Typography>Nenhum contrato cadastrado.</Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <PaginationComponent
                page={page}
                setPage={setPage}
                count={count}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Box>
          </Box>
          <ModalCadastroImovelCliente
            aberto={isModalAdicionarImovelClienteAberto}
            handleClose={handleOpenModalAdicionarImovelCliente}
            clienteSelecionado={clienteSelecionado}
          />
        </React.Fragment>
      </JumboContentLayout>
    </Box>
  );
};

export default ServicosContratoList;
