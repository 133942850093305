import React, { useEffect, useRef, useState } from "react";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HistoryIcon from "@mui/icons-material/History";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { CalendarMonth } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiService from "app/services/config";
import ModalEdicaoCondicionante from "../ModalEdicaoCondicionante/ModalEdicaoCondicionante";
import CustomChipLicencasUnidade from "../CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import ArquivoWrapper from "../ArquivoWrapper/ArquivoWrapper";
import { makeStyles } from "@mui/styles";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import ModalHistoricoCondicionante from "../ModalHistoricoCondicionante/ModalHistoricoCondicionante";
import Item from "antd/es/list/Item";

const CondicionanteWrapper = ({
  dados,
  atualizarUnidade,
  accordionOpen,
  tipoCondicionante,
  licencaId,
}) => {
  const userId = localStorage.getItem("id");
  const [isDadosCarregados, setIsDadosCarregados] = useState(true);
  const [condicionante, setCondicionante] = useState(dados);
  const accordionRef = useRef(null);
  const [arquivos, setArquivos] = useState([]);
  const [arquivosUpload, setArquivosUpload] = useState([]);
  const [isAccordionAberto, setIsAccordionAberto] = useState(accordionOpen);
  const [tarefasConcluidas, setTarefasConcluidas] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuAberto, setSubmenuAberto] = useState(false);
  const [isModalEdicaoObservacaoAberto, setIsModalEdicaoObservacaoAberto] =
    useState(false);
  const [
    isModalHistoricoCondicionanteAberto,
    setIsModalHistoricoCondicionanteAberto,
  ] = useState(false);
  const [acaoEdicao, setAcaoEdicao] = useState("");
  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const useStyles = makeStyles({
    labelChecked: {
      textDecoration: "line-through",
    },
  });

  const classes = useStyles();

  const toggleAccordion = () => {
    setIsAccordionAberto(!isAccordionAberto);
  };

  const handleClickSubmenu = () => {
    setSubmenuAberto(!submenuAberto);
  };

  const handleCloseSubmenu = () => {
    setSubmenuAberto(false);
  };

  const handleOpenModalEdicaoCondicionante = () => {
    setIsModalEdicaoObservacaoAberto(!isModalEdicaoObservacaoAberto);
  };

  const handleOpenModalHistoricoCondicionante = () => {
    setIsModalHistoricoCondicionanteAberto(
      !isModalHistoricoCondicionanteAberto
    );
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao, item) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "excluir" : "arquivar"
      }?`,
      text: `Não será póssível reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "deletar" && !item) {
          handleDeleteCondicionante(id);
        }
        if (acao === "deletar" && item === "anexo") {
          handleDeleteDocumento(id);
        }
        if(acao === "arquivar" && !item) {
          handleArquivarCondicionante(id);
        }
      }
    });
  };

  const modalAlertConclusaoTarefa = (id, acao, item) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "concluir" ? "concluir a tarefa" : "deixar a tarefa pendente"
      }?`,
      text: `Você pode reverter a ação posteriormente!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "concluir" && !item) {
          handleConcluirTarefa(id);
        }
        if (acao === "desmarcar" && !item) {
          handleDesfazerConcluirTarefa(id);
        }
      }
    });
  };

  const handleDeleteDocumento = async (id) => {
    try {
      await ApiService.put(`/condicionante/documento/${id}`, {
        userId: Number(userId),
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Anexo excluído!", "success");
          setCondicionante((prevCondicionante) => ({
            ...prevCondicionante,
            anexos: prevCondicionante.anexos.filter((anexo) => anexo.id !== id),
          }));
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
      console.error(error);
    }
  };

  const handleUpdateCondicionante = async () => {
    try {
      await ApiService.get(`/condicionante/${condicionante.id}`).then(
        (response) => {
          if (response.status === 200) {
            setCondicionante(response.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleArquivarCondicionante = async (id) => {
    try {
      await ApiService.put(`/condicionante/arquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Condicionante arquivada com sucesso!");
          atualizarUnidade();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  }

  const handleDeleteCondicionante = async (id) => {
    try {
      await ApiService.delete(`/condicionante/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Condicionante excluída com sucesso!");
          atualizarUnidade();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleConcluirTarefa = async (tarefaId) => {
    try {
      await ApiService.put(`/condicionante/concluir/tarefa/`, {
        tarefaId: Number(tarefaId),
        userId: Number(userId),
        condicionante_id: Number(condicionante.id),
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Tarefa concluída!");
          atualizarUnidade();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleDesfazerConcluirTarefa = async (tarefaId) => {
    try {
      await ApiService.put(`/condicionante/desfazer/concluir/tarefa/`, {
        tarefaId: Number(tarefaId),
        userId: Number(userId),
        condicionante_id: Number(condicionante.id),
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Tarefa marcada como pendente!");
          atualizarUnidade();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleRemoverArquivo = (arquivoRemovido) => {
    const novosArquivos = arquivos.filter(
      (arquivo) => arquivo !== arquivoRemovido
    );
    setArquivos(novosArquivos);
  };

  useEffect(() => {
    setCondicionante(dados);
    const totalTarefasConcluidas = condicionante.tarefas.filter(
      (tarefa) => tarefa.is_concluida === true
    );
    setTarefasConcluidas(totalTarefasConcluidas.length);
  }, [dados]);

  useEffect(() => {
    setIsAccordionAberto(accordionOpen);
    if (isAccordionAberto && accordionRef.current) {
      accordionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [accordionOpen]);

  return (
    isDadosCarregados && (
      <>
        <Accordion
          sx={{ margin: 1, background: "white", padding: 3 }}
          expanded={isAccordionAberto}
          onChange={toggleAccordion}
          ref={accordionRef}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              gap={2}
            >
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Typography fontWeight={600} fontSize={16}>
                    {condicionante.nome_condicionante}
                  </Typography>
                  <Stack direction="row" gap={2}>
                    <ReplayOutlinedIcon
                      size={"medium"}
                      sx={{ color: "#008598", cursor: "pointer" }}
                      onClick={() => {
                        setAcaoEdicao("status");
                        handleOpenModalEdicaoCondicionante();
                      }}
                    />
                    <MenuOutlinedIcon
                      size={"medium"}
                      sx={{ color: "#008598", cursor: "pointer" }}
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        handleClickSubmenu();
                      }}
                    />
                    <Popover
                      open={submenuAberto}
                      anchorEl={anchorEl}
                      onClose={handleCloseSubmenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List>
                        <ListItem
                          onClick={() => {
                            navigate(
                              tipoCondicionante === "condicionanteLicenca"
                                ? `/app/editar-condicionante-licenca/${licencaId}/${condicionante.id}`
                                : `/app/editar-condicionante/${condicionante.unidade_id}/${condicionante.id}`
                            );
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary="Editar" />
                        </ListItem>
                        <Divider />
                        <ListItem
                          onClick={handleOpenModalHistoricoCondicionante}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <HistoryIcon />
                          </ListItemIcon>
                          <ListItemText primary="Histórico" />
                        </ListItem>
                        <Divider />
                        <ListItem
                          onClick={() => {
                            modalAlert(condicionante.id, "arquivar");
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <ArchiveIcon />
                          </ListItemIcon>
                          <ListItemText primary="Arquivar" />
                        </ListItem>
                        <Divider />
                        <ListItem
                          onClick={() => {
                            modalAlert(condicionante.id, "deletar");
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText primary="Excluir" />
                        </ListItem>
                      </List>
                    </Popover>
                  </Stack>
                </Stack>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Stack my={1} direction={"row"} gap={1}>
                  <Box width={"auto"}>
                    <CustomChipLicencasUnidade
                      situacao={condicionante.status}
                    />
                  </Box>
                  <Chip
                    label={
                      <Stack direction="row" gap={1} alignItems="center">
                        <CalendarMonth />{" "}
                        {condicionante.data_prazo
                          ? moment
                              .parseZone(condicionante.data_prazo)
                              .format("DD/MM/YYYY")
                          : "N/I"}
                      </Stack>
                    }
                  />
                  <NotificationsActiveOutlinedIcon color={"#6EA4D9"} />
                  <AttachFileOutlinedIcon color="#6EA4D9" />
                  <Stack direction="row" gap={1}>
                    <CheckBoxOutlinedIcon color="#6EA4D9" />
                    <Typography fontSize={15}>
                      {tarefasConcluidas}/{condicionante.tarefas.length}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" display="flex" gap={1}>
                  {condicionante.responsaveis.length > 0 &&
                    condicionante.responsaveis?.map((responsavel) => (
                      <Tooltip title={responsavel.user?.name ?? ""}>
                        <Avatar
                          sx={{ width: "24px", height: "24px" }}
                          src={
                            responsavel.user?.avatarUrl
                              ? `${process.env.REACT_APP_API_KEY}/public/uploads/${responsavel.user?.avatarUrl}`
                              : null
                          }
                        >
                          {responsavel.user?.name
                            ? responsavel.user?.name?.charAt(0).toUpperCase()
                            : "P"}
                        </Avatar>
                      </Tooltip>
                    ))}
                </Stack>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" gap={1} my={4}>
              <Stack
                direction={"row"}
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
              >
                <Typography fontWeight={600} fontSize={16}>
                  Observações
                </Typography>
                <EditNoteIcon
                  color={"info"}
                  cursor={"pointer"}
                  onClick={() => {
                    setAcaoEdicao("observacao");
                    handleOpenModalEdicaoCondicionante();
                  }}
                />
              </Stack>
              <ModalEdicaoCondicionante
                handleClose={handleOpenModalEdicaoCondicionante}
                modalAberto={isModalEdicaoObservacaoAberto}
                nomeCondicionante={condicionante.nome_condicionante}
                observacao={condicionante.observacao}
                userId={userId}
                condicionanteId={condicionante.id}
                statusCondicionante={condicionante.status}
                atualizarCondicionante={handleUpdateCondicionante}
                acao={acaoEdicao}
              />
              <ModalHistoricoCondicionante
                condicionanteId={condicionante.id}
                handleClose={handleOpenModalHistoricoCondicionante}
                modalAberto={isModalHistoricoCondicionanteAberto}
              />
              <Divider />
              <Typography>
                {condicionante.observacao ?? "Nenhuma observação no momento."}{" "}
                <Link
                  color="#008598"
                  sx={{
                    textDecoration: "none",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={handleOpenModalHistoricoCondicionante}
                >
                  Ver anteriores no histórico.
                </Link>
              </Typography>
            </Stack>
            <Stack direction="column" gap={1} my={4} width={"100%"}>
              <Typography fontWeight={600} fontSize={16}>
                Anexos
              </Typography>
              <Divider />
              <Typography variant="h6" my={1}>
                Formatos de arquivos permitidos: .jpg, .pdf e .png | Tamanho
                máximo: 10 MB
              </Typography>
              <input
                accept=".pdf, .png, .jpg, .jpeg"
                style={{ display: "none" }}
                id="file-upload"
                multiple
                type="file"
                onChange={(event) => {
                  const files = event.target.files;
                  setArquivosUpload((prevArquivos) => [
                    ...prevArquivos,
                    ...Array.from(files),
                  ]);
                  const novosArquivos = Array.from(files).map((file) => ({
                    nome: file.name,
                    tamanho_documento: file.size,
                    extensao: file.name.split(".").pop().toUpperCase(),
                    dataUpload: moment().format("DD/MM/YYYY"),
                  }));
                  setArquivos((arquivos) => [...arquivos, ...novosArquivos]);
                }}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: "100%",
                    background: "transparent",
                    p: 1,
                    color: "#A7A7A7",
                    border: "1px dashed #A7A7A7",
                    "&:hover": {
                      background: "#2CB3C7",
                      color: "#FFF",
                      border: "none",
                    },
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    navigate(
                      tipoCondicionante === "condicionanteLicenca"
                        ? `/app/editar-condicionante-licenca/${licencaId}/${condicionante.id}`
                        : `/app/editar-condicionante/${condicionante.unidade_id}/${condicionante.id}`
                    );
                  }}
                >
                  Upload
                </Button>
              </label>
              <Stack
                style={{
                  maxHeight: "150px",
                  overflowY: "auto",
                }}
              >
                {condicionante.anexos.map((anexo) => (
                  <ArquivoWrapper
                    key={v4()}
                    arquivo={anexo}
                    tipoLicenca={"Documentos condicionantes"}
                    onRemoverArquivo={() => {
                      modalAlert(anexo.id, "deletar", "anexo");
                    }}
                    tipoArquivo={"anexoCondicionante"}
                  />
                ))}
                {arquivos.length > 0 && (
                  <>
                    {arquivos.map((arquivo, index) => (
                      <ArquivoWrapper
                        key={v4()}
                        arquivo={arquivo}
                        tipoLicenca={"Documento condicionante"}
                        onRemoverArquivo={handleRemoverArquivo}
                      />
                    ))}
                  </>
                )}
              </Stack>
            </Stack>
            <Stack direction="column" gap={1} my={4} width={"100%"}>
              <Stack
                direction="row"
                width="100%"
                justifyContent={"space-between"}
              >
                <Typography fontWeight={600} fontSize={16}>
                  Tarefas
                </Typography>
                <NotificationsActiveOutlinedIcon color={"#6EA4D9"} />
              </Stack>
              <Divider />
              {condicionante.tarefas.length > 0 &&
                condicionante.tarefas.map((tarefa) => (
                  <>
                    <Stack
                      direction="row"
                      my={1}
                      justifyContent={"space-between"}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tarefa.is_concluida}
                              onClick={() =>
                                !tarefa.is_concluida
                                  ? modalAlertConclusaoTarefa(
                                      tarefa.id,
                                      "concluir"
                                    )
                                  : modalAlertConclusaoTarefa(
                                      tarefa.id,
                                      "desmarcar"
                                    )
                              }
                            />
                          }
                          label={tarefa.descricao}
                          classes={{
                            label: tarefa.is_concluida
                              ? classes.labelChecked
                              : "",
                          }}
                          color="success"
                        />
                      </FormGroup>
                      <Typography>
                        {moment
                          .parseZone(tarefa.data_prazo)
                          .utc()
                          .format("DD/MM/YYYY")}
                      </Typography>
                    </Stack>
                  </>
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </>
    )
  );
};

export default CondicionanteWrapper;
