import {
  Box,
  Button,
  Fade,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { tiposArquivos } from "app/utils/tiposArquivos";
import React, { useEffect, useRef, useState } from "react";
import ActionButton from "../ActionButton/ActionButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import SendIcon from "@mui/icons-material/Send";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 320,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
  borderRadius: "20px",
  currencyInput: {
    width: "100%",
    height: 50,
    padding: "10px 14px",
    fontSize: "1rem",
    borderRadius: 4,
    border: "1px solid #ced4da",
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
};

const ModalUploadDocumentoImovel = ({
  isOpen,
  handleClose,
  setFieldValue,
  handleUpload,
  document,
  hasDocument,
  description
}) => {
  const [modalAberto, setModalAberto] = useState(isOpen);
  const [descricaoDocumento, setDescricaoDocumento] = useState("");
  const inputFileRef = useRef(null);

  const handleFileUploadClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  useEffect(() => {
    setModalAberto(isOpen);
    setDescricaoDocumento(description);
    return () => {
      setDescricaoDocumento("");
    };
  }, [isOpen]);
  return (
    <Paper sx={{ borderRadius: "20px" }}>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={style}>
            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "bold",
              }}
            >
              Upload de documento
            </Typography>
            <Box width={1} mt={3}>
              <label
                htmlFor="upload-button-file"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <input
                  ref={inputFileRef}
                  id="upload-button-file"
                  name="documento"
                  hidden
                  accept="image/*, .pdf, .docx, .xls"
                  type="file"
                  onChange={(event) => {
                    handleUpload(event);
                  }}
                />
                <ActionButton
                  color={"green"}
                  icon={<CloudUploadIcon />}
                  title={"Fazer upload"}
                  action={handleFileUploadClick}
                />
              </label>
              {hasDocument && (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  borderRadius={1}
                  sx={{
                    p: 2,
                    my: 1,
                    border: "1px solid #0e0e0e",
                  }}
                >
                  <Box
                    sx={{
                      background:
                        tiposArquivos[String(document?.name).split(".")[1]]
                          ?.cor,
                      color: "#FFF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50px",
                      height: "50px",
                    }}
                    borderRadius={1}
                  >
                    {tiposArquivos[String(document?.name).split(".")[1]]?.nome}
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_API_KEY}/${document?.name}`,
                        "_blank"
                      );
                    }}
                  >
                    {document?.name?.includes("\\") ||
                    document?.name?.includes("/")
                      ? document.name?.split(/(\\|\/)/).pop()
                      : document?.name}
                  </Box>
                </Stack>
              )}
            </Box>
            <Box my={1}>
              <InputAmbiental
                name={"descricao_documento"}
                fullWidth={true}
                height={80}
                onChange={(event) => {
                  const value = event.target.value;
                  setFieldValue("descricao_documento", value);
                  setDescricaoDocumento(value);
                }}
                placeholder="Descrição/ nome do arquivo*"
                value={descricaoDocumento}
              />
            </Box>
            <Box my={4}>
              <ActionButton
                color={descricaoDocumento ? "blue" : "gray"}
                disabled={descricaoDocumento ? false : true}
                icon={<SendIcon />}
                title={"Enviar"}
                action={handleClose}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalUploadDocumentoImovel;
