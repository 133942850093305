import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Card,
  Badge,
  Chip,
  PaginationItem,
  Paper,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import { gerarPDF } from "app/utils/pdfGenerator";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { exportarContratoPDF } from "app/utils/exportarContratoPDF";
import EditIcon from "@mui/icons-material/Edit";
import Description from "@mui/icons-material/Description";
import { Visibility } from "@mui/icons-material";
import { DateRangePicker } from "react-dates";
import gerarRelatorioTodosContratos from "app/utils/pdfs/gerarRelatorioTodosContratos";
import gerarRelatorioContratosFixosPDF from "app/utils/pdfs/gerarRelatorioContratosFixosPDF";
import gerarRelatorioContratosRenovacaoPDF from "app/utils/pdfs/gerarRelatorioContratosRenovacaoPDF";
import gerarPDFContratoPadraoIndividual from "app/utils/pdfs/gerarPDFContratoPadraoIndividual";
import gerarPDFContratoFixoIndividual from "app/utils/pdfs/gerarPDFContratoFixoIndividual";
import gerarPDFContratoRenovacaoIndividual from "app/utils/pdfs/gerarPDFContratoRenovacaoIndividual";
import gerarPDFContratoFixoRenovacaoIndividual from "app/utils/pdfs/gerarPDFContratoFixoRenovacaoIndividual";
import gerarRelatorioContratosPadraoPDF from "app/utils/pdfs/gerarRelatorioContratosPadraoPDF";
import DescriptionIcon from "@mui/icons-material/Description";
import QueueIcon from "@mui/icons-material/Queue";
import ActionButton from "app/components/ActionButton/ActionButton";
import PaginationComponent from "app/components/Pagination/Pagination";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FilterListIcon from "@mui/icons-material/FilterList";
import { darken } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Div from "@jumbo/shared/Div";
import TuneIcon from "@mui/icons-material/Tune";
import SelectPdf from "app/components/SelectPdf/SelectPdf";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import "../../assets/styles/DateRangePickerStyles.css";
import BotoesExportarDados from "app/components/BotoesExportarDados";
import OrdenacaoLista from "app/components/OrdenacaoLista";

export const ContratoList = () => {
  const [contratos, setContratos] = useState([]);
  const [selectedContratosIds, setSelectedContratosIds] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [filtroSelecionarContratos, setFiltroSelecionarContratos] =
    useState("todos");
  const [filtroSituacaoContratos, setFiltroSituacaoContratos] =
    useState("padrao");
  const [statusOrcamentarioSelecionado, setStatusOrcamentarioSelecionado] =
    useState("selecionar");
  const [tipoOrcamentoSelecionado, setTipoOrcamentoSelecionado] =
    useState("selecionar");
  const [count, setCount] = useState("");
  const { theme } = useJumboTheme();
  const [expand, setExpand] = useState(false);
  const [todosContratosSelecionados, setTodosContratosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [exportarParaPDFSelecionado, setExportarParaPDFSelecionado] =
    useState("selecionar");
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [colaboradores, setcolaboradores] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState("selecionar");
  const [periodoPesquisa, setPeriodoPesquisa] = useState("selecionar");
  const [showList, setShowList] = useState(false);

  const [isPDFLoading, setIsPDFLoading] = useState(false);

  // Lista de PDFs (simulando links para download)
  const pdfFiles = [
    { id: 1, name: "PDF File 1", url: "/path/to/pdf1.pdf" },
    { id: 2, name: "PDF File 2", url: "/path/to/pdf2.pdf" },
    { id: 3, name: "PDF File 3", url: "/path/to/pdf3.pdf" },
  ];

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);

  let filtros = {};

  if (filtroSelecionarContratos !== "todos") {
    filtros = {
      ...filtros,
      tipoContrato: filtroSelecionarContratos,
    };
  }

  if (filtroSituacaoContratos !== "padrao") {
    filtros = {
      ...filtros,
      situacao: filtroSituacaoContratos,
    };
  }

  if (statusOrcamentarioSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      statusOrcamento: statusOrcamentarioSelecionado,
    };
  }

  if (colaboradorSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      elaborador: colaboradorSelecionado,
    };
  }

  if (tipoOrcamentoSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      tipoOrcamento: tipoOrcamentoSelecionado,
    };
  }

  if (periodoPesquisa !== "selecionar") {
    filtros = {
      ...filtros,
      periodo: periodoPesquisa,
      startDate: moment.utc(startDate).format("YYYY-MM-DD"),
      endDate: moment.utc(endDate).format("YYYY-MM-DD"),
    };
  }

  filtros.searchTerm = searchTerm;

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleSelecionarTodosContratos = () => {
    setSelectedContratosIds(
      todosContratosSelecionados ? [] : contratos.map((contrato) => contrato.id)
    );
    setTodosContratosSelecionados(!todosContratosSelecionados);
  };

  // Função para gerenciar o clique no botão principal
  const handleButtonClick = () => {
    setShowList(!showList);
  };

  // Função para gerenciar o download de um PDF
  const handleDownload = (url) => {
    // Criar link para download programaticamente
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop(); // Nome do arquivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteMuitosContratos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os contratos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosContratos();
      }
    });
  };

  const handleExportarContratosParaPDF = (tipoContrato) => {
    switch (tipoContrato) {
      case "todos":
        gerarRelatorioTodosContratos(filtros, false, setIsPDFLoading);
        break;
      case "padrao":
        gerarRelatorioContratosPadraoPDF(filtros, true, setIsPDFLoading);
        break;
      case "fixo":
        gerarRelatorioContratosFixosPDF(filtros, false, setIsPDFLoading);
        break;
      case "renovacao":
        gerarRelatorioContratosRenovacaoPDF(filtros, false, setIsPDFLoading);
        break;
    }
  };

  const handleExportarContratoIndividualParaPDF = (
    idContrato,
    tipoContrato
  ) => {
    switch (tipoContrato) {
      case "renovacaoFixoIndividual":
        gerarPDFContratoFixoRenovacaoIndividual(idContrato);
        break;
      case "padraoIndividual":
        gerarPDFContratoPadraoIndividual(idContrato);
        break;
      case "fixoIndividual":
        gerarPDFContratoFixoIndividual(idContrato);
        break;
      case "renovacaoIndividual":
        gerarPDFContratoRenovacaoIndividual(idContrato);
        break;
    }
  };

  const deleteMuitosContratos = useCallback(async () => {
    try {
      await ApiService.delete("/contratos/delete/many", {
        data: { ids: selectedContratosIds },
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Contratos removidos com sucesso!");
          getContratos();
          setSelectedContratosIds([]);
          setTodosContratosSelecionados(false);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os contratos selecionados");
      console.log(error);
    }
  }, [selectedContratosIds]);

  const handleCheckboxChange = (contratoId) => {
    setSelectedContratosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(contratoId)) {
        return prevSelectedIds.filter((id) => id !== contratoId);
      } else {
        return [...prevSelectedIds, contratoId];
      }
    });
    setTodosContratosSelecionados(false);
  };

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const handleOpenModal = () => {
    setOpenModal(true); // Abre o modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Fecha o modal
  };

  const getStatusOrcamento = async () => {
    try {
      await ApiService.get("/statusorcamento/all").then((response) => {
        if (response.status === 200) {
          setStatusOrcamento(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getColaboradores = async () => {
    try {
      await ApiService.get(
        "/users/colaboradores?filtroRoles=financeiro_admin"
      ).then((response) => {
        if (response.status === 200) {
          setcolaboradores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getContratos = useCallback(async () => {
    try {
      let apiUrl = `/contratos?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${filtroSelecionarContratos}&situacao=${filtroSituacaoContratos}&userId=${userId}&roleId=${roleId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (startDate && endDate) {
        apiUrl += `&dataInicio=${moment(startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
      }

      if (statusOrcamentarioSelecionado !== "selecionar") {
        apiUrl += `&statusOrcamento=${statusOrcamentarioSelecionado}`;
      }

      if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
        apiUrl += `&idElaborador=${colaboradorSelecionado}`;
      }

      if (periodoPesquisa !== "selecionar") {
        apiUrl += `&periodo=${periodoPesquisa}`;
      }

      if (tipoOrcamentoSelecionado !== "selecionar") {
        apiUrl += `&tipoOrcamento=${Number(tipoOrcamentoSelecionado)}`;
      }

      const response = await ApiService.get(apiUrl);

      const initialData = response.data.contratos.map((contrato) => ({
        ...contrato,
        isChecked: false,
      }));
      setContratos(initialData);
      setCount(response.data.total);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("error", error.message);
      }
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, userId, roleId]);

  // Função para capturar valores atuais e chamar getContratos
  const handleFilter = (clean = false) => {
    console.log("Executando filtro com os valores mais recentes");

    let filtroAtual = {
      filtroSelecionarContratos,
      filtroSituacaoContratos,
      statusOrcamentarioSelecionado,
      startDate,
      endDate,
      colaboradorSelecionado,
      periodoPesquisa,
      tipoOrcamentoSelecionado,
      searchTerm,
    };

    if (clean) {
      filtroAtual = {
        filtroSelecionarContratos: "todos",
        filtroSituacaoContratos: "padrao",
        statusOrcamentarioSelecionado: "selecionar",
        startDate: null,
        endDate: null,
        colaboradorSelecionado: "selecionar",
        periodoPesquisa: "selecionar",
        tipoOrcamentoSelecionado: "selecionar",
        searchTerm: "",
      };
    }

    const getContratosManualFilter = async () => {
      try {
        let apiUrl = `/contratos?page=${
          page + 1
        }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${
          filtroAtual.filtroSelecionarContratos
        }&situacao=${
          filtroAtual.filtroSituacaoContratos
        }&userId=${userId}&roleId=${roleId}`;

        if (filtroAtual.searchTerm) {
          apiUrl += `&searchTerm=${filtroAtual.searchTerm}`;
        }

        if (filtroAtual.startDate && filtroAtual.endDate) {
          apiUrl += `&dataInicio=${moment(filtroAtual.startDate._d).format(
            "YYYY-MM-DD"
          )}&dataFim=${moment(filtroAtual.endDate._d).format("YYYY-MM-DD")}`;
        }

        if (filtroAtual.statusOrcamentarioSelecionado !== "selecionar") {
          apiUrl += `&statusOrcamento=${filtroAtual.statusOrcamentarioSelecionado}`;
        }

        if (
          filtroAtual.colaboradorSelecionado &&
          filtroAtual.colaboradorSelecionado !== "selecionar"
        ) {
          apiUrl += `&idElaborador=${filtroAtual.colaboradorSelecionado}`;
        }

        if (filtroAtual.periodoPesquisa !== "selecionar") {
          apiUrl += `&periodo=${filtroAtual.periodoPesquisa}`;
        }

        if (filtroAtual.tipoOrcamentoSelecionado !== "selecionar") {
          apiUrl += `&tipoOrcamento=${Number(
            filtroAtual.tipoOrcamentoSelecionado
          )}`;
        }

        const response = await ApiService.get(apiUrl);

        const initialData = response.data.contratos.map((contrato) => ({
          ...contrato,
          isChecked: false,
        }));
        console.log("contratos", initialData);
        setContratos(initialData);
        setCount(response.data.total);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("error", error.message);
        }
      }
    };

    getContratosManualFilter();
  };

  const handleLimparFiltros = () => {
    setPage(0);
    setPerPage(10);
    setSortBy("id");
    setSortOrder("desc");
    setSearchTerm("");
    setFiltroSelecionarContratos("todos");
    setFiltroSituacaoContratos("padrao");
    setStatusOrcamentarioSelecionado("selecionar");
    setColaboradorSelecionado("selecionar");
    setStartDate(null);
    setEndDate(null);
    setPeriodoPesquisa("selecionar");
    setTipoOrcamentoSelecionado("selecionar");
  };

  useEffect(() => {
    getContratos();
    getStatusOrcamento();
    getColaboradores();
  }, [getContratos]);

  return (
    <JumboContentLayout
      header={
        <PageHeader
          title={"Contratos"}
          icon="desc"
          btn={
            <ActionButton
              title="Novo contrato"
              to="/app/novo-contrato"
              color="green"
              icon={<QueueIcon />}
            />
          }
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <React.Fragment>
          <Grid
            container
            gap={1}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Div
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Pesquisar..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: 2,
                      border: "1px solid #4E5526",
                      paddingLeft: 2,
                      height: "36px",
                      "&:hover": { borderColor: "#3B4220" },
                      "&.Mui-focused": { borderColor: "#4E5526" },
                      color: "#4E5526",
                      "&::placeholder": { color: "red", opacity: 1 },
                      width: 350,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            backgroundColor: "#4E5526",
                            borderRadius: 2,
                            minWidth: "36px",
                            height: "36px",
                            padding: 0,
                            "&:hover": { backgroundColor: "#3B4220" },
                          }}
                          onClick={() => handleSearch(inputValue)}
                        >
                          <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    paddingX: 2,
                    textTransform: "none",
                    backgroundColor: "#F0F0F0",
                    gap: 1,
                    "&:hover": { backgroundColor: darken("#F0F0F0", 0.2) },
                  }}
                  onClick={handleOpenModal}
                >
                  <Typography sx={{ color: "#4E5526" }}>Filtros</Typography>
                  <TuneIcon sx={{ color: "#4E5526", fontSize: "20px" }} />
                </Button>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {selectedContratosIds.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteMuitosContratos}
                    sx={{
                      borderRadius: 2,
                      paddingX: 2,
                      backgroundColor: "#C84E4D",
                      textTransform: "none",
                    }}
                  >
                    Excluir {selectedContratosIds.length} contratos
                  </Button>
                )}

                <BotoesExportarDados
                  dados_planilha={contratos}
                  nome_arquivo_planilha={"lista_contratos_"}
                  colunas_planilha={[
                    "descricao",
                    "createdAt",
                    "Cliente.nome",
                    "elaboradores_orcamento",
                  ]}
                  titulo="Contratos"
                />
                <SelectPdf
                  handleExportarContratosParaPDF={
                    handleExportarContratosParaPDF
                  }
                  isLoading={isPDFLoading}
                />
              </Box>
            </Div>

            <Card
              sx={{
                p: 2,
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <Checkbox
                    sx={{
                      color: "#696969",
                      "&.Mui-checked": {
                        color: "#95A64A",
                      },
                    }}
                    onChange={handleSelecionarTodosContratos}
                  />
                </Grid>
                <Grid item lg={2}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={24} fontWeight={600}>
                      Contrato
                    </Typography>
                    <OrdenacaoLista
                      column={"descricao"}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      setSortBy={setSortBy}
                      setSortOrder={setSortOrder}
                    />
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Cliente
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={24} fontWeight={600}>
                      Data de criação
                    </Typography>
                    <OrdenacaoLista
                      column={"createdAt"}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      setSortBy={setSortBy}
                      setSortOrder={setSortOrder}
                    />
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Elaborador
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Ações
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <Dialog
              open={openModal}
              onClose={handleCloseModal}
              PaperProps={{
                style: {
                  width: "800px", // Define a largura do modal
                  maxWidth: "none", // Remove o limite máximo de largura
                  padding: "0 80px",
                },
              }}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={2}
                  padding={2}
                  alignContent={"center"}
                  // justifyContent={"flex-start"}
                  display={"flex"}
                  width={"100%"}
                >
                  <Grid item xs={5} alignItems={"flex-start"}>
                    <SelectAmbiental
                      label={"Selecionar:"}
                      name={"tipo_contrato"}
                      value={filtroSelecionarContratos}
                      required={false}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFiltroSelecionarContratos(value);
                      }}
                      nameOptionDescription={"label"}
                      nameOptionValue={"name"}
                      options={[
                        { name: "todos", label: "Todos os Contratos" },
                        { name: "fixo", label: "Contratos Fixos" },
                        { name: "renovacao", label: "Contratos Renovação" },
                      ]}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={5} alignItems={"flex-start"} >
                    <SelectAmbiental
                      label={"Status Orçamentário"}
                      name={"status"}
                      value={statusOrcamentarioSelecionado}
                      required={false}
                      onChange={(event) => {
                        const value = event.target.value;
                        setStatusOrcamentarioSelecionado(value);
                      }}
                      nameOptionDescription={"descricao"}
                      nameOptionValue={"id"}
                      options={[
                        { id: "selecionar", descricao: "Selecionar" },
                        ...statusOrcamento,
                      ]}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  padding={2}
                  alignContent={"center"}
                  // justifyContent={"center"}
                  display={"flex"}
                  width={"100%"}
                >
                  <Grid item xs={4} alignItems={"flex-start"}>
                    <SelectAmbiental
                      label={"Filtrar por"}
                      name={"periodo"}
                      value={periodoPesquisa}
                      required={false}
                      onChange={(event) => {
                        const value = event.target.value;
                        setPeriodoPesquisa(value);
                      }}
                      nameOptionDescription={"label"}
                      nameOptionValue={"name"}
                      options={[
                        { name: "selecionar", label: "Selecionar" },
                        { name: "createdAt", label: "Data de Criação" },
                        { name: "data_aprovacao", label: "Data de Aprovação" },
                      ]}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={7} sx={{ marginTop: 4 }}>
                    <DateRangePicker
                      small={true}
                      startDate={startDate}
                      startDatePlaceholderText="Data inicial"
                      startDateId="data_inicio"
                      endDate={endDate}
                      endDateId="data_final"
                      endDatePlaceholderText="Data final"
                      onDatesChange={handleDatesChange}
                      focusedInput={focusedInput}
                      onFocusChange={handleFocusChange}
                      isOutsideRange={() => false}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  paddingLeft={2}
                  alignContent={"center"}
                  // justifyContent={"center"}
                  display={"flex"}
                  width={"100%"}
                  paddingY={2}
                >
                  <Grid item xs={5} alignItems={"flex-start"}>
                    <SelectAmbiental
                      label={"Tipo de Orçamento:"}
                      name={"tipo_orcamento"}
                      value={tipoOrcamentoSelecionado}
                      required={false}
                      onChange={(event) => {
                        const value = event.target.value;
                        setTipoOrcamentoSelecionado(value);
                      }}
                      nameOptionDescription={"label"}
                      nameOptionValue={"id"}
                      options={[
                        { name: "selecionar", label: "Selecionar" },
                        { id: 1, label: "Agronegócio" },
                        { id: 2, label: "Mineração" },
                        { id: 3, label: "Postos de Combustíveis" },
                        { id: 4, label: "Prestação de Serviços" },
                      ]}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={7} alignItems={"flex-start"}>
                    <SelectAmbiental
                      label={"Elaborador:"}
                      name={"elaborador"}
                      value={colaboradorSelecionado}
                      required={false}
                      onChange={(event) => {
                        const value = event.target.value;
                        setColaboradorSelecionado(value);
                      }}
                      nameOptionDescription={"name"}
                      nameOptionValue={"id"}
                      options={[
                        { id: "selecionar", name: "Selecionar" },
                        ...colaboradores,
                      ]}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                {/* <Button onClick={handleCloseModal} color="primary">Limpar filtros</Button>
                <Button onClick={handleCloseModal} color="primary">Filtrar</Button> */}
                <ActionButton
                  title={"Filtrar"}
                  to={null}
                  color="blue"
                  icon={<TuneIcon sx={{ fontSize: "16px" }} />}
                  isSubmitAction={true}
                  action={() => {
                    handleFilter();
                    handleCloseModal();
                  }}
                />

                <ActionButton
                  title={"Limpar filtros"}
                  to={null}
                  color="red"
                  icon={<DeleteIcon sx={{ fontSize: "16px" }} />}
                  isSubmitAction={true}
                  action={() => {
                    handleCloseModal();
                    handleLimparFiltros();
                    handleFilter(true);
                  }}
                />
              </DialogActions>
            </Dialog>

            {contratos.length > 0 ? (
              <>
                {contratos.map((contrato, key) => (
                  <Card
                    sx={{
                      p: 2,
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={key}
                  >
                    <Grid
                      container
                      // spacing={2}
                      // padding={2}
                      gap={3}
                      // justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item lg={1}>
                        <Checkbox
                          sx={{
                            color: "#696969",
                            "&.Mui-checked": {
                              color: "#95A64A",
                            },
                          }}
                          key={contrato.id}
                          checked={selectedContratosIds.includes(contrato.id)}
                          onChange={() => handleCheckboxChange(contrato.id)}
                        />
                      </Grid>
                      <Grid item lg={2} sx={{ display: 'flex' }}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "600" }}
                          >
                            <Chip
                              color="success"
                              sx={{ borderRadius: 2 }}
                              label={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography fontSize={18} fontWeight={600}>
                                    {contrato.descricao}
                                  </Typography>
                                  {contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Renovação".toLowerCase()
                                  ) && (
                                    <ReplayIcon fontSize={"small"} gap={10} />
                                  )}
                                  {contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Contrato fixo".toLowerCase()
                                  ) && <StarIcon fontSize={"small"} gap={10} />}
                                </Box>
                              }
                              my={2}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2} sx={{ display: 'flex' }}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            fontSize={"16px"}
                            style={{ fontWeight: "600" }}
                          >
                            {contrato.Cliente?.nome ?? "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}  sx={{ display: 'flex' }}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "600" }}
                          >
                            {moment(contrato.createdAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2} sx={{ display: 'flex' }}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "600" }}
                          >
                            <Stack direction={"row"} gap={1}>
                              {contrato?.elaboradores_orcamento?.length > 0 && (
                                <>
                                  <Typography
                                    fontSize={"18px"}
                                    style={{ fontWeight: "600" }}
                                  >
                                    {
                                      contrato?.elaboradores_orcamento[0]
                                        ?.usuario?.name
                                    }
                                  </Typography>

                                  {contrato?.elaboradores_orcamento?.length >
                                    1 && (
                                    <Typography
                                      fontSize={"18px"}
                                      style={{ fontWeight: "600" }}
                                    >
                                      {contrato?.elaboradores_orcamento
                                        ?.length - 1}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Stack>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={1} sx={{ display: 'flex' }}>
                        <Box
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Ver detalhes">
                            <Visibility
                              cursor="pointer"
                              sx={{ color: "#000" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato.id}/detalhes`
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Exportar para PDF">
                            <PictureAsPdfIcon
                              cursor="pointer"
                              sx={{ color: "#CA0606" }}
                              onClick={() => {
                                // exportarContratoPDF(contrato.id);
                                const temRenova =
                                  contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Renovação".toLowerCase()
                                  );

                                const temFixo =
                                  contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Contrato fixo".toLowerCase()
                                  );

                                const temPadrao =
                                  contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() === "PADRÃO".toLowerCase()
                                  );

                                let tipoContrato = null;
                                if (temFixo && temRenova) {
                                  tipoContrato = "renovacaoFixoIndividual";
                                } else if (temFixo) {
                                  tipoContrato = "fixoIndividual";
                                } else if (temRenova) {
                                  tipoContrato = "renovacaoIndividual";
                                } else if (temPadrao) {
                                  tipoContrato = "padraoIndividual";
                                } else {
                                  tipoContrato = "renovacaoIndividual";
                                }

                                handleExportarContratoIndividualParaPDF(
                                  contrato?.id,
                                  tipoContrato
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Processos Administrativos">
                            <Description
                              cursor="pointer"
                              sx={{ color: "#4E5526" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato.id}/cliente/${contrato?.Cliente?.id}/processos`,
                                  {
                                    state: {
                                      descricao: contrato.descricao,
                                      id: contrato.id,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Editar">
                            <EditIcon
                              cursor="pointer"
                              // color="info"
                              sx={{ color: "#525252" }}
                              onClick={() => {
                                navigate(`/app/editar-contrato/${contrato.id}`);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Excluir">
                            <DeleteIcon
                              cursor="pointer"
                              sx={{ color: "#C84E4D" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato.id}/deletar`
                                );
                              }}
                              color="error"
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum contrato cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            <PaginationComponent
              page={page}
              setPage={setPage}
              count={count}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Grid>
        </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};
