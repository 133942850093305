import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const CustomDropdownIndicator = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "10px",
      }}
    >
      <ArrowDropDownIcon style={{ color: "#95A64A" }} />
    </div>
  );
};
const AutoCompleteAmbiental = ({
  value,
  name,
  label,
  required,
  options,
  nameOptionValue,
  nameOptionDescription,
  fullWidth,
  width,
  isMulti = false,
  placeholder,
  handleCreateData,
  setFieldValue,
  disabled = false,
  onChange,
  onBlur
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: fullWidth ? "100%" : width ? `${width}%` : "auto",
      minHeight: "40px",
      height: "40px",
      outline: "none",
      fontFamily: "sans-serif",
      fontSize: "14px",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      borderRadius: "10px",
      border: "1px solid #4E5526",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888888",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#5E5E5E",
    }),
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <label htmlFor={name} style={{ fontWeight: "bold" }}>
        {label}
        {required ? "*" : null}
      </label>
      <CreatableSelect
        isClearable
        styles={customStyles}
        isDisabled={disabled}
        name={name}
        isMulti={isMulti}
        onBlur={onBlur}
        onChange={(option) => {
          setFieldValue(name, option ? Number(option[nameOptionValue]) : "");
        }}
        onCreateOption={async (value) => {
          const { id } = await handleCreateData(value);
          setFieldValue(name, id);
        }}
        options={options}
        getOptionLabel={(option) =>
          option.__isNew__ ? option.label : option[nameOptionDescription]
        }
        value={
          isMulti
            ? options?.filter((option) =>
                selectedOptions?.includes(option[nameOptionValue])
              )
            : typeof value === "object"
            ? options?.find(
                (option) =>
                  option[nameOptionValue] === selectedOptions[nameOptionValue]
              )
            : options?.find((option) => option[nameOptionValue] === value)
        }
        placeholder={placeholder}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
      />
      {console.log(selectedOptions)}
    </div>
  );
};

export default AutoCompleteAmbiental;
