import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SelectAmbiental = ({
  onChange,
  value,
  name,
  label,
  required,
  options,
  nameOptionValue,
  nameOptionDescription,
  fullWidth,
  width,
  style,
  isMulti = false,
  disabled = false,
  onBlur,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [currentValue, setCurrentValue] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const isNumericValue = Number(value) && !isNaN(value);
    const selectedValue = isNumericValue ? Number(value) : value;
    if (isMulti) {
      if (selectedValue && !selectedOptions.includes(selectedValue)) {
        const updatedOptions = [...selectedOptions, selectedValue];
        setSelectedOptions(updatedOptions);
        onChange(updatedOptions); 
      }
      setCurrentValue(""); 
    } else {
      setSelectedOptions(selectedValue);
      onChange(event); 
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== optionToRemove
    );
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <label htmlFor={name} style={{ fontWeight: "bold" }}>
        {label}
        {required ? "*" : null}
      </label>
      <div
        style={{
          position: "relative",
          width: fullWidth ? "100%" : `${width}%`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            padding: "5px",
            borderRadius: "10px",
            border: "1px solid #4E5526",
            backgroundColor: "#FFFFFF",
            minHeight: "40px",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          {isMulti &&
            selectedOptions.map((selectedOption) => (
              <div
                key={selectedOption}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  fontSize: "14px",
                  maxWidth: "30%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    marginRight: "20px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {
                    options?.find(
                      (option) => option?.[nameOptionValue] === selectedOption
                    )?.[nameOptionDescription]
                  }
                </span>
                <button
                  type="button"
                  onClick={() => handleRemoveOption(selectedOption)}
                  style={{
                    position: "absolute",
                    right: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  &times;
                </button>
              </div>
            ))}
          <select
            value={isMulti ? currentValue : selectedOptions}
            onChange={handleSelectChange}
            onBlur={onBlur}
            disabled={disabled}
            name={name}
            style={{
              flex: 1,
              width: width ? `${width}%` : "auto",
              border: "none",
              outline: "none",
              fontFamily: "sans-serif",
              fontSize: "16px",
              color: "#5E5E5E",
              backgroundColor: "transparent",
              cursor: "pointer",
              padding: "5px",
              appearance: "none",
              WebkitAppearance: "none",
              MozAppearance: "none",
            }}
          >
            <option value="" disabled hidden>
              Selecionar
            </option>
            {options &&
              options.map((option) => (
                <option
                  key={option[nameOptionValue]}
                  value={option[nameOptionValue]}
                >
                  {option[nameOptionDescription]}
                </option>
              ))}
          </select>
        </div>

        <ArrowDropDownIcon
          sx={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
            width: "30px",
            height: "30px",
            color: "#95A64A",
          }}
        />
      </div>
    </div>
  );
};

export default SelectAmbiental;
