import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ApiService from "app/services/config";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility } from "@mui/icons-material";
import axios from "axios";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { mascaraCNPJ } from "app/utils/mascaraDados";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import DescriptionIcon from "@mui/icons-material/Description";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PaginationComponent from "app/components/Pagination/Pagination";
import BotoesExportarDados from "app/components/BotoesExportarDados";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const UnidadesClienteList = () => {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [unidades, setUnidades] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [grupoRestricao, setGrupoRestricao] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [todosItensSelecionados, setTodosItensSelecionados] = useState(false);
  const [unidadesRegulares, setUnidadesRegulares] = useState([]);
  const [unidadesIrregulares, setUnidadesIrregulares] = useState([]);
  const [unidadesArquivadas, setUnidadesArquivadas] = useState([]);
  const [clienteNome, setClienteNome] = useState(null);
  const [unidadeNome, setUnidadeNome] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState("");
  const [unidadesCadastradas, setUnidadesCadastradas] = useState(0);
  const [unidadesNovas, setUnidadesNovas] = useState(0);
  const [isOcultarScores, setIsOcultarScores] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    cidade: [],
    estado: "",
    statusUnidade: [],
    is_arquivada: false,
    termoBusca: "",
    page: 0,
    perPage: 10,
    sortOrder: "desc",
    sortBy: "createdAt",
  });
  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permissionCreate = hasPermission("Unidades", "create");
  const permissionUpdate = hasPermission("Unidades", "update");
  const permissionDelete = hasPermission("Unidades", "delete");

  // console.log('unidades',unidades)
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const { clienteId } = useParams();
  const Swal = useSwalWrapper();

  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id, acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "apagar" : "arquivar"
      }?`,
      text: `${
        acao === "deletar"
          ? "Não será possível reverter a ação!"
          : "Você pode desarquivar a unidade posteriormente!"
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        switch (acao) {
          case "deletar":
            deleteUnidade(id);
            break;
          case "arquivar":
            arquivarUnidade(id);
            break;
        }
      }
    });
  };
  const modalAlertVarios = (acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar"
          ? "apagar as licenças"
          : `${acao === "arquivar" ? "arquivar " : "desarquivar "} as licenças`
      }?`,
      text: `${
        acao === "deletar"
          ? "Não será possível reverter a ação!"
          : `Você pode ${
              acao === "arquivar" ? "desarquivar " : "arquivar "
            } as licenças posteriormente!`
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        switch (acao) {
          case "deletar":
            handleDeleteMany();
            break;
          case "arquivar":
            handleArquivarMany();
            break;
          case "desarquivar":
            handleDesarquivarMany();
            break;
        }
      }
    });
  };

  const handleDeleteMany = async () => {
    try {
      await ApiService.put("/unidade/many", selectedIds).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidades excluídas com sucesso!");
          getUnidades();
        }
        setSelectedIds([]);
        setTodosItensSelecionados(false);
        setPage(0);
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleArquivarMany = async () => {
    try {
      await ApiService.put(
        "/unidade/arquivar/varias/unidades",
        selectedIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidades arquivadas!");
          getUnidades();
        }
        setSelectedIds([]);
        setTodosItensSelecionados(false);
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const handleDesarquivarMany = async () => {
    try {
      await ApiService.put(
        "/unidade/desarquivar/varias/unidades",
        selectedIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidades arquivadas!");
          getUnidades();
        }
        setSelectedIds([]);
        setTodosItensSelecionados(false);
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.error(error);
    }
  };

  const modalAlertDesarquivar = (id) => {
    Swal.fire({
      title: `Tem certeza que quer desarquivar a unidade?`,
      text: `Você poderá arquivá-la novamente posteriormente!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        desarquivarUnidade(id);
      }
    });
  };

  const handleOcultarScores = () => {
    setIsOcultarScores(!isOcultarScores);
  };

  const deleteUnidade = async (id) => {
    try {
      await ApiService.delete(`/unidade/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade excluída!");
          getUnidades();
          setPage(0);
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getUnidades = useCallback(async () => {
    console.log("getUnidades");
    try {
      let filtros = {};
      if (filtrosPesquisa.cidade) {
        filtros.cidade = filtrosPesquisa.cidade;
      }
      if (filtrosPesquisa.estado) {
        filtros.estado = filtrosPesquisa.estado;
      }
      if (filtrosPesquisa.statusUnidade) {
        filtros.statusUnidade = filtrosPesquisa.statusUnidade;
      }
      if (searchTerm) {
        filtros.termoBusca = searchTerm;
      }
      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortBy = sortBy;
      filtros.sortOrder = sortOrder;
      filtros.is_arquivada = filtrosPesquisa.is_arquivada;

      console.log("searchTerm", searchTerm);
      console.log("filtros", filtros);

      await ApiService.post(`/unidade/cliente/${clienteId}`, filtros)
        .then((response) => {
          if (response.status === 201) {
            const initialData = response.data.unidades.map((unidade) => ({
              ...unidade,
              isSelected: selectedIds.includes(unidade.id),
            }));
            setUnidades(initialData);
            setCount(response.data.count);
            setUnidadesCadastradas(response.data.countUnidadesCadastradas);
            setUnidadesNovas(response.data.countUnidadesNovas);
            setUnidadesRegulares(response.data.countUnidadesRegulares);
            setUnidadesIrregulares(response.data.countUnidadesIrregulares);
            setUnidadesArquivadas(response.data.countUnidadesArquivadas);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error, error.message);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedIds]);

  const arquivarUnidade = async (id) => {
    try {
      await ApiService.put(`/unidade/arquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade arquivada!");
          getUnidades();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const desarquivarUnidade = async (id) => {
    try {
      await ApiService.put(`/unidade/desarquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade desarquivada!");
          getUnidades();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSelecionarTodosItens = () => {
    setSelectedIds(
      todosItensSelecionados ? [] : unidades.map((unidade) => unidade.id)
    );
    setTodosItensSelecionados(!todosItensSelecionados);
  };

  const handleLimparFiltros = () => {
    setFiltrosPesquisa({
      cidade: [],
      is_arquivada: false,
      estado: "",
      statusUnidade: [],
      termoBusca: "",
      page: 0,
      perPage: 10,
      sortOrder: "desc",
      sortBy: "createdAt",
    });
  };

  const getClienteNome = async () => {
    try {
      await ApiService.get(`/clientes/cliente/${clienteId}`).then(
        (response) => {
          if (response.status === 200) {
            const { nome } = response.data;
            setClienteNome(nome);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/clientes/breadcrumbs?clienteId=${clienteId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleCheckboxChange = (unidadeId) => {
  //   setUnidades((prevUnidades) =>
  //     prevUnidades.map((unidade) =>
  //       unidade.id === unidadeId
  //         ? { ...unidade, isSelected: !unidade.isSelected }
  //         : unidade
  //     )
  //   );

  //   setSelectedIds((prevSelectedIds) => {
  //     if (prevSelectedIds.includes(itemId)) {
  //       return prevSelectedIds.filter((id) => id !== itemId);
  //     } else {
  //       return [...prevSelectedIds, itemId];
  //     }
  //   });
  //   setTodosItensSelecionados(false);
  // };

  const handleCheckboxChange = (unidadeId) => {
    console.log("unidades", unidades);
    setUnidades((prevUnidades) =>
      prevUnidades.map((unidade) =>
        unidade.id === unidadeId
          ? { ...unidade, isSelected: !unidade.isSelected }
          : unidade
      )
    );

    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(unidadeId)) {
        return prevSelectedIds.filter((id) => id !== unidadeId);
      } else {
        return [...prevSelectedIds, unidadeId];
      }
    });

    // setTodosItensSelecionados(false);
  };

  const handleSearch = async (newSearchTerm) => {
    console.log("newSearchTerm", newSearchTerm);
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const unidadeId = tableMeta.rowData[1];
          const isChecked = selectedIds.includes(unidadeId);
          return (
            <Checkbox
              checked={isChecked}
              onChange={() => handleCheckboxChange(unidadeId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "nomeUnidade",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "razaoSocial",
      label: "Razão social",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : "N/I"}</>;
        },
      },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? mascaraCNPJ(value) : "N/I"}</>;
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const unidadeId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              {/* <Tooltip title="Detalhes da unidade">
                <Visibility
                  color="success"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/app/unidade/${unidadeId}`, {
                      state: {
                        nomeCliente: location?.state?.nomeCliente
                          ? location?.state?.nomeCliente
                          : breadCrumbsInfo?.nome,
                        nomeUnidade: tableMeta.rowData[2],
                        clienteId,
                        unidadeId
                      },
                    });
                  }}
                />
              </Tooltip> */}
              <Tooltip title="Editar unidade">
                <EditIcon
                  cursor={permissionUpdate ? "pointer" : "not-allowed"}
                  color="info"
                  style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                  onClick={() => {
                    if (permissionUpdate) {
                      navigate(
                        `/app/cliente/${clienteId}/unidades/editar/${unidadeId}`,
                        {
                          state: {
                            nomeCliente: location?.state?.nomeCliente,
                          },
                        }
                      );
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Imóveis da Unidade">
                <HomeIcon
                  cursor={"pointer"}
                  style={{ color: "#7FDE5D" }}
                  onClick={() => {
                    navigate(
                      `/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`,
                      {
                        state: {
                          nomeCliente: location?.state?.nomeCliente,
                          nomeUnidade: tableMeta.rowData[2],
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Deletar unidade">
                <DeleteIcon
                  cursor={permissionDelete ? "pointer" : "not-allowed"}
                  style={{ opacity: permissionDelete ? 1 : 0.5 }}
                  color="error"
                  onClick={() => {
                    if (permissionDelete) {
                      modalAlert(unidadeId, "deletar");
                    }
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  useEffect(() => {
    getUnidades();
  }, [filtrosPesquisa, page, perPage, sortBy, sortOrder, searchTerm]);

  useEffect(() => {
    getEstados();
    if (!location?.state?.nomeCliente) {
      getClienteNome();
    }
    getBreadCrumbsInfo();
  }, [clienteId]);

  return (
    <Box>
      <BackNavigation url={"/app/listar-clientes"} />
      <Tag label={breadCrumbsInfo?.nome} sx={{ marginBottom: 3 }} />

      <JumboContentLayout
        header={
          <PageHeader
            title={"Unidades"}
            icon="desc"
            btn={
              <ActionButton
                title="Nova unidade"
                to={permissionCreate ? `/app/cliente/${clienteId}/unidades/nova/` : null}
                color="green"
                icon={<QueueIcon />}
                disabled={!permissionCreate}
              />
            }
          />
        }
        layoutOptions={layoutOptions}
      >
        {lg && (
          <Stack
            spacing={2}
            direction={"row"}
            sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
          ></Stack>
        )}

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Grid
            container
            gap={1}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Div
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Pesquisar..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: 2,
                      border: "1px solid #4E5526",
                      paddingLeft: 2,
                      height: "36px",
                      "&:hover": { borderColor: "#3B4220" },
                      "&.Mui-focused": { borderColor: "#4E5526" },
                      color: "#4E5526",
                      "&::placeholder": { color: "red", opacity: 1 },
                      width: 350,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            backgroundColor: "#4E5526",
                            borderRadius: 2,
                            minWidth: "36px",
                            height: "36px",
                            padding: 0,
                            "&:hover": { backgroundColor: "#3B4220" },
                          }}
                          onClick={() => handleSearch(inputValue)}
                        >
                          <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {(selectedIds.length > 0 && permissionDelete) && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      modalAlertVarios("deletar");
                    }}
                    sx={{
                      borderRadius: 2,
                      paddingX: 2,
                      backgroundColor: "#C84E4D",
                      textTransform: "none",
                    }}
                  >
                    Excluir {selectedIds.length} unidade(s)
                  </Button>
                )}
                <BotoesExportarDados
                  titulo={`Unidades do cliente ${breadCrumbsInfo?.nome}`}
                  colunas_planilha={[
                    "nomeUnidade",
                    "razaoSocial",
                    "cnpj",
                    "createdAt",
                  ]}
                  dados_planilha={unidades}
                  nome_arquivo_planilha={`unidades_cliente_${breadCrumbsInfo?.nome}_`}
                />
              </Box>
            </Div>

            <Card
              sx={{
                p: 2,
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <Grid
                container
                // justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <Checkbox
                    sx={{
                      color: "#696969",
                      "&.Mui-checked": {
                        color: "#95A64A",
                      },
                    }}
                    onChange={handleSelecionarTodosItens}
                  />
                </Grid>
                <Grid item lg={3} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Nome
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Razão social
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      CNPJ
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Ações
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid container mt={3} gap={1}>
            {unidades.length > 0 ? (
              <>
                {unidades.map((u, key) => (
                  <Card
                    sx={{
                      p: 2,
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      // gap={2}
                    >
                      <Grid item lg={1}>
                        <Checkbox
                          sx={{
                            color: "#696969",
                            "&.Mui-checked": {
                              color: "#95A64A",
                            },
                          }}
                          key={u.id}
                          checked={selectedIds.includes(u.id)}
                          onChange={() => handleCheckboxChange(u.id)}
                        />
                      </Grid>
                      <Grid item lg={3}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          <Typography fontSize={18} sx={{ display: "flex" }}>
                            {u.nomeUnidade ?? "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18} sx={{ display: "flex" }}>
                            {u.razaoSocial && u.razaoSocial !== ""
                              ? u.razaoSocial
                              : "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={18} sx={{ display: "flex" }}>
                            {u.cnpj && u.cpnj !== "" ? mascaraCNPJ(u.cnpj) : "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3}>
                        <Box
                          display={"flex"}
                          gap={"1rem"}
                          justifyContent={"center"}
                        >
                          <Tooltip title="Editar unidade">
                            <EditIcon
                              cursor={
                                permissionUpdate ? "pointer" : "not-allowed"
                              }
                              // color="info"
                              style={{
                                opacity: permissionUpdate ? 1 : 0.5,
                                color: "#525252",
                              }}
                              onClick={() => {
                                if (permissionUpdate) {
                                  navigate(
                                    `/app/cliente/${clienteId}/unidades/editar/${u.id}`,
                                    {
                                      state: {
                                        nomeCliente:
                                          location?.state?.nomeCliente,
                                      },
                                    }
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Imóveis da Unidade">
                            <HomeIcon
                              cursor={"pointer"}
                              style={{ color: "#4E5526" }}
                              onClick={() => {
                                navigate(
                                  `/app/cliente/${clienteId}/unidade/${u.id}/imoveis`,
                                  {
                                    state: {
                                      nomeCliente: location?.state?.nomeCliente,
                                      nomeUnidade: u.nomeUnidade,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Deletar unidade">
                            <DeleteIcon
                              cursor={
                                permissionDelete ? "pointer" : "not-allowed"
                              }
                              style={{
                                opacity: permissionDelete ? 1 : 0.5,
                                color: "#C84E4D",
                              }}
                              // color="error"
                              onClick={() => {
                                if (permissionDelete) {
                                  navigate(`/app/cliente/${clienteId}/unidades/${u.id}/deletar`)
                                }
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum contrato cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>

          <PaginationComponent
            page={page}
            setPage={setPage}
            count={count}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Grid>
      </JumboContentLayout>
    </Box>
  );
};

export default UnidadesClienteList;
