import React from "react";

const BadgeNomeCliente = ({ nome, linkTo }) => {
  return (
    <div
      style={{
        width: "max-content",
        maxWidth: "max-content",
        padding: "8px",
        height: "30px",
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#4E5526",
        color: "#FFF",
        borderRadius: "20px",
        margin: "10px 0",
        cursor: "pointer",
      }}
      onClick={() => {
        window.location.href = linkTo;
      }}
    >
      {nome}
    </div>
  );
};

export default BadgeNomeCliente;
