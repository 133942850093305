import { Box } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Link } from "react-router-dom";


function BackNavigation({ url }) {
   return (
    <Box style={{ marginBottom: 20 }}>
        <Link to={url} style={{ 
            textDecoration: 'none',
            color: '#525252',
            alignItems: 'center',
            display: 'flex'
        }}>
            <ArrowLeftIcon sx={{ color: '#95A64A' }} />
            Voltar
        </Link>
    </Box>
   )
}

export default BackNavigation;
