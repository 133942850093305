import { Stack } from "@mui/material";
import React from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

const SelectFiltrosAmbiental = ({
  handleChange,
  options,
  value,
  defaultValue = null,
  defaultText = null,
  fullWidth = false,
  optionValueName,
  optionValueDescription,
}) => {
  return (
    <Stack
      direction={"row"}
      width={fullWidth ? "100%" : "auto"}
      style={{ position: "relative" }}
    >
      <div
        style={{
          backgroundColor: "#95A64A",
          color: "#FFFFFF",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "160px",
          height: "40px",
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        Selecionar
      </div>
      <select
        onChange={handleChange}
        value={value}
        style={{
          width: "100%",
          borderRadius: "0px 10px 10px 0px",
          border: "1px solid #4E5526",
          height: "40px",
          outline: "none",
          padding: "5px 20px",
          fontFamily: "sans-serif",
          fontSize: "16px",
          color: "#95A64A",
          position: "relative",
          boxSizing: "border-box",
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          backgroundColor: "#FFFFFF",
        }}
      >
        <option value={defaultValue ? defaultValue : "selecionar"}>
          {defaultText ? defaultText : "Selecionar"}
        </option>
        {options &&
          options.length > 0 &&
          options.map((option, index) => (
            <option key={`${option}${index}`} value={option[optionValueName]}>
              {option[optionValueDescription]}
            </option>
          ))}
      </select>
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: 5,
          transform: "translateY(-50%)",
        }}
      >
        <UnfoldMoreIcon
          style={{
            color: "#95A64A",
          }}
        />
      </div>
    </Stack>
  );
};

export default SelectFiltrosAmbiental;
