import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Card } from "@mui/material";
import React, { forwardRef, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const OBJETO_RESPONSAVEIS = {
  cliente: "Cliente",
  orgao: "Órgão",
  ambiental: "Ambiental",
  protocolado: "Protocolado",
};

const ListaServicosDashboard = forwardRef(
  (
    {
      processo,
      iniciarServico,
      concluirServico,
      aumentarPrioridade,
      diminuirPrioridade,
      paralisarServico,
      scrollToServicoId,
      setScrollToServicoId,
      servicosRefs,
      abaSelecionada,
      imoveisDosContratos,
    },
    ref
  ) => {
    const handleChangeScrollToServicoId = (servicoId) => {
      setScrollToServicoId(servicoId);
    };

    const navigate = useNavigate();

    return (
      <Accordion
        key={processo?.id}
        p={4}
        style={{
          width: "100%",
          height: "auto",
          marginTop: "20px",
          borderRadius: "10px",
          border: "none",
        }}
        defaultExpanded={false}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            borderRadius: "10px",
            border: "none",
          }}
        >
          <Grid container justifyContent="space-evenly" alignItems="center">
            <Grid item lg={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6,
                }}
              >
                <Typography fontSize={"12px"} style={{ fontWeight: "800" }}>
                  <Chip
                    color="success"
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {processo?.processos[0]?.contrato?.descricao}
                        {processo?.processos[0]?.contrato?.tipocontratocontrato?.some(
                          (tipoContrato) =>
                            String(
                              tipoContrato.tipos_contratos.descricao
                            ).toLowerCase() === "Renovação".toLowerCase()
                        ) && <ReplayIcon fontSize={"small"} gap={10} />}
                        {processo?.processos[0]?.contrato.tipocontratocontrato?.some(
                          (tipoContrato) =>
                            String(
                              tipoContrato.tipos_contratos.descricao
                            ).toLowerCase() === "contrato fixo"
                        ) && <StarIcon fontSize={"small"} gap={10} />}
                      </Box>
                    }
                  />
                </Typography>
                <Stack width="100%" direction="row" gap={2}>
                  <Typography fontSize={"18px"} fontWeight={600}>
                    Cliente:{" "}
                  </Typography>
                  <Typography fontSize={"18px"}>
                    {processo?.processos[0]?.contrato?.Cliente?.nome}
                  </Typography>
                </Stack>
                {imoveisDosContratos[processo?.processos[0]?.id]?.map(
                  (imovel) => (
                    <Box
                      key={`${imovel.descricao}-${imovel.unidade}`}
                      sx={{ mb: 1 }}
                    >
                      {imovel.unidade && (
                        <Stack width="100%" direction="row" gap={2}>
                          <Typography fontSize={"18px"} fontWeight={600}>
                            Unidade:{" "}
                          </Typography>
                          <Typography fontSize={"18px"}>
                            {processo?.processos[0]?.contrato?.Cliente?.nome}
                          </Typography>
                        </Stack>
                      )}
                      <Stack width="100%" direction="row" gap={2}>
                        <Typography fontSize={"18px"} fontWeight={600}>
                          Imóvel:{" "}
                        </Typography>
                        <Typography fontSize={"18px"}>
                          {imovel?.descricao}
                        </Typography>
                      </Stack>
                    </Box>
                  )
                )}
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography fontSize={"20px"} style={{ fontWeight: 600 }}>
                  {processo?.processos[0]?.processo_administrativo?.descricao ??
                    "N/I"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography fontSize={"20px"} style={{ fontWeight: 600 }}>
                  Prioridade
                </Typography>
                <Stack direction={"row"} gap={1} my={1}>
                  <Tooltip
                    title="Aumentar prioridade"
                    onClick={() => {
                      aumentarPrioridade(processo?.id);
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: "30px",
                        width: "30px",
                        backgroundColor: "transparent",
                        border: "2px solid #4E5526",
                        borderRadius: 9999,
                      }}
                    >
                      <KeyboardArrowUpIcon
                        style={{ color: "#4E5526" }}
                        cursor="pointer"
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title="Diminuir prioridade"
                    onClick={() => {
                      diminuirPrioridade(processo?.id);
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: "30px",
                        width: "30px",
                        backgroundColor: "transparent",
                        border: "2px solid #4E5526",
                        borderRadius: 9999,
                      }}
                    >
                      <KeyboardArrowDownIcon
                        color="info"
                        cursor="pointer"
                        style={{ color: "#4E5526" }}
                      />
                    </Box>
                  </Tooltip>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderRadius: "10px",
            border: "none",
            height: "auto",
          }}
        >
          <Grid container width="100%" p={6}>
            <Grid item xs={3}>
              <Typography fontSize="18px" fontWeight={600}>
                Tarefa
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography fontSize="18px" fontWeight={600}>
                Situação
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography fontSize="18px" fontWeight={600}>
                Datas
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography fontSize="18px" fontWeight={600}>
                Responsabilidade
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography fontSize="18px" fontWeight={600}>
                Ações
              </Typography>
            </Grid>
          </Grid>
          {processo?.processos[0]?.servicos &&
          processo?.processos[0]?.servicos.length > 0 ? (
            processo?.processos[0]?.servicos.map((servico, index) => (
              <>
                <Grid
                  container
                  width="100%"
                  my={1}
                  px={6}
                  key={`${servico.id}-${index}`}
                >
                  <Grid item xs={3}>
                    <Typography fontSize="16px">
                      {servico?.tarefas_servico[0]?.descricao ?? "N/I"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography fontSize="16px">
                      {servico?.status_andamento_servico?.descricao ?? "N/I"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography fontSize={"16px"}>
                        Início:{" "}
                        {servico?.data_inicio
                          ? moment
                              .utc(servico?.data_inicio)
                              .format("DD/MM/YYYY")
                          : "Não iniciado"}
                      </Typography>
                      <Typography fontSize={"16px"}>
                        Conclusão:{" "}
                        {servico?.data_conclusao
                          ? moment
                              .utc(servico?.data_conclusao)
                              .format("DD/MM/YYYY")
                          : "Sem data"}
                      </Typography>
                      <Typography fontSize={"16px"}>
                        Prazo:{" "}
                        {servico?.prazo
                          ? moment.utc(servico?.prazo).format("DD/MM/YYYY")
                          : "Sem data"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography fontSize={"16px"}>
                      {OBJETO_RESPONSAVEIS[servico?.responsavel]}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        fontSize={"18px"}
                        style={{ fontWeight: "500" }}
                      >
                        Ações
                      </Typography>
                      <Stack direction={"row"} gap={1}>
                        <Tooltip title="Acompanhar Serviço">
                          <VisibilityIcon
                            cursor="pointer"
                            style={{
                              color: "#4E5526",
                            }}
                            onClick={() => {
                              navigate(
                                `/app/contrato/${processo?.contrato?.id}/cliente/${processo?.contrato?.Cliente?.id}/processos/${processo?.id}/procadmin/${processo?.processo_administrativo?.id}/servicos/${servico?.id}/detalhes`
                              );
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            servico.status_andamento_servico.id === 1
                              ? "Serviço já iniciado"
                              : "Iniciar Serviço"
                          }
                          onClick={() => {
                            iniciarServico(
                              servico.id,
                              servico.status_andamento_servico.id
                            );
                            // handleChangeScrollToServicoId(servico.id);
                          }}
                        >
                          <PlayArrowIcon
                            color={
                              abaSelecionada === "em_andamento"
                                ? "success"
                                : "disabled"
                            }
                            cursor="pointer"
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            servico.status_andamento_servico.id === 6 &&
                            !servico.is_paralisado
                              ? "Serviço já paralisado"
                              : "Paralisar Serviço"
                          }
                          onClick={() => {
                            paralisarServico(
                              servico.id,
                              servico.status_andamento_servico.id
                            );
                            // handleChangeScrollToServicoId(servico.id);
                          }}
                        >
                          <PauseIcon
                            color={
                              servico.status_andamento_servico.id === 6 &&
                              !servico.is_paralisado
                                ? "warning"
                                : "disabled"
                            }
                            cursor="pointer"
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            servico.status_andamento_servico.id === 12 ||
                            servico.status_andamento_servico.id === 13
                              ? "Serviço já concluído"
                              : "Concluir Serviço"
                          }
                          onClick={() => {
                            concluirServico(
                              servico.id,
                              servico.status_andamento_servico.id,
                              servico?.Contrato?.id
                            );
                            // handleChangeScrollToServicoId(servico.id);
                          }}
                        >
                          <StopIcon
                            color={
                              servico.status_andamento_servico.id === 12 ||
                              servico.status_andamento_servico.id === 13
                                ? "error"
                                : "disabled"
                            }
                            cursor="pointer"
                          />
                        </Tooltip>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    marginTop: "20px",
                  }}
                />
              </>
            ))
          ) : (
            <Box width="100%" p={2} textAlign={"center"}>
              Nenhum serviço encontrado.
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default ListaServicosDashboard;
