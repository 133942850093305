import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  FormGroup,
  Grid,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ErrorMessage, Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ActionButton from "app/components/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import BackNavigation from "app/components/BackNavigation/BackNavigation";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  userId: yup.number().required("Campo obrigatório"),
  dataVencimento: yup
    .string()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  status: yup.string().required("Campo obrigatório"),
  nome_licenca_id: yup.number().required("Campo obrigatório"),
  numero: yup.string().required("Campo obrigatório"),
  data_emissao: yup
    .date()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  data_expiracao: yup
    .date()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  observacao: yup.string().required("Campo obrigatório"),
  anexo: yup.string().required("Campo obrigatório"),
  termo_fat: yup.number().required("Campo obrigatório"),
  processo_contrato_id: yup.number().required("Campo obrigatório"),
});

const LicencasContratoForm = () => {
  const { id, contratoId, processoId, clienteId, licencaId, procadmin } =
    useParams();
  const userId = Number(localStorage.getItem("id"));
  const initialValues = {
    userId: userId,
    dataVencimento: null,
    status: "",
    nome_licenca_id: "",
    numero: "",
    data_emissao: null,
    data_expiracao: null,
    observacao: "",
    anexo: "",
    termo_fat: 0,
    processo_contrato_id: Number(processoId),
  };
  const [licenca, setLicenca] = useState(initialValues);
  const [nomesLicencas, setNomesLicencas] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const location = useLocation();
  const { theme } = useJumboTheme();

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const formRef = useRef();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSubmit = async (values) => {
    try {
      if (Object.entries(formRef.current.errors)?.length > 0) {
        toast("warning", "Corrija os erros no formulário!");
        return;
      }
      const valuesEdited = values;
      delete valuesEdited.nome_licenca;

      if (licencaId) {
        await ApiService.put(`/licenca/${licencaId}`, valuesEdited).then(
          (response) => {
            if (response.status === 200) {
              toast("success", "Atualizado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
              );
            }
          }
        );
      } else {
        await ApiService.post(`/licenca/criar`, valuesEdited).then(
          (response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
              );
            }
          }
        );
      }
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getLicenca = async () => {
    try {
      await ApiService.get(`/licenca/${licencaId}`).then((response) => {
        if (response.status === 200) {
          setLicenca({
            ...response.data,
            data_emissao: moment
              .utc(response.data?.data_emissao)
              .format("YYYY-MM-DD"),
            data_expiracao: moment
              .utc(response.data?.data_expiracao)
              .format("YYYY-MM-DD"),
            dataVencimento: moment
              .utc(response.data?.dataVencimento)
              .format("YYYY-MM-DD"),
            userId: Number(userId),
            processo_contrato_id: Number(processoId),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const modalAlertDeleteLicenca = (licencaId) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar a licença?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteLicenca(licencaId);
      }
    });
  };

  const deleteLicenca = useCallback(async (licencaId) => {
    try {
      ApiService.delete(`/licenca/${licencaId}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(
            `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
          );
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getNomesLicencas = async () => {
    try {
      await ApiService.get("/nomes/licenca/all").then((response) => {
        if (response.status === 200) {
          setNomesLicencas(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNomeLicenca = useCallback(async (descricao) => {
    const response = await ApiService.post("/nomes/licenca", {
      nome: descricao,
    })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getNomesLicencas();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  useEffect(() => {
    if (licencaId) {
      getLicenca();
    }
    getNomesLicencas();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [licencaId]);

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&licenca=${licencaId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <Box sx={{ position: "relative" }}>
      <JumboContentLayout layoutOptions={layoutOptions}>
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}
        <BackNavigation
          url={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`}
        />
        <BadgeNomeCliente nome={breadCrumbsInfo?.descricao} />
        {breadCrumbsInfo ? (
          <Typography variant={"h4"} fontWeight={600}>
            {
              breadCrumbsInfo?.processos_contratos?.[0]?.processo_administrativo
                ?.descricao
            }
          </Typography>
        ) : null}
        <PageHeader
          title={id ? `Editar licença` : "Adicionar licença"}
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />
        <Formik
          innerRef={formRef}
          initialValues={licenca}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            setSubmitting,
            resetForm,
            errors,
            setFieldTouched,
            touched,
          }) => (
            <Form
              style={{
                width: "100%",
                minHeight: "50vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Grid container width="100%" spacing={2}>
                  <Grid item xs={3}>
                    <InputAmbiental
                      label={"Observação"}
                      name="observacao"
                      value={values.observacao}
                      fullWidth={true}
                      onChange={handleChange}
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("observacao", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.observacao && errors.observacao && (
                      <div style={{ color: "red" }}>{errors.observacao}</div>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <SelectAmbiental
                      label={"Licença"}
                      name={"nome_licenca_id"}
                      fullWidth={true}
                      onChange={(event) => {
                        setFieldValue(
                          "nome_licenca_id",
                          Number(event.target.value)
                        );
                      }}
                      options={nomesLicencas}
                      value={values.nome_licenca_id}
                      nameOptionValue={"id"}
                      nameOptionDescription={"nome"}
                      onBlur={(e) => {
                        setFieldTouched("nome_licenca_id", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.nome_licenca_id && errors.nome_licenca_id && (
                      <div style={{ color: "red" }}>
                        {errors.nome_licenca_id}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputAmbiental
                      label={"Status"}
                      name="status"
                      value={values.status}
                      fullWidth={true}
                      onChange={handleChange}
                      type="text"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("status", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.status && errors.status && (
                      <div style={{ color: "red" }}>{errors.status}</div>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputAmbiental
                      label={"Número"}
                      name="numero"
                      value={values.numero}
                      fullWidth={true}
                      onChange={handleChange}
                      type="text"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("numero", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.numero && errors.numero && (
                      <div style={{ color: "red" }}>{errors.numero}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={3}>
                    <InputAmbiental
                      label={"Anexo"}
                      name="anexo"
                      value={values.anexo}
                      fullWidth={true}
                      onChange={handleChange}
                      type="text"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("anexo", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.anexo && errors.anexo && (
                      <div style={{ color: "red" }}>{errors.anexo}</div>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputAmbiental
                      label={"Termo FAT"}
                      name="termo_fat"
                      value={values.termo_fat}
                      fullWidth={true}
                      onChange={handleChange}
                      type="number"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("termo_fat", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.termo_fat && errors.termo_fat && (
                      <div style={{ color: "red" }}>{errors.termo_fat}</div>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <InputAmbiental
                      label={"Data de Vencimento"}
                      name="dataVencimento"
                      value={values.dataVencimento}
                      fullWidth={true}
                      onChange={handleChange}
                      type="date"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("dataVencimento", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.dataVencimento && errors.dataVencimento && (
                      <div style={{ color: "red" }}>
                        {errors.dataVencimento}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <InputAmbiental
                      label={"Data de Expiração"}
                      name="data_expiracao"
                      value={values.data_expiracao}
                      fullWidth={true}
                      onChange={handleChange}
                      type="date"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("data_expiracao", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.data_expiracao && errors.data_expiracao && (
                      <div style={{ color: "red" }}>
                        {errors.data_expiracao}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <InputAmbiental
                      label={"Data de Emissão"}
                      name="data_emissao"
                      value={values.data_emissao}
                      fullWidth={true}
                      onChange={handleChange}
                      type="date"
                      disabled={isDeleteMode}
                      handleBlur={(e) => {
                        setFieldTouched("data_emissao", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.data_emissao && errors.data_emissao && (
                      <div style={{ color: "red" }}>{errors.data_emissao}</div>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                my={3}
                sx={{
                  position: "sticky",
                  right: 0,
                  bottom: 10,
                }} 
              >
                {isDeleteMode ? (
                  <Stack direction="row" gap={2} display={"flex"}>
                    <Box>
                      <ActionButton
                        title="Excluir licença"
                        to={null}
                        action={() => {
                          modalAlertDeleteLicenca(licencaId);
                        }}
                        isSubmitAction={false}
                        color="red"
                        icon={<DeleteIcon />}
                      />
                    </Box>
                    <Box>
                      <ActionButton
                        title="Cancelar"
                        to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas}`}
                        color="blue"
                        icon={<ArrowBackIcon />}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <ActionButton
                    title={licencaId ? "Atualizar" : "Criar"}
                    to={null}
                    color="blue"
                    icon={<BorderColorIcon />}
                    isSubmitAction={true}
                    action={() => {
                      handleSubmit(
                        values,
                        setSubmitting,
                        resetForm,
                        setFieldValue
                      );
                    }}
                  />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </JumboContentLayout>
    </Box>
  );
};

export default LicencasContratoForm;
