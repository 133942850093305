import { Chip, Typography } from "@mui/material";
import React from "react";


const MoneyBox = ({ contrato = null, valorAtualRenegociacao= null, valorBase = null, valorPago = null }) => {
    console.log('valorPago', valorPago)
  return (
    <Chip
        style={{
            width: "100%",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#A08438",
            padding: 10,
            backgroundColor: "#FFF3CE",
            borderRadius: 6,
        }}
        label={
            <Typography fontSize={"36px"} fontWeight={600}>
            {valorPago !== null ? (
                valorPago.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                })
            ):(
                contrato?.renegociacoescontrato?.length > 0 ? (
                    <>
                    {valorAtualRenegociacao.toLocaleString(
                        "pt-BR",
                        {
                        style: "currency",
                        currency: "BRL",
                        }
                    )}{" "}
                    em{" "}
                    {`${
                        contrato.renegociacoescontrato[
                        contrato.renegociacoescontrato.length - 1
                        ].numeroParcelas
                    }x`}
                    </>
                ) : (
                    <>
                    {valorBase.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })}
                    </>
                )
            )}
            </Typography>
        }
    />
  );
};

export default MoneyBox;
