import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Avatar, Box, Breadcrumbs, Button, Chip, Divider } from "@mui/material";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import ContratoDetalhesServicos from "app/components/ContratoDetalhesServicos/ContratoDetalhesServicos";
import DetalhesClienteContrato from "app/components/DetalhesClienteContrato/DetalhesClienteContrato";
import DetalhesContrato from "app/components/DetalhesContrato/DetalhesContrato";
import { PermissionContext } from "app/contexts/PermissionContext";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import { Paid, PaidOutlined, Topic } from "@mui/icons-material";
import ModalHistoricoPagamentoContrato from "app/components/ModalHistoricoPagamentoContrato/ModalHistoricoPagamentoContrato";
import ModalPagamentoContrato from "app/components/ModalPagamentoContrato/ModalPagamentoContrato";
import ModalHistoricoRenegociacaoContrato from "app/components/ModalHistoricoRenegociacaoPagamento/ModalHistoricoRenegociacaoPagamento";
import ModalRenegociarContrato from "app/components/ModalRenegociarContrato/ModalRenegociarContrato";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import { useJumboTheme } from "@jumbo/hooks";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Tag from "app/components/Tag/Tag";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyBox from "app/components/MoneyBox/MoneyBox";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import gerarPDFContratoPadraoIndividual from "app/utils/pdfs/gerarPDFContratoPadraoIndividual";
import gerarPDFContratoFixoIndividual from "app/utils/pdfs/gerarPDFContratoFixoIndividual";
import gerarPDFContratoRenovacaoIndividual from "app/utils/pdfs/gerarPDFContratoRenovacaoIndividual";
import gerarPDFContratoFixoRenovacaoIndividual from "app/utils/pdfs/gerarPDFContratoFixoRenovacaoIndividual";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ContratoDetalhes = () => {
  const { id } = useParams();
  const [contrato, setContrato] = useState([]);
  const [valorTotalContrato, setValorTotalContrato] = useState(0);
  const [isModalRenegociacaoAberto, setIsModalRenegociacaoAberto] =
    useState(false);
  const [valorPago, setValorPago] = useState(0);
  const [valorBase, setValorBase] = useState(0);
  const [valorAtualRenegociacao, setValorAtualRenegociacao] = useState(0);
  const [isModalPagamentoAberto, setIsModalPagamentoAberto] = useState(false);
  const [isModalHistoricoPagamentoAberto, setIsModalHistoricoPagamentoAberto] =
    useState(false);
  const [
    isModalHistoricoRenegociacaoAberto,
    setIsModalHistoricoRenegociacaoAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const { theme } = useJumboTheme();
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteContrato(id);
      }
    });
  };

  const handleOpenModalRenegociacao = () => {
    setIsModalRenegociacaoAberto(!isModalRenegociacaoAberto);
  };

  const handleOpenModalPagamento = () => {
    setIsModalPagamentoAberto(!isModalPagamentoAberto);
  };

  const handleOpenModalHistoricoPagamento = () => {
    setIsModalHistoricoPagamentoAberto(!isModalHistoricoPagamentoAberto);
  };

  const handleOpenModalHistoricoRenegociacao = () => {
    setIsModalHistoricoRenegociacaoAberto(!isModalHistoricoRenegociacaoAberto);
  };

  const handleAtualizarValorPago = (pagamento, acao) => {
    const { valor, id } = pagamento;

    if (acao === "remocao") {
      const novoPagamentosContrato = contrato.pagamentoscontrato.filter(
        (pag) => pag.id !== id
      );
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: novoPagamentosContrato,
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev - valor);
    } else {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: [...contrato.pagamentoscontrato, pagamento],
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev + valor);
    }
  };

  const handleAtualizarValorBase = (valor) => {
    setValorBase(valor);
  };

  const handleAtualizarValorAtualComRenegociacao = (dados, acao) => {
    switch (acao) {
      case "adicao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: [...prevContrato.renegociacoescontrato].concat(
            dados
          ),
        }));
        break;
      case "remocao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: dados,
        }));
        break;
    }
  };

  const handleAtualizarValorPagoAposEdicao = (idPagamento, novoValor) => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: contrato.pagamentoscontrato.map((pagamento) =>
          pagamento.id === idPagamento
            ? { ...pagamento, valor: novoValor }
            : pagamento
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleAtualizarRenegociacaoAposEdicao = (
    idRenegociacao,
    valor,
    parcelas
  ) => {
    if (contrato && contrato.renegociacoescontrato) {
      const novoContrato = {
        ...contrato,
        renegociacoescontrato: contrato.renegociacoescontrato.map(
          (renegociacao) =>
            renegociacao.id === idRenegociacao
              ? {
                  ...renegociacao,
                  valor,
                  numeroParcelas: parcelas,
                }
              : renegociacao
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleGetContratoDetalhes = useCallback(async () => {
    try {
      await ApiService.get(`/contratos/${id}`).then((response) => {
        if (response.status === 200) {
          setContrato(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const handleDeleteContrato = async () => {
    try {
      await ApiService.delete(`/contratos/${id}`).then((response) => {
        if (response.status === 200) {
          navigate("/app/listar-contratos");
          toast("success", "Contrato excluído!");
        }
      });
    } catch (error) {
      toast("error", "Erro ao excluir o contrato!");
      console.log(error);
    }
  };

  const handleCalcularValorTotalContrato = () => {
    let valorTotal = 0;

    if (contrato?.renegociacoescontrato?.length > 0) {
      const ultimaRenegociacao =
        contrato?.renegociacoescontrato[
          contrato?.renegociacoescontrato.length - 1
        ];
      valorTotal = ultimaRenegociacao?.valor ?? 0;
    } else {
      contrato?.servicos?.forEach((servico) => {
        const quantidade = servico?.quantidade ?? 0;
        const valor = servico?.valor ?? 0;
        valorTotal += quantidade * valor;
      });
    }

    setValorTotalContrato(
      Number(valorTotal).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );
  };

  useEffect(() => {
    handleGetContratoDetalhes();
  }, []);

  useEffect(() => {
    if (contrato) {
      handleCalcularValorTotalContrato();
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoValorPago = contrato.pagamentoscontrato.reduce(
        (total, pagamento) => total + pagamento.valor,
        0
      );
      setValorPago(novoValorPago);
    } else {
      setValorPago(0);
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato && contrato.servicos && contrato.servicos.length > 0) {
      let somaValorBase = 0;
      contrato.servicos.forEach((servico) => {
        somaValorBase += servico.valor * servico.quantidade;
        handleAtualizarValorBase(somaValorBase);
      });
    }
  }, [contrato]);

  useEffect(() => {
    if (
      contrato &&
      contrato.renegociacoescontrato &&
      contrato.renegociacoescontrato.length > 0
    ) {
      const valorRenegociacao = contrato.renegociacoescontrato[0].valor;
      setValorAtualRenegociacao(valorRenegociacao);
    }
  }, [contrato]);

  const handleExportarContratoIndividualParaPDF = () => {
    const idContrato = id;
    const tipoContrato = contrato?.tipocontratocontrato[0]?.tipoContratoId;

    console.log(`ID do Contrato: ${idContrato}`);
    console.log(`Tipo do Contrato: ${tipoContrato}`);

    switch (tipoContrato) {
      case 1:
        gerarPDFContratoRenovacaoIndividual(idContrato);
        break;
      case 2:
        gerarPDFContratoFixoIndividual(idContrato);
        break;
      case 4:
        gerarPDFContratoPadraoIndividual(idContrato);
        break;
    }
  };

  // console.log('contrato', contrato)
  return (
    <Box sx={{
      position: 'relative'
    }}>
      <BackNavigation url={"/app/listar-contratos"} />
      <Tag label={contrato?.descricao} sx={{ marginBottom: 3 }} />
      {/* <Box style={{ marginBottom: 30 }}> */}
      <JumboContentLayout
        header={
          <PageHeader
            title={`Contratos > ${contrato?.descricao}`}
            icon="desc"
            btn={
              <ActionButton
                title="Gerar PDF"
                action={handleExportarContratoIndividualParaPDF}
                color="pink"
                icon={<PictureAsPdfIcon sx={{ color: "#212529" }} />}
              />
            }
          />
        }
        layoutOptions={layoutOptions}
      >
        <Grid container spacing={2} flexDirection={"column"}>
          <DetalhesContrato
            contrato={contrato}
            id={id}
            modalAlert={modalAlert}
          />
          <Divider sx={{ my: 2, borderColor: "#4E5526" }} />
          <DetalhesClienteContrato contrato={contrato} />

          <Grid item xs={12} marginY={3}>
            <Paper
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                gap: 1,
                backgroundColor: "#4E5526",
              }}
            >
              <Typography
                variant={"h2"}
                fontWeight={400}
                color="#FFF"
                margin={0}
              >
                Valor Total do Contrato:
              </Typography>
              <Typography
                variant={"h2"}
                fontWeight={600}
                color="#FFF"
                margin={0}
              >
                {valorTotalContrato}
              </Typography>
            </Paper>
          </Grid>

          <ModalRenegociarContrato
            aberto={isModalRenegociacaoAberto}
            handleClose={handleOpenModalRenegociacao}
            contratoId={Number(id)}
            atualizarValorRenegociacao={
              handleAtualizarValorAtualComRenegociacao
            }
            valorAtualContrato={valorBase}
          />
          <ModalHistoricoRenegociacaoContrato
            aberto={isModalHistoricoRenegociacaoAberto}
            handleClose={handleOpenModalHistoricoRenegociacao}
            contratoId={id}
            atualizarValorRenegociacao={
              handleAtualizarValorAtualComRenegociacao
            }
            valorBase={valorBase}
            atualizarValorAposEdicao={handleAtualizarRenegociacaoAposEdicao}
          />
          <ModalPagamentoContrato
            aberto={isModalPagamentoAberto}
            handleClose={handleOpenModalPagamento}
            contratoId={id}
            atualizarValorPago={handleAtualizarValorPago}
          />
          <ModalHistoricoPagamentoContrato
            aberto={isModalHistoricoPagamentoAberto}
            handleClose={handleOpenModalHistoricoPagamento}
            contratoId={id}
            atualizarValorPago={handleAtualizarValorPago}
            atualizarValorAposEdicao={handleAtualizarValorPagoAposEdicao}
          />

          <Grid container paddingLeft={2} spacing={4}>
            {/* Itens esquerda */}
            <Grid
              container
              item
              xs={6}
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  fontSize={24}
                  fontWeight={800}
                  display="flex"
                  alignItems="center"
                >
                  <AttachMoneyIcon sx={{ fontSize: 26, marginRight: 1 }} />
                  Valor atual
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MoneyBox
                  contrato={contrato}
                  valorAtualRenegociacao={valorAtualRenegociacao}
                  valorBase={valorBase}
                />
              </Grid>
              <ActionButton
                title={"Renegociar"}
                color={"green"}
                icon={<MonetizationOnIcon />}
                action={handleOpenModalRenegociacao}
              />
              <ActionButton
                title={"Ver histórico"}
                color={"gray"}
                action={handleOpenModalHistoricoRenegociacao}
              />
            </Grid>

            {/* Itens direita */}
            <Grid
              container
              item
              xs={6}
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  fontSize={24}
                  fontWeight={800}
                  display="flex"
                  alignItems="center"
                >
                  <AttachMoneyIcon sx={{ fontSize: 26, marginRight: 1 }} />
                  Valor pago
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MoneyBox valorPago={valorPago} />
              </Grid>
              <ActionButton
                title={"Ver histórico"}
                color={"gray"}
                action={handleOpenModalHistoricoPagamento}
              />
            </Grid>
          </Grid>

          <ContratoDetalhesServicos contrato={contrato} />
        </Grid>

        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} my={2} sx={{
          position: "sticky",
          right: 0,
          bottom: 10
        }}>
          <Stack direction="row" spacing={2}>
            <ActionButton
              title={"Deletar"}
              color="red"
              icon={<DeleteIcon />}
              action={() => modalAlert(id)}
            />
            <ActionButton
              title={"Editar"}
              color="blue"
              icon={<EditIcon />}
              to={`/app/editar-contrato/${id}`}
            />
          </Stack>
        </Box>
      </JumboContentLayout>
    </Box>
  );
};

export default ContratoDetalhes;
