import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import ApiService from "app/services/config";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import BackNavigation from "app/components/BackNavigation/BackNavigation";
import Tag from "app/components/Tag/Tag";
import QueueIcon from "@mui/icons-material/Queue";
import ActionButton from "app/components/ActionButton/ActionButton";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import BotoesExportarDados from "app/components/BotoesExportarDados";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import OrdenacaoLista from "app/components/OrdenacaoLista";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const LicencasContratoList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const { contratoId, clienteId, processoId, procadmin } = useParams();
  const [licencas, setLicencas] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [selectedLicencasIds, setSelectedLicencasIds] = useState([]);
  const [todasLicencasSelecionadas, setTodasLicencasSelecionadas] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Contratos", "create");
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodasLicencas = () => {
    const todosIdsLicencas = licencas.map((licenca) => licenca.id);
    if (!todasLicencasSelecionadas) {
      setSelectedLicencasIds(todosIdsLicencas);
    } else {
      setSelectedLicencasIds([]);
    }
    setTodasLicencasSelecionadas(!todasLicencasSelecionadas);
  };

  const handleDeleteMuitasLicencas = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar as licenças selecionadas?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitasLicencas();
      }
    });
  };

  const deleteMuitasLicencas = useCallback(async () => {
    try {
      await ApiService.put("/licenca/deletar/many/", selectedLicencasIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Licenças removidas com sucesso!");
            getLicencas();
            setSelectedLicencasIds([]);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar as licenças selecionadas");
      console.log(error);
    }
  }, [selectedLicencasIds]);

  const getLicencas = useCallback(async () => {
    try {
      let apiUrl = `/licenca/contrato/${contratoId}/processo/${processoId}?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.licencas.map((licenca) => ({
            ...licenca,
            isSelected: selectedLicencasIds.includes(licenca.id),
          }));
          setLicencas(initialData);
          setCount(response.data.count);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedLicencasIds]);

  useEffect(() => {
    getLicencas();
  }, [getLicencas]);

  const handleCheckboxChange = (licencaId) => {
    setLicencas((prevLicencas) =>
      prevLicencas.map((licenca) =>
        licenca.id === licencaId
          ? { ...licenca, isSelected: !licenca.isSelected }
          : licenca
      )
    );

    setSelectedLicencasIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(licencaId)) {
        return prevSelectedIds.filter((id) => id !== licencaId);
      } else {
        return [...prevSelectedIds, licencaId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedLicencasIds.length > 0);
    setTodasLicencasSelecionadas(false);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const licencaId = tableMeta.rowData[1];
          const licenca = licencas.find((licenca) => licenca.id === licencaId);
          return (
            <Checkbox
              checked={licenca.isSelected}
              onChange={() => handleCheckboxChange(licencaId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "processo_administrativo",
      label: "Id Processo",
      options: {
        filter: true,
        sort: true,
        display: false,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value?.id}</>;
        },
      },
    },
    {
      name: "nome_licenca",
      label: "Licença",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value?.nome}</>;
        },
      },
    },
    {
      name: "numero",
      label: "Número",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "data_emissao",
      label: "Data de Emissão",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "data_expiracao",
      label: "Data de Expiração",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "dataVencimento",
      label: "Data de Vencimento",
      options: {
        filter: true,
        sort: true,
        enableNestedAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment.utc(value).format("DD/MM/YYYY")}</>;
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const licencaId = tableMeta.rowData[1];
          const procAdminId = tableMeta.rowData[2]?.id;
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Editar Licença">
                <EditIcon
                  cursor="pointer"
                  color="info"
                  disabled={!permission}
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procAdminId}/licencas/${licencaId}/editar`,
                      {
                        state: {
                          descricao:
                            location?.state?.descricao ??
                            licencas[0]?.contrato?.descricao,
                          processoDescricao: tableMeta.rowData[2]?.descricao,
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title="Excluir Licença">
                <DeleteIcon
                  cursor="pointer"
                  color="error"
                  onClick={() => {
                    navigate(
                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procAdminId}/licencas/${licencaId}/deletar`
                    );
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedLicencasIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Box>
        <BackNavigation
          url={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
        />
        <Tag label={breadCrumbsInfo?.descricao} sx={{ marginBottom: 3 }} />
      </Box>
      <Stack width="100%">
        <PageHeader
          title={"Licenças"}
          icon="desc"
          btn={
            <ActionButton
              title="Nova licença"
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/nova`}
              color="green"
              icon={<QueueIcon />}
            />
          }
        />
      </Stack>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <TextField
            variant="standard"
            placeholder="Pesquisar..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
              sx: {
                borderRadius: 2,
                border: "1px solid #4E5526",
                paddingLeft: 2,
                height: "36px",
                "&:hover": { borderColor: "#3B4220" },
                "&.Mui-focused": { borderColor: "#4E5526" },
                color: "#4E5526",
                "&::placeholder": { color: "red", opacity: 1 },
                width: 350,
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    sx={{
                      backgroundColor: "#4E5526",
                      borderRadius: 2,
                      minWidth: "36px",
                      height: "36px",
                      padding: 0,
                      "&:hover": { backgroundColor: "#3B4220" },
                    }}
                    onClick={() => handleSearch(inputValue)}
                  >
                    <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          {selectedLicencasIds.length > 0 && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitasLicencas}
              sx={{
                borderRadius: 2,
                paddingX: 2,
                backgroundColor: "#C84E4D",
                textTransform: "none",
              }}
            >
              Excluir {selectedLicencasIds.length} licença(s)
            </Button>
          )}
          <BotoesExportarDados
            titulo={`Licenças do contrato ${breadCrumbsInfo?.descricao}`}
            dados_planilha={licencas}
            colunas_planilha={[
              "nome_licenca.nome",
              "numero",
              "data_emissao",
              "data_expiracao",
              "dataVencimento",
              "createdAt",
            ]}
            nome_arquivo_planilha={`licencas_contrato_${breadCrumbsInfo?.descricao}_`}
          />
        </Box>
      </Div>

      <Card
        sx={{
          p: 2,
          width: "100%",
          minHeight: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item lg={1}>
            <Checkbox
              sx={{
                color: "#696969",
                "&.Mui-checked": {
                  color: "#95A64A",
                },
              }}
              onChange={handleSelecionarTodasLicencas}
            />
          </Grid>
          <Grid item lg={2}>
            <Box>
              <Typography fontSize={24} fontWeight={600}>
                Licença
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={1}>
            <Box>
              <Typography fontSize={24} fontWeight={600}>
                Número
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontSize={24} fontWeight={600}>
                Data de emissão
              </Typography>
              <OrdenacaoLista
                column={"data_emissao"}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
              />
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontSize={24} fontWeight={600}>
                Data de expiração
              </Typography>
              <OrdenacaoLista
                column={"data_expiracao"}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
              />
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontSize={24} fontWeight={600}>
                Data de vencimento
              </Typography>
              <OrdenacaoLista
                column={"data_vencimento"}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
              />
            </Box>
          </Grid>
          <Grid item lg={1}>
            <Box>
              <Typography fontSize={24} fontWeight={600}>
                Ações
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>

      {licencas && licencas.length > 0 ? (
        licencas.map((licenca, key) => (
          <Card
            sx={{
              p: 2,
              width: "100%",
              minHeight: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            key={key}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Grid item lg={1}>
                <Checkbox
                  sx={{
                    color: "#696969",
                    "&.Mui-checked": {
                      color: "#95A64A",
                    },
                  }}
                  key={licenca.id}
                  checked={licenca.isSelected}
                  onChange={() => handleCheckboxChange(licenca.id)}
                />
              </Grid>
              <Grid item lg={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={18}>
                    {licenca?.nome_licenca?.nome ?? "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={1}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={18}>
                    {licenca?.numero ?? "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={18} textAlign={"left"}>
                    {licenca?.data_emissao
                      ? moment.utc(licenca?.data_emissao).format("DD/MM/YYYY")
                      : "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={18} textAlign={"left"}>
                    {licenca?.data_expiracao
                      ? moment.utc(licenca?.data_expiracao).format("DD/MM/YYYY")
                      : "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={18} textAlign={"left"}>
                    {licenca?.dataVencimento
                      ? moment.utc(licenca?.dataVencimento).format("DD/MM/YYYY")
                      : "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={1}>
                <Stack direction="row" gap={1}>
                  <Tooltip title="Editar Licença">
                    <EditIcon
                      cursor="pointer"
                      color="info"
                      disabled={!permission}
                      onClick={() => {
                        navigate(
                          `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/${licenca.id}/editar`
                        );
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Excluir Licença">
                    <DeleteIcon
                      cursor="pointer"
                      color="error"
                      onClick={() => {
                        navigate(
                          `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/${licenca.id}/deletar`
                        );
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        ))
      ) : (
        <Card style={{ padding: 40 }}>Nenhuma licença encontrada</Card>
      )}
    </JumboContentLayout>
  );
};

export default LicencasContratoList;
