import React, { useContext } from "react";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TopicIcon from "@mui/icons-material/Topic";
import PaidIcon from "@mui/icons-material/Paid";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BuildIcon from "@mui/icons-material/Build";
import MoneyIcon from "@mui/icons-material/Money";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DomainIcon from "@mui/icons-material/Domain";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import BusinessIcon from "@mui/icons-material/Business";
import TaskIcon from '@mui/icons-material/Task';
import { AdminPanelSettings } from "@mui/icons-material";
import InboxIcon from '@mui/icons-material/Inbox';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import WindowIcon from '@mui/icons-material/Window';

const menus = [
  // {
  //   label: "Home",
  //   type: "section",
  //   children: [

  //   ],
  // },
  {
    uri: "/painel",
    label: "Painel de controle",
    type: "nav-item",
    icon: <WindowIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/app/caixa-entrada",
    label: "Caixa de Entrada",
    type: "nav-item",
    icon: <InboxIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/app/listar-colaboradores",
    label: "Colaboradores",
    type: "nav-item",
    icon: <PeopleIcon sx={{ fontSize: 20 }} />,
  },
  // {
  //   uri: "/app/condicionantes",
  //   label: "Condicionantes",
  //   type: "nav-item",
  //   icon: <TaskIcon sx={{ fontSize: 20 }} />,
  // },
  {
    uri: "/app/listar-clientes",
    label: "Clientes",
    type: "nav-item",
    icon: <AttachMoneyIcon sx={{ fontSize: 20 }} />,
  },
  // {
  //   uri: "/app/licencas",
  //   label: "Licenças",
  //   type: "nav-item",
  //   icon: <WorkspacePremiumIcon sx={{ fontSize: 20 }} />,
  // },
  // {
  //   uri: "/app/unidades",
  //   label: "Unidades",
  //   type: "nav-item",
  //   icon: <BusinessIcon sx={{ fontSize: 20 }} />,
  // },
  {
    uri: "/app/listar-contratos",
    label: "Contratos",
    type: "nav-item",
    icon: <PaidIcon sx={{ fontSize: 20 }} />,
  },
  // {
  //   uri: "/app/listar-servicos",
  //   label: "Serviços",
  //   type: "nav-item",
  //   icon: <AssignmentIcon sx={{ fontSize: 20 }} />,
  // },
  {
    uri: "/app/financeiro",
    label: "Financeiro Contratos",
    type: "nav-item",
    icon: <CurrencyExchangeIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/app/dashboard",
    label: "Gestão de Serviços",
    type: "nav-item",
    icon: <TopicIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/app",
    label: "Administrativo",
    type: "collapsible",
    icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />,
    children: [
      // {
      //   uri: "/app/admin/licencas",
      //   label: "Licenças",
      //   type: "nav-item",
      // },
      // {
      //   uri: "/app/listar-alvara-licenca",
      //   label: "Alvará e Licenças",
      //   type: "nav-item",
      //   icon: <BuildIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "/app/listar-alvara-licenca-complementar",
      //   label: "Alvará e Licença Complementar",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "/app/categorias-conta",
      //   label: "Categorias de Conta",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "/app/listar-centros-de-custos",
      //   label: "Centros de Custos",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "/app/listar-documentos-licencas",
      //   label: "Documentos Licenças",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
      // {
      //   uri: "/app/fornecedores",
      //   label: "Fornecedores",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/app/listar-grupo-restricao",
        label: "Grupos de Restrição",
        type: "nav-item",
        icon: <PaidIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/orgaos",
        label: "Órgãos",
        type: "nav-item",
        icon: <PaidIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-processos-admin",
        label: "Processos Administrativos",
        type: "nav-item",
        icon: <AdminPanelSettings sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-segmentos",
        label: "Segmentos",
        type: "nav-item",
        icon: <TopicIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-setores",
        label: "Setores",
        type: "nav-item",
        icon: <DomainIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-status-orcamento",
        label: "Status de Orçamento",
        type: "nav-item",
        icon: <MoneyIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/app/listar-status-servicos",
      //   label: "Status de Serviços",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/app/listar-tarefas",
        label: "Tarefas",
        type: "nav-item",
        icon: <PaidIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-tipo-contrato",
        label: "Tipo Contrato",
        group: "Administrativo",
        type: "nav-item",
        icon: <TopicIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-tipo-orcamento",
        label: "Tipo Orçamento",
        type: "nav-item",
        icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/listar-tipos-servico",
        label: "Tipos de Serviços",
        type: "nav-item",
        icon: <BuildIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //   uri: "/app/listar-tipo-unidade",
      //   label: "Tipo de Unidade",
      //   type: "nav-item",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      // },
    ],
  },
  {
    uri: "/app",
    label: "Financeiro",
    type: "collapsible",
    icon: <RequestQuoteIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/app/plano-de-contas",
        label: "Plano de contas",
        group: "Financeiro",
        type: "nav-item",
        icon: <BuildIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/controle-de-caixa",
        label: "Controle de caixa",
        group: "Financeiro",
        type: "nav-item",
        icon: <BuildIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/consolidacao-fc",
        label: "Consolidação FC",
        group: "Financeiro",
        type: "nav-item",
        icon: <BuildIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/contas-a-pagar",
        label: "Contas a pagar",
        group: "Financeiro",
        type: "nav-item",
        icon: <BuildIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
