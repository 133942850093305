import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import BusinessIcon from "@mui/icons-material/Business";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HistoryIcon from "@mui/icons-material/History";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";
import CustomChipLicencasUnidade from "app/components/CustomChipLicencasUnidade/CustomChipLicencasUnidade";
import ModalInformacoesLicenca from "app/components/ModalInformacoesLicenca/ModalInformacoesLicenca";
import ModalHistoricoLicenca from "app/components/ModalHistoricoLicenca/ModalHistoricoLicenca";
import { ModalEditarIdentificacaoObservacaoLicenca } from "app/components/ModalEditarIdentificaoObservacaoLicenca/ModalEditarIdentificacaoObservacaoLicenca";
import * as yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { v4 } from "uuid";
import ArquivoWrapper from "app/components/ArquivoWrapper/ArquivoWrapper";
import ListEmptyWrapper from "app/components/ListEmptyWrapper/ListEmptyWrapper";
import CondicionanteWrapper from "app/components/CondicionanteWrapper/CondicionanteWrapper";
import DocumentoLicencaWrapper from "app/components/DocumentoLicencaWrapper/DocumentoLicencaWrapper";
import ModalEdicaoDocumentosLicenca from "app/components/ModalEdicaoDocumentosLicenca/ModalEdicaoDocumentosLicenca";

const usersLicencaValidationSchema = yup.object().shape({
  idsUsuarios: yup.array().of(yup.number()),
});

export const LicencasDetails = () => {
  const valuesUsersLicenca = {
    idsUsuarios: [],
  };
  const { id } = useParams();
  const userId = localStorage.getItem("id");
  const { theme } = useJumboTheme();
  const navigate = useNavigate();
  const [licenca, setLicenca] = useState([]);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [editandoUsuarios, setEditandoUsuarios] = useState(false);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [submenuAberto, setSubmenuAberto] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [isModalInformacoesAbertos, setIsModalInformacoesAbertos] =
    useState(false);
  const [isModalHistoricoAberto, setIsModalHistoricoAberto] = useState(false);
  const [
    isModalIdentificaoObservacaoAberto,
    setIsModalIdentificaoObservacaoAberto,
  ] = useState(false);
  const [
    acaoModalEdicaoIdentificacaoObservacao,
    setAcaoModalEdicaoIdentificacaoObservacao,
  ] = useState("");
  const [
    isModalAdicionarDocumentosAberto,
    setIsModalAdicionarDocumentosAberto,
  ] = useState(false);
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlertDeletarArquivo = (idDocumento) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o arquivo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteDocumento(idDocumento);
      }
    });
  };

  const handleDeleteDocumento = async (documentoId) => {
    try {
      await ApiService.post(`/unidadealvaralicenca/documento/${documentoId}`, {
        userId: Number(userId),
        licencaId: Number(id),
      }).then((response) => {
        if (response.status === 201) {
          toast("success", "Documento excluído", "success");
          getLicenca();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const handleDeleteDocumentoComplementar = async (documentoId) => {
    try {
      await ApiService.delete(`/documentocomplementar/${documentoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Documento excluído!");
            getLicenca();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const handleClickSubmenu = () => {
    setSubmenuAberto(!submenuAberto);
  };

  const handleCloseSubmenu = () => {
    setSubmenuAberto(false);
  };

  const handleOpenModalInformacoes = () => {
    setIsModalInformacoesAbertos(!isModalInformacoesAbertos);
  };

  const handleOpenModalHistorico = () => {
    setIsModalHistoricoAberto(!isModalHistoricoAberto);
  };

  const handleOpenModalEdicaoObservacaoIdentificacao = () => {
    setIsModalIdentificaoObservacaoAberto(!isModalIdentificaoObservacaoAberto);
  };

  const handleEditarUsuarios = () => {
    setEditandoUsuarios(true);
  };

  const handleToggleUsuarioSelecionado = (usuarioId) => {
    if (usuariosSelecionados.includes(usuarioId)) {
      setUsuariosSelecionados(
        usuariosSelecionados.filter((id) => id !== usuarioId)
      );
    } else {
      setUsuariosSelecionados([...usuariosSelecionados, usuarioId]);
    }
  };

  const handleSalvarUsuarios = async () => {
    try {
      await ApiService.put(`unidadealvaralicenca/usuarios/${id}`, {
        usuariosIds: usuariosSelecionados,
        userId: Number(userId),
        licencaId: Number(id),
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Responsáveis atualizados!");
          getLicenca();
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
    setEditandoUsuarios(false);
  };

  const handleCloseModalAdicionarDocumentos = () => {
    setIsModalAdicionarDocumentosAberto(!isModalAdicionarDocumentosAberto);
  };

  const modalAlert = (id, acao) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "excluir" : "arquivar"
      }?`,
      text: `Não será póssível reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        return acao === "deletar" ? deleteLicenca(id) : arquivarLicenca(id);
      }
    });
  };

  const arquivarLicenca = async (id) => {
    try {
      await ApiService.put(`/unidadealvaralicenca/arquivar/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Licença arquivada!");
            navigate("/app/licencas");
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const deleteLicenca = async (id) => {
    try {
      await ApiService.delete(`/unidadealvaralicenca/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Licença excluída!");
            navigate("/app/licencas");
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getUsuarios = useCallback(async () => {
    try {
      await ApiService.get("/users/all").then((response) => {
        setUsuarios(response.data);
      });
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  const getLicenca = async () => {
    try {
      await ApiService.get(`/unidadealvaralicenca/${id}`).then(
        async (response) => {
          if (response.status === 200) {
            console.log(response.data);
            setLicenca({
              ...response.data,
              alvaraLicencas: response.data.alvaraLicenca,
              alvaraLicencasComplementar:
                response.data.alvaraLicencaComplementar,
            });
            const idsUsuariosLicenca = response.data.users.map(
              (user) => user.id
            );
            setUsuariosSelecionados(idsUsuariosLicenca);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await getLicenca();
      await getUsuarios();
    };
    await fetchData();
    setIsDadosCarregados(true);
  }, []);

  return (
    <>
      {isDadosCarregados && (
        <JumboContentLayout
          header={
            <HeaderBreadcrumbs
              title={"Licenças"}
              subtitle={`Detalhes da Licença > ${
                isDadosCarregados ? licenca.unidade?.nomeUnidade : ""
              } > ${licenca.identificacao}`}
              titleUrl={"/app/licencas"}
            />
          }
          layoutOptions={layoutOptions}
        >
          {lg && (
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
            ></Stack>
          )}
          <Grid container width={"100%"} gap={1} direction={"row"}>
            <Grid item xs={8}>
              <Paper elevation={1} sx={{ p: 3, background: "white" }}>
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Typography style={{ fontWeight: 600, fontSize: "20px" }}>
                      {licenca.alvaraLicenca?.descricao}
                    </Typography>
                    <Typography
                      my={1}
                      style={{ fontSize: "16px", color: "#A5A5A5" }}
                    >
                      {licenca.identificacao}
                    </Typography>
                    <Typography
                      my={1}
                      gap={1}
                      style={{
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <BusinessIcon color="info" />{" "}
                      <Link
                        to={`/app/unidade/${licenca.unidade.id}`}
                        style={{ color: "#2EB5C9", textDecoration: "none" }}
                      >
                        {licenca.unidade.nomeUnidade} - CNPJ:{" "}
                        {licenca.unidade.cnpj}
                      </Link>
                    </Typography>

                    <Box my={1}>
                      <Box display={"flex"} gap={1}>
                        <Typography sx={{ fontWeight: 600 }}>
                          Nº da licença:{" "}
                          {licenca.num_licenca ? licenca.num_licenca : ""}
                        </Typography>
                      </Box>
                      <Box display={"flex"} gap={1}>
                        <Typography sx={{ fontWeight: 600 }}>
                          Emissão:{" "}
                          {licenca.emissao
                            ? moment
                                .parseZone(licenca.emissao)
                                .format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </Box>
                      <Box display={"flex"} gap={1}>
                        <Typography sx={{ fontWeight: 600 }}>
                          Observação:{" "}
                          {licenca.observacao ? licenca.observacao : ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Stack direction="row" gap={1}>
                      <Typography
                        fontSize={"18px"}
                        style={{
                          fontWeight: "500",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <CustomChipLicencasUnidade
                          situacao={licenca.status_licenca}
                        />
                      </Typography>
                      <Typography
                        fontSize={"18px"}
                        style={{
                          fontWeight: "500",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Chip
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <HourglassBottomIcon />
                              Vencimento:{" "}
                              {licenca.vencimento
                                ? moment
                                    .parseZone(licenca.vencimento)
                                    .format("DD/MM/YYYY")
                                : "N/I"}
                            </Box>
                          }
                          my={2}
                        />
                      </Typography>
                    </Stack>
                    <Divider />
                  </Box>
                  <Box display={"flex"} gap={2}>
                    <Button
                      variant="contained"
                      color="info"
                      style={{ height: 30 }}
                      onClick={handleOpenModalInformacoes}
                    >
                      <AddIcon /> Informações
                    </Button>
                    <ModalInformacoesLicenca
                      aberto={isModalInformacoesAbertos}
                      handleClose={handleOpenModalInformacoes}
                      licencaId={id}
                      userId={userId}
                      atualizarLicenca={getLicenca}
                    />
                    <Button
                      variant="outlined"
                      color="info"
                      style={{ height: 30 }}
                      onClick={() => {
                        setAcaoModalEdicaoIdentificacaoObservacao("observacao");
                        handleOpenModalEdicaoObservacaoIdentificacao();
                      }}
                    >
                      <QuestionAnswerIcon />
                    </Button>
                    <Button
                      variant="outlined"
                      color="info"
                      style={{ height: 30 }}
                      onClick={handleOpenModalHistorico}
                    >
                      <HistoryIcon />
                    </Button>
                    <ModalHistoricoLicenca
                      modalAberto={isModalHistoricoAberto}
                      handleClose={handleOpenModalHistorico}
                      licencaId={id}
                    />
                    <Button
                      variant="outlined"
                      color="info"
                      style={{ height: 30 }}
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        handleClickSubmenu();
                      }}
                    >
                      <ListIcon />
                    </Button>
                    <Popover
                      open={submenuAberto}
                      anchorEl={anchorEl}
                      onClose={handleCloseSubmenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List>
                        <ListItem
                          onClick={() => {
                            setAcaoModalEdicaoIdentificacaoObservacao(
                              "identificacao"
                            );
                            handleOpenModalEdicaoObservacaoIdentificacao();
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary="Editar identificação" />
                        </ListItem>
                        <Divider />
                        <ListItem
                          onClick={() => {
                            modalAlert(id, "arquivar");
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <ArchiveIcon />
                          </ListItemIcon>
                          <ListItemText primary="Arquivar" />
                        </ListItem>
                        <Divider />
                        <ListItem
                          onClick={() => {
                            modalAlert(id, "deletar");
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#f9f9f9",
                            },
                          }}
                        >
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText primary="Excluir" />
                        </ListItem>
                      </List>
                    </Popover>
                    <ModalEditarIdentificacaoObservacaoLicenca
                      acao={acaoModalEdicaoIdentificacaoObservacao}
                      handleClose={handleOpenModalEdicaoObservacaoIdentificacao}
                      modalAberto={isModalIdentificaoObservacaoAberto}
                      licencaId={id}
                      userId={userId}
                      atualizarLicenca={getLicenca}
                    />
                  </Box>
                </Box>

                {licenca.documento_licencas?.length > 0 && (
                  <Stack
                    direction="column"
                    p={1}
                    height={"100px"}
                    style={{ overflowY: "scroll" }}
                  >
                    {licenca.documento_licencas?.map((documento) => (
                      <ArquivoWrapper
                        arquivo={documento}
                        tipoLicenca={licenca.alvaraLicenca?.descricao}
                        onRemoverArquivo={modalAlertDeletarArquivo}
                        key={v4()}
                      />
                    ))}
                  </Stack>
                )}
              </Paper>
            </Grid>
            <Grid item xs={3} direction={"column"}>
              <Paper elevation={1} sx={{ p: 3, background: "#E5EBF2" }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography sx={{ fontWeight: 600 }}>Responsáveis</Typography>
                  {editandoUsuarios ? (
                    <>
                      <CheckIcon
                        color="success"
                        style={{ cursor: "pointer" }}
                        onClick={handleSalvarUsuarios}
                      />
                      <CloseIcon
                        color="error"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditandoUsuarios(false);
                        }}
                      />
                    </>
                  ) : (
                    <EditIcon
                      fontSize="medium"
                      color={"info"}
                      sx={{ cursor: "pointer" }}
                      onClick={handleEditarUsuarios}
                    />
                  )}
                </Stack>
                <Divider my={1} />
                {editandoUsuarios && (
                  <Stack
                    my={1}
                    direction={"column"}
                    sx={{ height: "120px", overflowY: "scroll" }}
                  >
                    {usuarios.length > 0 &&
                      usuarios.map((usuario) => (
                        <Stack
                          direction="row"
                          gap={2}
                          my={1}
                          alignItems="center"
                          key={usuario.id}
                        >
                          <Checkbox
                            checked={usuariosSelecionados.includes(usuario.id)}
                            onChange={() =>
                              handleToggleUsuarioSelecionado(usuario.id)
                            }
                          />
                          <Avatar
                            sx={{ width: 30, height: 30 }}
                            src={
                              usuario.avatarUrl
                                ? `${process.env.REACT_APP_API_KEY}/public/uploads/${usuario.avatarUrl}`
                                : ""
                            }
                          >
                            {String(usuario.name).charAt(0).toUpperCase()}
                          </Avatar>
                          <Typography>{usuario.name}</Typography>
                        </Stack>
                      ))}
                  </Stack>
                )}
                {licenca.users.length > 0 && (
                  <Stack
                    my={1}
                    direction={"column"}
                    sx={{ height: "90px", overflowY: "scroll" }}
                  >
                    {licenca.users.length > 0 &&
                      licenca.users.map((usuario) => (
                        <Stack
                          key={v4()}
                          direction="row"
                          gap={2}
                          my={1}
                          alignItems={"center"}
                        >
                          <Avatar
                            sx={{ width: 30, height: 30 }}
                            src={
                              usuario.avatarUrl
                                ? `${process.env.REACT_APP_API_KEY}/public/uploads/${usuario.avatarUrl}`
                                : ""
                            }
                          >
                            {String(usuario.name).charAt(0).toUpperCase()}
                          </Avatar>
                          <Typography>{usuario.name}</Typography>
                        </Stack>
                      ))}
                  </Stack>
                )}
              </Paper>
              <Paper elevation={1} sx={{ p: 3, background: "#E5EBF2", my: 2 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Definir lembrete de vencimento
                  </Typography>
                  <CircleNotificationsIcon fontSize="medium" color={"info"} />
                </Stack>
                <Divider my={1} />
                <List>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText>Via E-mail</ListItemText>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={8} my={2}>
              <Paper elevation={1} sx={{ p: 3, background: "white" }}>
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Stack
                    direction="column"
                    width={"100%"}
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Typography
                          my={1}
                          sx={{ fontWeight: 600, fontSize: "18px" }}
                        >
                          Condicionantes
                        </Typography>
                      </Box>
                      {licenca.condicionantes?.length > 0 && (
                        <Button
                          onClick={() => {
                            navigate(`/app/nova-condicionante-licenca/${id}`);
                          }}
                          sx={{
                            my: 1,
                            "&:hover": {
                              backgroundColor: "#2CB3C7",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                        >
                          Adicionar nova condicionante
                        </Button>
                      )}
                    </Stack>
                    {licenca.condicionantes?.length > 0 ? (
                      <Box sx={{ width: "100%" }}>
                        {licenca.condicionantes?.map((condicionante) => (
                          <CondicionanteWrapper
                            dados={condicionante}
                            // accordionOpen={accordionId === condicionante.id}
                            atualizarUnidade={getLicenca}
                            key={v4()}
                            // ref={
                            //   accordionId === condicionante.id
                            //     ? accordionRef
                            //     : null
                            // }
                            tipoCondicionante={"condicionanteLicenca"}
                            licencaId={id}
                          />
                        ))}
                      </Box>
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        <ListEmptyWrapper
                          message={"Nenhuma condicionante adicionada."}
                        />
                        <Button
                          onClick={() => {
                            navigate(`/app/nova-condicionante-licenca/${id}`);
                          }}
                          sx={{
                            display: "block",
                            margin: "auto",
                            my: 1,
                            "&:hover": {
                              backgroundColor: "#2CB3C7",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                        >
                          Adicionar nova condicionante
                        </Button>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={8} my={2}>
              {licenca.documentos_complementares.length > 0 && (
                <Box
                  my={1}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    color="info"
                    onClick={handleCloseModalAdicionarDocumentos}
                    sx={{ maxWidth: 400 }}
                  >
                    <AddIcon /> Documentos
                  </Button>
                </Box>
              )}
              <Paper elevation={1} sx={{ p: 3, background: "white" }}>
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Stack
                    direction="column"
                    width={"100%"}
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Typography style={{ fontWeight: 600, fontSize: "20px" }}>
                      Documentos complementares
                    </Typography>
                    {licenca.documentos_complementares?.length > 0 ? (
                      <>
                        {licenca.documentos_complementares.map((documento) => (
                          <>
                            <DocumentoLicencaWrapper
                              atualizarUnidade={getLicenca}
                              documento={documento}
                              handleDeleteDocumentoUnidade={
                                handleDeleteDocumentoComplementar
                              }
                              userId={userId}
                            />
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <ListEmptyWrapper
                          message={"Nenhum documento adicionado."}
                        />
                        <Button
                          onClick={handleCloseModalAdicionarDocumentos}
                          sx={{
                            display: "block",
                            margin: "auto",
                            my: 1,
                            "&:hover": {
                              backgroundColor: "#2CB3C7",
                              color: "white",
                            },
                          }}
                          variant="outlined"
                          color="info"
                        >
                          Adicionar novo documento
                        </Button>
                      </>
                    )}
                  </Stack>
                </Box>
                <ModalEdicaoDocumentosLicenca
                  aberto={isModalAdicionarDocumentosAberto}
                  handleClose={handleCloseModalAdicionarDocumentos}
                  licencaId={id}
                  atualizarUnidade={getLicenca}
                  nomeUnidade={licenca.identificacao}
                  userId={userId}
                />
              </Paper>
            </Grid>
          </Grid>
        </JumboContentLayout>
      )}
    </>
  );
};
