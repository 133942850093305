import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/pt-br";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  Tooltip,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  OutlinedInput,
  Pagination,
  PaginationItem,
  InputAdornment,
  IconButton,
  Skeleton,
  Stack,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Chart from "react-apexcharts";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import { useMediaQuery } from "beautiful-react-hooks";
import moment, { now } from "moment";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiService from "app/services/config";
import { DateRangePicker } from "react-dates";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useLocation, useNavigate } from "react-router-dom";
import ListaServicosDashboard from "app/components/ListaServicosDashboard/ListaServicosDashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import gerarResumoCompletoPDF from "app/utils/pdfs/gerarResumoCompletoPDF";
import gerarResumoSimplesPDF from "app/utils/pdfs/gerarResumoSimplesPDF";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import { CalendarMonth } from "@mui/icons-material";
import AbaDashboard from "app/components/AbaDashboard";

const coresPorAtividade = {
  orgao: "#5FDBB3",
  cliente: "#F5AB3E",
  ambiental: "#EC5F6F",
};
const CORES_DONUT = ["#98A94A", "#E5CB74", "#FF7878"];

const Dashboard = () => {
  const roleId = Number(localStorage.getItem("role_id"));
  const userId = Number(localStorage.getItem("id"));
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [dadosAcoes, setDadosAcoes] = useState([]);
  const [dadosPlanilha, setDadosPlanilha] = useState([]);
  const [dadosPlanilhaFormatados, setDadosPlanilhaFormatados] = useState([]);
  const [dadosGraficoDeBarras, setDadosGraficoDeBarras] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [setores, setSetores] = useState([]);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [statusServico, setStatusServico] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [atividadesSelecionadas, setAtividadesSelecionadas] = useState([]);
  const [isModalCadastroServicoAberto, setIsModalCadastroServicoAberto] =
    useState(false);
  const atividadesGrafico = {
    orgao: "Órgão",
    protocolado: "Protocolado",
    cliente: "Cliente",
    ambiental: "Ambiental",
  };
  const [scrollToServicoId, setScrollToServicoId] = useState(null);
  const servicosIdsRef = useRef({});
  const location = useLocation();
  const [abaSelecionada, setAbaSelecionada] = useState("em_andamento");
  const [isLoadingPDFCompleto, setIsLoadingPDFCompleto] = useState(false);
  const [isLoadingPDFResumido, setIsLoadingPDFResumido] = useState(false);

  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    colaboradoresServico: [],
    depende: [],
    setores: [],
    situacao: null,
    data_inicio: null,
    data_final: null,
    periodo: "",
    status: "em_andamento",
  });
  const [isDadosCarregando, setIsDadosCarregando] = useState(false);
  const [imoveisDosContratos, setImoveisDosContratos] = useState([]);
  const [openModalFiltros, setOpenModalFiltros] = useState(false);

  const handleOpenModalFiltros = () => {
    setOpenModalFiltros(true);
  };

  const handleCloseModalFiltros = () => {
    setOpenModalFiltros(false);
  };

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Gestão de Serviços", "read")) {
    navigate("/app");
  }

  const Swal = useSwalWrapper();
  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const idColaborador = localStorage.getItem("id");
  const nomeColaborador = localStorage.getItem("username");

  const subtitleGraficos = () => {
    let texto = "Depende de: ";
    if (
      filtrosPesquisa.depende &&
      filtrosPesquisa.depende.length > 0 &&
      filtrosPesquisa.depende.length < 4
    ) {
      const atividades = filtrosPesquisa.depende.map((atividade) => {
        switch (atividade) {
          case "orgao":
            return "Órgão";
          case "protocolado":
            return "Protocolado";
          case "cliente":
            return "Cliente";
          case "ambiental":
            return "Ambiental";
          default:
            return "";
        }
      });
      texto += atividades.join(", ");
    } else if (
      filtrosPesquisa.depende.length === 4 &&
      filtrosPesquisa.depende
    ) {
      texto += "Geral";
    } else {
      texto += "Geral";
    }

    if (
      filtrosPesquisa.periodo !== "selecionar" &&
      filtrosPesquisa.data_final &&
      filtrosPesquisa.data_final
    ) {
      const FORMATAR_PERIODOS = {
        data_conclusao: "Data de Conclusão",
        prazo: "Prazo",
        createdAt: "Data de Registro",
      };
      texto += ` | ${FORMATAR_PERIODOS[filtrosPesquisa.periodo]} - De ${moment(
        filtrosPesquisa.data_inicio
      ).format("DD/MM/YYYY")} à ${moment(filtrosPesquisa.data_final).format(
        "DD/MM/YYYY"
      )}`;
    } else {
      texto += " | Período: Geral";
    }

    return texto;
  };

  const handleIniciarServico = async (id, status_servico) => {
    try {
      setScrollToServicoId(id);
      if (status_servico === 1) {
        toast("info", "O serviço já foi iniciado!");
        return;
      }

      await ApiService.put(`/servicos/iniciar/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Serviço iniciado!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleConcluirServico = async (id, status_servico, contratoId) => {
    try {
      setScrollToServicoId(id);
      if (status_servico === 12 || status_servico === 13) {
        toast("info", "O serviço já foi concluído!");
        return;
      }
      await ApiService.put(`/servicos/concluir/${id}/${contratoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast(null, "Ação concluída", "success");
            getDadosPlanilha();
            getDadosGraficoDeBarras();
          }
        }
      );
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleAumentarPrioridadeProcesso = async (processoId) => {
    try {
      // setScrollToServicoId(id);
      await ApiService.put(
        `/servicos/aumentar/prioridade/${processoId}/${abaSelecionada}`
      ).then((response) => {
        if (response.status === 200) {
          toast(null, "Prioridade do processo aumentada!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleDiminuirPrioridadeProcesso = async (processoId) => {
    try {
      // setScrollToServicoId(id);
      await ApiService.put(
        `/servicos/diminuir/prioridade/${processoId}/${abaSelecionada}`
      ).then((response) => {
        if (response.status === 200) {
          toast(null, "Prioridade do processo diminuida!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleParalisarServico = async (id, status_servico) => {
    try {
      setScrollToServicoId(id);
      if (status_servico === 6) {
        toast("info", "O serviço já foi paralisado!");
        return;
      }
      await ApiService.put(`/servicos/paralisar/${id}`).then((response) => {
        if (response.status === 200) {
          toast(null, "Serviço paralisado!", "success");
          getDadosPlanilha();
          getDadosGraficoDeBarras();
        }
      });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro", "error");
    }
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFiltrosPesquisa((prevFiltros) => ({
        ...prevFiltros,
        data_inicio: moment(startDate._d).format("YYYY-MM-DD"),
        data_final: moment(endDate._d).format("YYYY-MM-DD"),
      }));
    }
  };

  const handleLegendClick = (chartContext, seriesIndex, config) => {
    setTimeout(() => {
      let filtroDepende = config.config.labels[seriesIndex];
      let atividadeSelecionada = null;

      switch (filtroDepende) {
        case "Órgão":
          atividadeSelecionada = "orgao";
          break;
        case "Protocolado":
          atividadeSelecionada = "protocolado";
          break;
        case "Cliente":
          atividadeSelecionada = "cliente";
          break;
        case "Ambiental":
          atividadeSelecionada = "ambiental";
          break;
      }

      if (atividadeSelecionada) {
        setFiltrosPesquisa((prevFiltros) => ({
          ...prevFiltros,
          depende: prevFiltros.depende.includes(atividadeSelecionada)
            ? prevFiltros.depende.filter(
                (atividade) => atividade !== atividadeSelecionada
              )
            : [...prevFiltros.depende, atividadeSelecionada],
        }));
        setAtividadesSelecionadas((prevSelecionadas) =>
          prevSelecionadas.includes(atividadeSelecionada)
            ? prevSelecionadas.filter(
                (atividade) => atividade !== atividadeSelecionada
              )
            : [...prevSelecionadas, atividadeSelecionada]
        );
      }
    }, 1000);
  };

  const handleModalCadastroServico = () => {
    setIsModalCadastroServicoAberto(!isModalCadastroServicoAberto);
  };

  const modalAlert = (id, acao, status_tarefa) => {
    Swal.fire({
      title:
        acao === "concluir"
          ? "Tem certeza que deseja concluir a ação?"
          : "Tem certeza que deseja iniciar a ação?",
      text:
        acao === "concluir"
          ? "Você poderá iniciá-la novamente depois"
          : "Você poderá atribuir outra data de início posteriormente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "concluir") {
          handleConcluirServico(id);
        }
        if (acao === "iniciar") {
          handleIniciarServico(id, status_tarefa);
        }
      }
    });
  };

  const getImoveisUnidades = async () => {
    const ids = dadosPlanilhaFormatados.map(
      (processo_contrato) => processo_contrato?.processos[0]?.id
    );

    const url = `contratos/imoveis-e-unidades?contratos=${ids.join(",")}`;
    try {
      ApiService.get(url)
        .then((response) => {
          setImoveisDosContratos(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getTarefas = async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        if (response.status === 200) {
          setTarefas(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSetores = async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        if (response.status === 200) {
          setSetores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusOrcamento = async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        if (response.status === 200) {
          setStatusOrcamento(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusServico = async () => {
    try {
      await ApiService.get("/statusservico").then((response) => {
        if (response.status === 200) {
          const statusFiltradosSemNaoSubmetidos = response?.data?.filter(
            (status) => status?.id !== 15
          );
          setStatusServico(statusFiltradosSemNaoSubmetidos);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const getDadosAcoes = useCallback(async () => {
    try {
      let apiUrl = `/servicos/dashboard/count`;
      let filtros = {};
      if (filtrosPesquisa.depende.length > 0) {
        filtros.depende = filtrosPesquisa.depende;
      }

      if (filtrosPesquisa.colaboradoresServico.length > 0) {
        filtros.colaboradores = filtrosPesquisa.colaboradoresServico;
      }

      if (filtrosPesquisa.setores.length > 0) {
        filtros.setores = filtrosPesquisa.setores;
      }

      if (filtrosPesquisa.situacao) {
        filtros.situacao = filtrosPesquisa.situacao;
      }

      if (filtrosPesquisa.data_inicio) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
      }

      if (filtrosPesquisa.data_final) {
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (
        filtrosPesquisa.periodo !== "selecionar" &&
        filtrosPesquisa.data_inicio !== null &&
        filtrosPesquisa.data_final !== null
      ) {
        filtros.periodo = filtrosPesquisa.periodo;
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortOrder = sortOrder;
      filtros.sortBy = sortBy;
      filtros.searchTerm = searchTerm;
      filtros.roleId = roleId;
      filtros.userId = userId;

      await ApiService.post(apiUrl, filtros).then((response) => {
        if (response.status === 201) {
          setDadosAcoes({
            orgao: response.data[0],
            cliente: response.data[1],
            ambiental: response.data[2],
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  });

  const getDadosPlanilha = useCallback(async () => {
    try {
      let filtros = {};
      let apiUrl = `/processos-contrato/`;

      if (filtrosPesquisa.depende.length > 0) {
        filtros.depende = filtrosPesquisa.depende;
      }

      if (filtrosPesquisa.colaboradoresServico.length > 0) {
        filtros.colaboradores = filtrosPesquisa.colaboradoresServico;
      }

      if (filtrosPesquisa.setores.length > 0) {
        filtros.setores = filtrosPesquisa.setores;
      }

      if (filtrosPesquisa.situacao) {
        filtros.situacao = filtrosPesquisa.situacao;
      }

      if (filtrosPesquisa.data_inicio) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
      }

      if (filtrosPesquisa.data_final) {
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (filtrosPesquisa.status) {
        filtros.status = abaSelecionada;
      }

      if (
        filtrosPesquisa.periodo !== "selecionar" &&
        filtrosPesquisa.data_inicio !== null &&
        filtrosPesquisa.data_final !== null
      ) {
        filtros.periodo = filtrosPesquisa.periodo;
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortOrder = sortOrder;
      filtros.sortBy = sortBy;
      filtros.searchTerm = searchTerm;
      filtros.roleId = roleId;
      filtros.userId = userId;

      await ApiService.post(apiUrl, filtros)
        .then((response) => {
          if (response.status === 201) {
            setDadosPlanilhaFormatados(response.data.processos);
            setDadosPlanilha(response.data.processos);
            setCount(response.data.count);
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    filtrosPesquisa,
    abaSelecionada,
  ]);

  const getDadosGraficoDeBarras = async () => {
    try {
      setIsDadosCarregando(true);
      let apiUrl = `/servicotarefa/tarefas/dashboard/prazos`;

      let filtros = {};

      filtros.depende = filtrosPesquisa.depende;

      if (filtrosPesquisa.colaboradoresServico.length > 0) {
        filtros.colaboradores = filtrosPesquisa.colaboradoresServico;
      }

      if (filtrosPesquisa.setores.length > 0) {
        filtros.setores = filtrosPesquisa.setores;
      }

      if (filtrosPesquisa.situacao) {
        filtros.situacao = filtrosPesquisa.situacao;
      }

      if (filtrosPesquisa.data_inicio) {
        filtros.data_inicio = filtrosPesquisa.data_inicio;
      }

      if (filtrosPesquisa.data_final) {
        filtros.data_final = filtrosPesquisa.data_final;
      }

      if (filtrosPesquisa.status) {
        filtros.status = abaSelecionada;
      }

      if (
        filtrosPesquisa.periodo !== "selecionar" &&
        filtrosPesquisa.data_inicio !== null &&
        filtrosPesquisa.data_final !== null
      ) {
        console.log(filtrosPesquisa.periodo);
        filtros.periodo = filtrosPesquisa.periodo;
        filtros.data_inicio = filtrosPesquisa.data_inicio;
        filtros.data_final = filtrosPesquisa.data_final;
      }

      filtros.page = page;
      filtros.perPage = perPage;
      filtros.sortOrder = sortOrder;
      filtros.sortBy = sortBy;
      filtros.searchTerm = searchTerm;
      filtros.roleId = roleId;
      filtros.userId = userId;

      await ApiService.post(apiUrl, filtros).then((response) => {
        setTimeout(() => {
          if (response.status === 201) {
            const dados = [
              { tipo: "geral", dados: response?.data?.[0]["geral"] },
              { tipo: "ambiental", dados: response?.data?.[0]["ambiental"] },
              { tipo: "orgao", dados: response?.data?.[0]["orgao"] },
              { tipo: "cliente", dados: response?.data?.[0]["cliente"] },
            ];

            const dadosFormatados = dados.reduce((acc, curr) => {
              acc[curr.tipo] = curr.dados;
              return acc;
            }, {});

            setDadosGraficoDeBarras(dadosFormatados);
          }
        }, 1000);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsDadosCarregando(false);
    }
  };

  const getColaboradores = async () => {
    try {
      await ApiService.get("/users/colaboradores").then((response) => {
        if (response.status === 200) {
          setColaboradores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLimparFiltros = () => {
    setSearchTerm("");
    setAtividadesSelecionadas([]);
    setEndDate(null);
    setStartDate(null);
    setFiltrosPesquisa({
      depende: [],
      colaboradoresServico: [],
      data_inicio: null,
      data_final: null,
      periodo: "selecionar",
      prazo: "",
      setores: [],
      situacao: null,
      status: "em_andamento",
    });
    setAbaSelecionada("em_andamento");
  };

  useEffect(() => {
    getStatusOrcamento();
    getStatusServico();
    getColaboradores();
    getSetores();
  }, []);

  useEffect(() => {
    if (dadosPlanilhaFormatados.length > 0) {
      getImoveisUnidades();
    }
  }, [dadosPlanilhaFormatados]);

  useEffect(async () => {
    setIsDadosCarregando(true);
    await Promise.all([
      getTarefas(),
      getDadosPlanilha(),
      getDadosAcoes(),
      getDadosGraficoDeBarras(),
    ]);
    setIsDadosCarregando(false);
  }, [
    page,
    filtrosPesquisa,
    searchTerm,
    sortBy,
    sortOrder,
    page,
    perPage,
    abaSelecionada,
  ]);

  useEffect(() => {
    setCount(dadosPlanilhaFormatados?.length ?? 0);
  }, [dadosPlanilhaFormatados]);

  // useEffect(() => {
  //   if (scrollToServicoId) {
  //     const elemento = document.getElementById(scrollToServicoId);

  //     const rect = elemento.getBoundingClientRect();
  //     const scrollTopAtual =
  //       window.pageYOffset || document.documentElement.scrollTop;
  //     const posicaoYElemento = rect.top + scrollTopAtual;

  //     const alturaJanela = window.innerHeight;
  //     const deslocamento = alturaJanela / 2 - rect.height / 2;

  //     window.scrollTo({
  //       top: posicaoYElemento - deslocamento - 175,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [scrollToServicoId]);

  return (
    <JumboContentLayout
      layoutOptions={{
        sidebar: {
          sx: {
            [theme.breakpoints.up("lg")]: {
              position: "sticky",
              zIndex: 5,
              top: 96,
              minHeight: "auto",
            },
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          },
        },
        wrapper: {
          sx: {
            alignItems: "flex-start",
          },
        },
      }}
    >
      <Stack
        width="100%"
        mb={1}
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #4E5526"}
      >
        <PageHeader
          title={"Dashboard"}
          withBorderBottom={false}
          paddingBottom={0}
        />
        <Stack direction="row" gap={2}>
          <ActionButton
            color="pdf"
            icon={isLoadingPDFCompleto ? <CircularProgress size="20px" style={{ color: "white" }} /> :<PictureAsPdfIcon />}
            title={"Relatório completo"}
            action={() => {
              if (!isLoadingPDFCompleto) {
                gerarResumoCompletoPDF(
                  { ...filtrosPesquisa, searchTerm },
                  roleId,
                  userId,
                  setIsLoadingPDFCompleto
                );
              }
            }}
            width={470}
          />
          <ActionButton
            color="pdf"
            icon={isLoadingPDFResumido ? <CircularProgress size="20px" style={{ color: "white" }} /> : <PictureAsPdfIcon />}
            title={"Relatório resumido"}
            action={() => {
              if (!isLoadingPDFResumido) {
                gerarResumoSimplesPDF(
                  { ...filtrosPesquisa, searchTerm },
                  roleId,
                  userId,
                  setIsLoadingPDFResumido
                );
              }
            }}
            width={470}
            marginTop={0}
          />
        </Stack>
      </Stack>
      {location.pathname === "/" && roleId !== 1 ? (
        <Box>
          <Typography variant={"h1"}>Bem-vindo (a)!</Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2} width={"100%"} my={1}>
            <Grid item xs={5}>
              <Paper
                sx={{
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minHeight: "440px",
                }}
              >
                {dadosAcoes && (
                  <Chart
                    type="donut"
                    series={
                      atividadesSelecionadas &&
                      atividadesSelecionadas.length > 0
                        ? atividadesSelecionadas.map(
                            (atividade) => dadosAcoes[atividade]
                          )
                        : Object.values(dadosAcoes)
                    }
                    width={"150%"}
                    height={"auto"}
                    options={{
                      noData: {
                        align: "center",
                        verticalAlign: "middle",
                        text: "Nenhum dado disponível",
                      },
                      tooltip: {
                        enabled: true,
                      },
                      chart: {
                        events: {
                          legendClick: handleLegendClick,
                        },
                        toolbar: {
                          show: true,
                          offsetX: 0,
                          offsetY: 0,
                          tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset:
                              true |
                              '<img src="/static/icons/reset.png" width="20">',
                            customIcons: [],
                          },
                          export: {
                            csv: {
                              filename: "gestao-ambiental_controle-acoes-csv",
                              columnDelimiter: ",",
                              headerCategory: "category",
                              headerValue: "value",
                              dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString();
                              },
                            },
                            svg: {
                              filename: "gestao-ambiental_controle-acoes-svg",
                            },
                            png: {
                              filename: "gestao-ambiental_controle-acoes-png",
                            },
                          },
                          autoSelected: "zoom",
                        },
                      },
                      dataLabels: {
                        enabled: true,
                      },
                      fill: {
                        colors:
                          atividadesSelecionadas.length > 0
                            ? atividadesSelecionadas.map(
                                (atividade) => coresPorAtividade[atividade]
                              )
                            : CORES_DONUT,
                      },
                      legend: {
                        show: true,
                        position: "bottom",
                        labels: {
                          colors: theme.palette.text.primary,
                        },
                        onItemClick: {
                          toggleDataSeries: true,
                        },
                        markers: {
                          fillColors:
                            atividadesSelecionadas.length > 0
                              ? atividadesSelecionadas.map((atividade) => [
                                  coresPorAtividade[atividade],
                                ])
                              : Object.keys(dadosAcoes).map(
                                  (atividade) => coresPorAtividade[atividade]
                                ),
                        },
                      },
                      labels:
                        atividadesSelecionadas &&
                        atividadesSelecionadas.length > 0
                          ? atividadesSelecionadas.map(
                              (atividade) => atividadesGrafico[atividade]
                            )
                          : ["Órgão", "Cliente", "Ambiental"],
                      title: {
                        align: "center",
                        margin: 10,
                        style: {
                          fontSize: "18px",
                          fontWeight: "bold",
                        },
                        text: "Controle de Atividades",
                      },
                      subtitle: {
                        text: subtitleGraficos(),
                        align: "center",
                        margin: 10,
                        style: {
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "gray",
                        },
                      },
                      plotOptions: {
                        pie: {
                          expandOnClick: true,
                          donut: {
                            labels: {
                              value: {
                                color: "#FFFFFF",
                              },
                              total: {
                                showAlways: true,
                                show: true,
                                label: "Total de Ações",
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={7}>
              <Paper
                sx={{
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minHeight: "440px",
                }}
              >
                {Object.keys(dadosGraficoDeBarras).length > 0 &&
                  !isDadosCarregando && (
                    <Chart
                      type="bar"
                      width={"200%"}
                      height={"auto"}
                      series={
                        filtrosPesquisa.depende.length === 0 ||
                        (filtrosPesquisa.depende.length === 4 &&
                          !isDadosCarregando)
                          ? [
                              {
                                name: "Concluídas no prazo",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralConcluidasNoPrazo"
                                  ],
                                ],
                              },
                              {
                                name: "Concluídas com atraso",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralConcluidasComAtraso"
                                  ],
                                ],
                              },
                              {
                                name: "Atrasadas",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralAtrasadas"
                                  ],
                                ],
                              },
                              {
                                name: "Atrasadas e Não Iniciadas",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralAtrasadasENaoIniciadas"
                                  ],
                                ],
                              },
                              {
                                name: "Em andamento",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralPendentes"
                                  ],
                                ],
                              },
                              {
                                name: "Paralisadas",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralParalisadas"
                                  ],
                                ],
                              },
                              {
                                name: "Não iniciadas",
                                data: [
                                  dadosGraficoDeBarras["geral"][
                                    "acoesGeralNaoIniciadas"
                                  ],
                                ],
                              },
                            ]
                          : [
                              {
                                name: "Concluídas no prazo",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}ConcluidasNoPrazo`
                                    ],
                                  })
                                ),
                              },
                              {
                                name: "Concluídas com atraso",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}ConcluidasComAtraso`
                                    ],
                                  })
                                ),
                              },
                              {
                                name: "Atrasadas",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}Atrasadas`
                                    ],
                                  })
                                ),
                              },
                              {
                                name: "Atrasadas",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}AtrasadasENaoIniciadas`
                                    ],
                                  })
                                ),
                              },
                              {
                                name: "Pendentes",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}Pendentes`
                                    ],
                                  })
                                ),
                              },
                              {
                                name: "Paralisadas",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}Paralisadas`
                                    ],
                                  })
                                ),
                              },
                              {
                                name: "Não iniciadas",
                                data: atividadesSelecionadas.map(
                                  (atividade) => ({
                                    x: String(atividade)
                                      .charAt(0)
                                      .toUpperCase()
                                      .concat(String(atividade).slice(1)),
                                    y: dadosGraficoDeBarras[atividade][
                                      `acoes${String(atividade)
                                        .charAt(0)
                                        .toUpperCase()
                                        .concat(
                                          String(atividade).slice(1)
                                        )}NaoIniciadas`
                                    ],
                                  })
                                ),
                              },
                            ]
                      }
                      options={{
                        chart: {
                          height: 250,
                          width: "50%",
                        },
                        colors: [
                          "#98A94A",
                          "#77C2C6",
                          "#FF7878",
                          "#8B0000",
                          "#E5CB74",
                          "#FFA500",
                          "#B0B3D6",
                        ],
                        noData: {
                          align: "center",
                          verticalAlign: "middle",
                          text: "Nenhum dado disponível",
                        },
                        plotOptions: {
                          bar: {
                            horizontal: true,
                            columnWidth: "55%",
                            endingShape: "rounded",
                          },
                        },
                        dataLabels: {
                          enabled: true,
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },
                        xaxis: {
                          categories:
                            filtrosPesquisa.depende.length === 0 ||
                            filtrosPesquisa.depende.length === 4
                              ? ["Ações"]
                              : atividadesSelecionadas.map((atividade) => [
                                  String(atividade)
                                    .charAt(0)
                                    .toUpperCase()
                                    .concat(String(atividade).slice(1)),
                                ]),
                        },
                        yaxis: {
                          title: {
                            text: "Total de Ações",
                          },
                        },
                        fill: {
                          opacity: 1,
                        },
                        tooltip: {
                          y: {
                            formatter: function (val) {
                              return val + " ações";
                            },
                          },
                        },
                        legend: {
                          position: "top",
                          horizontalAlign: "left",
                          offsetX: 40,
                        },
                        title: {
                          align: "center",
                          margin: 10,
                          style: {
                            fontSize: "18px",
                            fontWeight: "bold",
                          },
                          text: "Placar de Atividades",
                        },
                        subtitle: {
                          text: subtitleGraficos(),
                          align: "center",
                          margin: 10,
                          style: {
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "gray",
                          },
                        },
                      }}
                    />
                  )}
              </Paper>
            </Grid>
          </Grid>
          <Stack
            width={"100%"}
            direction={"row"}
            gap={2}
            alignItems={"center"}
            my={3}
            p={2}
            borderBottom={"1px solid gray"}
          >
            <Box>
              <Typography fontSize={20} fontWeight={"bold"}>
                Planilha de Ações
              </Typography>
            </Box>
            <Box>
              <ActionButton
                title="Adicionar serviço"
                to="#"
                color="green"
                icon={<QueueIcon />}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  paddingX: 2,
                  textTransform: "none",
                  backgroundColor: "#F0F0F0",
                  gap: 1,
                  "&:hover": { backgroundColor: darken("#F0F0F0", 0.2) },
                }}
                onClick={handleOpenModalFiltros}
              >
                <Typography sx={{ color: "#4E5526" }}>Filtros</Typography>
                <TuneIcon sx={{ color: "#4E5526", fontSize: "20px" }} />
              </Button>
            </Box>
          </Stack>
          <Dialog
            open={openModalFiltros}
            onClose={handleCloseModalFiltros}
            PaperProps={{
              style: {
                width: "600px",
                maxWidth: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                padding: 20,
              },
            }}
          >
            <DialogContent
              style={{
                width: "100%",
              }}
            >
              <Grid container spacing={2} width="100%">
                <Grid item xs={6}>
                  <SelectAmbiental
                    label={"Atividade depende de:"}
                    isMulti={true}
                    value={filtrosPesquisa?.depende ?? []}
                    onChange={(event) => {
                      const selectedValues = event;
                      setAtividadesSelecionadas(selectedValues);

                      setFiltrosPesquisa((prevFiltros) => ({
                        ...prevFiltros,
                        depende: selectedValues,
                      }));
                    }}
                    fullWidth={true}
                    options={[
                      {
                        nome: "ambiental",
                        label: "Ambiental",
                      },
                      {
                        nome: "cliente",
                        label: "Cliente",
                      },
                      {
                        nome: "orgao",
                        label: "Órgão",
                      },
                    ]}
                    nameOptionDescription={"label"}
                    nameOptionValue={"nome"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectAmbiental
                    label={"Colaboradores:"}
                    options={colaboradores}
                    isMulti={true}
                    nameOptionDescription={"name"}
                    nameOptionValue={"id"}
                    value={filtrosPesquisa.colaboradoresServico ?? null}
                    onChange={(values) => {
                      setFiltrosPesquisa((prevFiltros) => ({
                        ...prevFiltros,
                        colaboradoresServico: values,
                      }));
                    }}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} width="100%" my={1}>
                <Grid item xs={6}>
                  <SelectAmbiental
                    label={"Setores:"}
                    isMulti={true}
                    value={filtrosPesquisa?.setores ?? []}
                    onChange={(values) => {
                      setFiltrosPesquisa((prevFiltros) => ({
                        ...prevFiltros,
                        setores: values,
                      }));
                    }}
                    fullWidth={true}
                    options={setores}
                    nameOptionDescription={"descricao"}
                    nameOptionValue={"id"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectAmbiental
                    label={"Situação:"}
                    options={statusServico}
                    nameOptionDescription={"descricao"}
                    nameOptionValue={"id"}
                    value={filtrosPesquisa.situacao ?? null}
                    onChange={(event) => {
                      setFiltrosPesquisa((prevFiltros) => ({
                        ...prevFiltros,
                        situacao: Number(event.target.value),
                      }));
                    }}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} width="100%" my={1}>
                <Grid item xs={6}>
                  <SelectAmbiental
                    label={"Período de Ações:"}
                    value={filtrosPesquisa?.periodo ?? []}
                    onChange={(event) => {
                      setFiltrosPesquisa((prevFiltros) => ({
                        ...prevFiltros,
                        periodo: event.target.value,
                      }));
                    }}
                    fullWidth={true}
                    options={[
                      {
                        periodo: "data_inicio",
                        label: "Data de Início",
                      },
                      {
                        periodo: "data_conclusao",
                        label: "Data de Conclusão",
                      },
                      {
                        periodo: "createdAt",
                        label: "Data de Criação",
                      },
                      {
                        periodo: "prazo",
                        label: "Prazo",
                      },
                    ]}
                    nameOptionDescription={"label"}
                    nameOptionValue={"periodo"}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignItems={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.7}
                >
                  <CalendarMonth
                    style={{
                      color: "#4E5526",
                    }}
                  />
                  <DateRangePicker
                    small={true}
                    startDate={startDate}
                    startDatePlaceholderText="Data inicial"
                    startDateId="data_inicio"
                    endDate={endDate}
                    endDateId="data_final"
                    endDatePlaceholderText="Data final"
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    isOutsideRange={() => false}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: 40,
              }}
            >
              <ActionButton
                title={"Filtrar"}
                to={null}
                color="blue"
                icon={<TuneIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleCloseModalFiltros();
                }}
              />

              <ActionButton
                title={"Limpar filtros"}
                to={null}
                color="red"
                icon={<DeleteIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleCloseModalFiltros();
                  handleLimparFiltros();
                }}
              />
            </DialogActions>
          </Dialog>
          <Grid container width="100%" spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: "1px solid #4E5526",
                    paddingLeft: 2,
                    height: "36px",
                    "&:hover": { borderColor: "#3B4220" },
                    "&.Mui-focused": { borderColor: "#4E5526" },
                    color: "#4E5526",
                    "&::placeholder": { color: "red", opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "#4E5526",
                          borderRadius: 2,
                          minWidth: "36px",
                          height: "36px",
                          padding: 0,
                          "&:hover": { backgroundColor: "#3B4220" },
                        }}
                        onClick={() => handleSearch(searchTerm)}
                      >
                        <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <AbaDashboard
                nomeAba={"nao_iniciados"}
                isAbaSelecionada={abaSelecionada === "nao_iniciados"}
                setAbaSelecionada={setAbaSelecionada}
                setFiltrosPesquisa={setFiltrosPesquisa}
                filtrosPesquisa={filtrosPesquisa}
              >
                NÃO INICIADOS
              </AbaDashboard>
            </Grid>
            <Grid item xs={2}>
              <AbaDashboard
                nomeAba={"em_andamento"}
                isAbaSelecionada={abaSelecionada === "em_andamento"}
                setAbaSelecionada={setAbaSelecionada}
                setFiltrosPesquisa={setFiltrosPesquisa}
                filtrosPesquisa={filtrosPesquisa}
              >
                EM ANDAMENTO
              </AbaDashboard>
            </Grid>
            <Grid item xs={2}>
              <AbaDashboard
                nomeAba={"finalizados"}
                isAbaSelecionada={abaSelecionada === "finalizados"}
                setAbaSelecionada={setAbaSelecionada}
                setFiltrosPesquisa={setFiltrosPesquisa}
                filtrosPesquisa={filtrosPesquisa}
              >
                FINALIZADOS
              </AbaDashboard>
            </Grid>
            <Grid item xs={2}>
              <AbaDashboard
                nomeAba={"paralisados"}
                isAbaSelecionada={abaSelecionada === "paralisados"}
                setAbaSelecionada={setAbaSelecionada}
                setFiltrosPesquisa={setFiltrosPesquisa}
                filtrosPesquisa={filtrosPesquisa}
              >
                PARALISADOS
              </AbaDashboard>
            </Grid>
          </Grid>

          {isDadosCarregando ? (
            <Paper width="100%" sx={{width: "100%", my: 5, p: 4}}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Paper>
          ) : dadosPlanilhaFormatados && dadosPlanilhaFormatados.length > 0 ? (
            dadosPlanilhaFormatados.map((processo) => (
              <ListaServicosDashboard
                processo={processo}
                aumentarPrioridade={handleAumentarPrioridadeProcesso}
                diminuirPrioridade={handleDiminuirPrioridadeProcesso}
                iniciarServico={handleIniciarServico}
                concluirServico={handleConcluirServico}
                paralisarServico={handleParalisarServico}
                scrollToServicoId={scrollToServicoId}
                setScrollToServicoId={setScrollToServicoId}
                servicosRefs={servicosIdsRef}
                abaSelecionada={abaSelecionada}
                imoveisDosContratos={imoveisDosContratos}
              />
            ))
          ) : (
            <Box
              width="100%"
              sx={{ background: "#FFF", textAlign: "center" }}
              p={2}
            >
              Nenhum serviço encontrado.
            </Box>
          )}
          <Paper
            style={{
              background: "white",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl>
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-evenly"}
              >
                <Grid item>
                  <Box my={2}>
                    <Typography>Página</Typography>
                    <Pagination
                      count={Math.ceil(count / perPage)}
                      page={page + 1}
                      onChange={(event, value) => setPage(value - 1)}
                      variant="outlined"
                      shape="rounded"
                      size="large"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                      style={{ marginTop: 10 }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography>Itens por página</Typography>
                    <TextField
                      select
                      label={"Itens por página"}
                      value={perPage}
                      onChange={(e) => setPerPage(parseInt(e.target.value))}
                      style={{ marginTop: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                {/* <Grid item>
                    <Box>
                      <Typography>Ordenar por</Typography>
                      <TextField
                        select
                        label={"Ordenação"}
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"id"}>ID</MenuItem>
                        <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                      </TextField>
                    </Box>
                  </Grid> */}
                {/* <Grid item>
                    <Box>
                      <Typography>Ordem</Typography>
                      <TextField
                        select
                        label={"Ordem"}
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"asc"}>Ascendente</MenuItem>
                        <MenuItem value={"desc"}>Descendente</MenuItem>
                      </TextField>
                    </Box>
                  </Grid> */}
              </Grid>
            </FormControl>
          </Paper>
        </>
      )}
    </JumboContentLayout>
  );
};

export default Dashboard;
