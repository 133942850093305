import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNotificacoes } from "app/contexts/NotificacoesContext";
import data from "../charts/Bar/components/data";
import Div from "@jumbo/shared/Div";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { darken } from "@mui/system";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SelectFiltrosAmbiental from "app/components/SelectFiltrosAmbiental";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PaginationComponent from "app/components/Pagination/Pagination";
import ActionButton from "app/components/ActionButton/ActionButton";
import OrdenacaoLista from "app/components/OrdenacaoLista";

const CaixaEntradaList = () => {
  const { notificacoesNaoLidas, setNotificacoesNaoLidas } = useNotificacoes();
  const userId = Number(localStorage.getItem("id"));
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const [notificacoesSelecionadas, setNotificacoesSelecionadas] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [selecionarMensagens, setSelecionarMensagens] = useState("todas");
  const [count, setCount] = useState("");
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const navigate = useNavigate();
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getNotificacoes = async () => {
    try {
      await ApiService.post(`/notificacoesmensagens/all/${Number(userId)}`, {
        page,
        perPage,
        sortBy,
        sortOrder,
        selecionar_mensagens: selecionarMensagens,
        searchTerm,
      }).then((response) => {
        if (response.status === 201) {
          setNotificacoes(response.data.notificacoesMensagens);
          setCount(response.data.count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarcarNotificacaoComoLida = async (id) => {
    try {
      await ApiService.put(`/notificacoesmensagens/marcar/lida/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Notificação marcada como lida!", "success");
            setNotificacoesNaoLidas((prev) => prev - 1);
            getNotificacoes();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleMarcarVariasNotificacoesComoLida = async (id) => {
    try {
      await ApiService.put(
        `/notificacoesmensagens/marcar/varias/lida/`,
        notificacoesSelecionadas
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Notificações marcadas como lida!", "success");
          const totalNotificacoesLidas =
            notificacoesNaoLidas.length - notificacoesSelecionadas.length;
          if (totalNotificacoesLidas < 0 || totalNotificacoesLidas === NaN) {
            setNotificacoesNaoLidas(0);
          } else {
            setNotificacoesNaoLidas(totalNotificacoesLidas);
          }

          getNotificacoes();
          setNotificacoesSelecionadas([]);
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleDeleteNotificacao = async (id) => {
    try {
      await ApiService.delete(`/notificacoesmensagens/${id}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Notificação excluída!", "success");
            getNotificacoes();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleDeleteManyNotificacoes = async () => {
    try {
      await ApiService.put(`/notificacoesmensagens/deletar/varias`, {
        ids: notificacoesSelecionadas,
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Notificações excluídas!", "success");
          getNotificacoes();
          setNotificacoesSelecionadas([]);
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!", "error");
    }
  };

  const handleSelecionarTodasNotificacoes = () => {
    const notificacoesNaoLidas = notificacoes.map(
      (notificacao) => notificacao.id
    );

    setNotificacoesSelecionadas(notificacoesNaoLidas);
  };

  const handleDesmarcarNotificacoesSelecionadas = () => {
    setNotificacoesSelecionadas([]);
  };

  const modalAlert = (id, quantidade) => {
    Swal.fire({
      title: `Tem certeza que deseja apagar ${
        quantidade === "uma" ? "a notificação" : "as notificações selecionadas"
      } ?`,
      text: `Você não poderá reverter a ação posteriormente!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (quantidade === "uma") {
          handleDeleteNotificacao(id);
        }
        if (id === null && quantidade === "varias") {
          handleDeleteManyNotificacoes();
        }
      }
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLimparFiltros = () => {};

  const handleFilter = () => {};

  useEffect(async () => {
    setIsDadosCarregados(false);
    await getNotificacoes();
    setIsDadosCarregados(true);
  }, [page, perPage, sortOrder, sortBy, selecionarMensagens, searchTerm]);

  return (
    <>
      {isDadosCarregados && (
        <JumboContentLayout
          header={<PageHeader title={"Caixa de Entrada"} />}
          layoutOptions={layoutOptions}
        >
          {lg && (
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
            ></Stack>
          )}

          <Div
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: "1px solid #4E5526",
                    paddingLeft: 2,
                    height: "36px",
                    "&:hover": { borderColor: "#3B4220" },
                    "&.Mui-focused": { borderColor: "#4E5526" },
                    color: "#4E5526",
                    "&::placeholder": { color: "red", opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "#4E5526",
                          borderRadius: 2,
                          minWidth: "36px",
                          height: "36px",
                          padding: 0,
                          "&:hover": { backgroundColor: "#3B4220" },
                        }}
                        onClick={() => handleSearch(inputValue)}
                      >
                        <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <SelectFiltrosAmbiental
                defaultValue={"selecionar"}
                value={selecionarMensagens}
                defaultText={"--------"}
                options={[
                  {
                    key: "todas",
                    description: "Todas as notificações",
                  },
                  {
                    key: "lidas",
                    description: "Notificações lidas",
                  },
                  {
                    key: "nao_lidas",
                    description: "Notificações não lidas",
                  },
                ]}
                handleChange={(event) => {
                  const value = event.target.value;
                  value === "selecionar"
                    ? setSelecionarMensagens("todas")
                    : setSelecionarMensagens(value);
                }}
                optionValueName={"key"}
                optionValueDescription={"description"}
              />
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  paddingX: 2,
                  textTransform: "none",
                  backgroundColor: "#F0F0F0",
                  gap: 1,
                  "&:hover": { backgroundColor: darken("#F0F0F0", 0.2) },
                }}
                onClick={handleOpenModal}
              >
                <Typography sx={{ color: "#4E5526" }}>Filtros</Typography>
                <TuneIcon sx={{ color: "#4E5526", fontSize: "20px" }} />
              </Button>
            </Box>
          </Div>
          <Div
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              my: 2,
              gap: 2,
            }}
          >
            {notificacoesSelecionadas.length > 0 && (
              <>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteManyNotificacoes}
                  sx={{
                    borderRadius: 2,
                    paddingX: 2,
                    backgroundColor: "#C84E4D",
                    textTransform: "none",
                  }}
                >
                  Excluir {notificacoesSelecionadas.length} notificações
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<CheckIcon />}
                  onClick={handleMarcarVariasNotificacoesComoLida}
                  sx={{
                    borderRadius: 2,
                    paddingX: 2,
                    textTransform: "none",
                  }}
                >
                  Marcar como lida
                </Button>
              </>
            )}
          </Div>

          <Box width={"100%"} p={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item lg={1}>
                <Checkbox
                  sx={{
                    color: "#696969",
                    "&.Mui-checked": {
                      color: "#95A64A",
                    },
                  }}
                  onChange={handleSelecionarTodasNotificacoes}
                />
              </Grid>
              <Grid item lg={3}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Nome
                  </Typography>
                  <OrdenacaoLista
                    column={"nome_destinatario"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Status
                  </Typography>
                  <OrdenacaoLista
                    column={"data_leitura"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Data
                  </Typography>
                  <OrdenacaoLista
                    column={"createdAt"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontSize={24} fontWeight={600}>
                    Horário
                  </Typography>
                  <OrdenacaoLista
                    column={"createdAt"}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  />
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box>
                  <Typography fontSize={24} fontWeight={600}>
                    Ações
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {notificacoes && notificacoes.length > 0 ? (
            notificacoes.map((notificacao, key) => (
              <Card
                sx={{
                  p: 2,
                  width: "100%",
                  minHeight: 100,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 1,
                }}
                key={key}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item lg={1}>
                    <Checkbox
                      sx={{
                        color: "#696969",
                        "&.Mui-checked": {
                          color: "#95A64A",
                        },
                      }}
                      key={notificacao.id}
                      checked={notificacoesSelecionadas.includes(
                        notificacao.id
                      )}
                      onChange={() => {
                        if (notificacoesSelecionadas.includes(notificacao.id)) {
                          setNotificacoesSelecionadas(
                            notificacoesSelecionadas.filter(
                              (id) => id !== notificacao.id
                            )
                          );
                        } else {
                          setNotificacoesSelecionadas([
                            ...notificacoesSelecionadas,
                            notificacao.id,
                          ]);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: 600,
                          marginBottom: 5,
                          fontSize: "18px",
                        }}
                      >
                        {notificacao.nome_destinatario}
                      </Typography>
                      <Typography
                        style={{
                          marginBottom: 5,
                          fontSize: "16px",
                          color: "#808080",
                        }}
                      >
                        {notificacao.descricao}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={2}>
                    <Stack direction={"column"} gap={2}>
                      {notificacao.data_leitura === null ? (
                        <Chip
                          label={
                            <Stack
                              alignItems={"center"}
                              flexDirection={"row"}
                              justifyContent={"center"}
                              display={"flex"}
                              gap={1}
                            >
                              Não lida{" "}
                              <PriorityHighIcon style={{ fontSize: "14px" }} />
                            </Stack>
                          }
                          sx={{
                            width: "50%",
                            background: "#FFCB3C",
                            color: "#484646",
                            borderRadius: "10px",
                          }}
                        />
                      ) : (
                        <Chip
                          color="info"
                          sx={{
                            p: 1,
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                          }}
                          label={
                            <Stack
                              alignItems={"center"}
                              flexDirection={"row"}
                              justifyContent={"center"}
                              display={"flex"}
                              gap={1}
                            >
                              Lida{" "}
                              <CheckCircleIcon style={{ fontSize: "14px" }} />
                            </Stack>
                          }
                        />
                      )}
                    </Stack>
                  </Grid>
                  <Grid item lg={2}>
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                      <Div>
                        <CalendarMonthIcon />
                      </Div>
                      <Div>
                        {moment.utc(notificacao.createdAt).format("DD/MM/YYYY")}
                      </Div>
                    </Stack>
                  </Grid>
                  <Grid item lg={2}>
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                      <Div>
                        <ScheduleIcon />
                      </Div>
                      <Div>
                        {moment.utc(notificacao.createdAt).format("HH:mm:ss")}
                      </Div>
                    </Stack>
                  </Grid>
                  <Grid item lg={2}>
                    <Stack direction={"row"} gap={2} sx={{ width: "100%" }}>
                      <Tooltip title={"Marcar como lida"}>
                        <IconButton
                          disabled={notificacao.data_leitura !== null}
                          onClick={() => {
                            handleMarcarNotificacaoComoLida(notificacao.id);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: notificacao.data_leitura
                              ? "disabled"
                              : "info",
                          }}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        onClick={() => {
                          modalAlert(notificacao.id, "uma");
                        }}
                      >
                        <DeleteIcon color="error" sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            ))
          ) : (
            <Card
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Nenhuma notificação encontrada.
            </Card>
          )}

          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
              style: {
                width: "800px", // Define a largura do modal
                maxWidth: "none", // Remove o limite máximo de largura
                padding: "0 80px",
              },
            }}
          >
            <DialogContent>
              <Typography>Quais filtros colocar aqui?</Typography>
            </DialogContent>

            <DialogActions>
              {/* <Button onClick={handleCloseModal} color="primary">Limpar filtros</Button>
                <Button onClick={handleCloseModal} color="primary">Filtrar</Button> */}
              <ActionButton
                title={"Filtrar"}
                to={null}
                color="blue"
                icon={<TuneIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleFilter();
                }}
              />

              <ActionButton
                title={"Limpar filtros"}
                to={null}
                color="red"
                icon={<DeleteIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleCloseModal();
                  handleLimparFiltros();
                  handleFilter(true);
                }}
              />
            </DialogActions>
          </Dialog>

          <PaginationComponent
            page={page}
            setPage={setPage}
            count={count}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </JumboContentLayout>
      )}
    </>
  );
};

export default CaixaEntradaList;
