import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import PaginationComponent from "app/components/Pagination/Pagination";
import BotoesExportarDados from "app/components/BotoesExportarDados";

const ClientesList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [clientes, setClientes] = useState([]);
  const [segmentosCliente, setSegmentosCliente] = useState([]);
  const [selectedClienteIds, setSelectedClienteIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState({
    id: null,
    nome: null,
  });
  const [todosClientesSelecionados, setTodosClientesSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [
    isModalAdicionarImovelClienteAberto,
    setIsModalAdicionarImovelClienteAberto,
  ] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Clientes", "create");
  const permissionUpdate = hasPermission("Clientes", "update");
  const permissionDelete = hasPermission("Clientes", "delete");
  if (!hasPermission("Clientes", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosClientes = () => {
    const todosIdsClientes = clientes.map((cliente) => cliente.id);
    if (!todosClientesSelecionados) {
      setSelectedClienteIds(todosIdsClientes);
    } else {
      setSelectedClienteIds([]);
    }
    setTodosClientesSelecionados(!todosClientesSelecionados);
  };

  const handleDeleteMuitosClientes = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os clientes selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosClientes();
      }
    });
  };

  const deleteMuitosClientes = useCallback(async () => {
    try {
      await ApiService.delete("/clientes/delete/many", {
        data: { ids: selectedClienteIds },
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Clientes removidos com sucesso!");
          getClientes();
          setSelectedClienteIds([]);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os clientes selecionados");
      console.log(error);
    }
  }, [selectedClienteIds]);

  const getClientes = useCallback(async () => {
    try {
      let apiUrl = `/clientes?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.clientes.map((cliente) => ({
            ...cliente,
            isSelected: selectedClienteIds.includes(cliente.id),
          }));
          setClientes(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedClienteIds]);

  // Nessa função, os dados de segmentos de cliente são puxados da API
  // Para depois o nome do segmento do cliente ser exibido no frontend
  const getSegmentosCliente = useCallback(async () => {
    try {
      await ApiService.get("/segmentos/all").then((response) => {
        setSegmentosCliente(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  });

  useEffect(() => {
    getClientes();
    getSegmentosCliente();
  }, [getClientes]);

  const handleCheckboxChange = (clienteId) => {
    setClientes((prevClientes) =>
      prevClientes.map((cliente) =>
        cliente.id === clienteId
          ? { ...cliente, isSelected: !cliente.isSelected }
          : cliente
      )
    );

    setSelectedClienteIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(clienteId)) {
        return prevSelectedIds.filter((id) => id !== clienteId);
      } else {
        return [...prevSelectedIds, clienteId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedClienteIds.length > 0);
    setTodosClientesSelecionados(false);
  };

  const handleOpenModalAdicionarImovelCliente = () => {
    setIsModalAdicionarImovelClienteAberto(
      !isModalAdicionarImovelClienteAberto
    );
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const clienteId = tableMeta.rowData[1];
          const cliente = clientes.find((cliente) => cliente.id === clienteId);
          return (
            <Checkbox
              checked={cliente.isSelected}
              onChange={() => handleCheckboxChange(clienteId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "segmento_cliente_id",
      label: "Segmento",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const idSegmento = tableMeta.rowData[4];
          const segmentoCliente = segmentosCliente.find(
            (segmento) => segmento.id === idSegmento
          );
          if (segmentoCliente) {
            return <span>{segmentoCliente.descricao}</span>;
          } else {
            return <span>Não encontrado</span>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const clienteId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Editar Cliente">
                <EditIcon
                  cursor={permissionUpdate ? "pointer" : "not-allowed"}
                  color="info"
                  style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                  disabled={!permissionUpdate}
                  onClick={() => {
                    if (permissionUpdate) {
                      navigate(`/app/editar-cliente/${clienteId}`);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Unidades">
                <MapsHomeWorkIcon
                  // cursor={permissionUpdate ? "pointer" : "not-allowed"}
                  cursor={"pointer"}
                  color="success"
                  // style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                  onClick={() => {
                    // if (permissionUpdate) {
                    navigate(`/app/cliente/unidades/${clienteId}`, {
                      state: {
                        nomeCliente: tableMeta.rowData[2],
                      },
                    });
                    // }
                  }}
                />
              </Tooltip>

              <Tooltip title="Excluir Cliente">
                <DeleteIcon
                  cursor={permissionDelete ? "pointer" : "not-allowed"}
                  color="error"
                  style={{ opacity: permissionDelete ? 1 : 0.5 }}
                  onClick={() => {
                    if (permissionDelete) {
                      navigate(`/app/cliente/${clienteId}/deletar`);
                    }
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedClienteIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      header={
        <PageHeader
          title={"Clientes"}
          icon="desc"
          btn={
            <ActionButton
              title="Novo cliente"
              to={hasPermission ? "/app/novo-cliente" : null}
              color="green"
              disabled={!hasPermission}
              icon={<QueueIcon />}
            />
          }
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <React.Fragment>
          <Grid
            container
            gap={1}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Div
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Pesquisar..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: 2,
                      border: "1px solid #4E5526",
                      paddingLeft: 2,
                      height: "36px",
                      "&:hover": { borderColor: "#3B4220" },
                      "&.Mui-focused": { borderColor: "#4E5526" },
                      color: "#4E5526",
                      "&::placeholder": { color: "red", opacity: 1 },
                      width: 350,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            backgroundColor: "#4E5526",
                            borderRadius: 2,
                            minWidth: "36px",
                            height: "36px",
                            padding: 0,
                            "&:hover": { backgroundColor: "#3B4220" },
                          }}
                          onClick={() => handleSearch(inputValue)}
                        >
                          <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {(selectedClienteIds.length > 0 && permissionDelete) && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteMuitosClientes}
                    sx={{
                      borderRadius: 2,
                      paddingX: 2,
                      backgroundColor: "#C84E4D",
                      textTransform: "none",
                    }}
                  >
                    Excluir {selectedClienteIds.length} cliente(s)
                  </Button>
                )}
                <BotoesExportarDados
                  dados_planilha={clientes}
                  colunas_planilha={[
                    "nome",
                    "email",
                    "segmento_cliente.descricao",
                    "createdAt",
                  ]}
                  nome_arquivo_planilha={"clientes_"}
                  titulo={"Clientes"}
                />
              </Box>
            </Div>

            <Card
              sx={{
                p: 2,
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <Grid
                container
                // justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <Checkbox
                    sx={{
                      color: "#696969",
                      "&.Mui-checked": {
                        color: "#95A64A",
                      },
                    }}
                    onChange={handleSelecionarTodosClientes}
                  />
                </Grid>
                <Grid item lg={3} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Nome
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      E-mail
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Segmento
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Ações
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <React.Fragment>
            <Grid container gap={1}>
              {clientes.length > 0 ? (
                <>
                  {clientes.map((c, key) => (
                    <Card
                      sx={{
                        p: 2,
                        width: "100%",
                        minHeight: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={key}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        // gap={2}
                      >
                        <Grid item lg={1}>
                          <Checkbox
                            sx={{
                              color: "#696969",
                              "&.Mui-checked": {
                                color: "#95A64A",
                              },
                            }}
                            key={c.id}
                            checked={c.isSelected}
                            onChange={() => handleCheckboxChange(c.id)}
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <Box
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              fontSize={18}
                              sx={{ display: "flex", fontWeight: 600 }}
                            >
                              {c.nome}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={4}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            <Typography fontSize={18} sx={{ display: "flex" }}>
                              {c.email}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={2}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            <Typography fontSize={18} sx={{ display: "flex" }}>
                              {segmentosCliente.find(
                                (segmento) =>
                                  segmento.id === c.segmento_cliente_id
                              )?.descricao ?? "Não encontrado"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={2}>
                          <Box
                            display={"flex"}
                            gap={"1rem"}
                            // justifyContent={"center"}
                          >
                            <Tooltip title="Editar cliente">
                              <EditIcon
                                cursor="pointer"
                                color="success"
                                onClick={() => {
                                  if (permissionUpdate) {
                                    navigate(`/app/editar-cliente/${c.id}`);
                                  }
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Unidades">
                              <MapsHomeWorkIcon
                                // cursor={permissionUpdate ? "pointer" : "not-allowed"}
                                cursor={"pointer"}
                                color="success"
                                // style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                                onClick={() => {
                                  // if (permissionUpdate) {
                                  navigate(`/app/cliente/unidades/${c.id}`, {
                                    state: {
                                      nomeCliente: c.nome,
                                    },
                                  });
                                  // }
                                }}
                              />
                            </Tooltip>

                            <Tooltip title="Excluir Cliente">
                              <DeleteIcon
                                cursor={
                                  permissionDelete ? "pointer" : "not-allowed"
                                }
                                color="error"
                                style={{ opacity: permissionDelete ? 1 : 0.5 }}
                                onClick={() => {
                                  if (permissionDelete) {
                                    navigate(`/app/cliente/${c.id}/deletar`);
                                  }
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <Grid container>
                  <Grid item style={{ width: "100%" }}>
                    <Box my={5} textAlign={"center"}>
                      <Typography>Nenhum cliente cadastrado.</Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <PaginationComponent
                page={page}
                setPage={setPage}
                count={count}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Grid>
            <ModalCadastroImovelCliente
              aberto={isModalAdicionarImovelClienteAberto}
              handleClose={handleOpenModalAdicionarImovelCliente}
              clienteSelecionado={clienteSelecionado}
            />
          </React.Fragment>
        </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};

export default ClientesList;
