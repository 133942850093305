import { Box, Card, Typography } from "@mui/material";
import React from "react";
import ActionButton from "../ActionButton/ActionButton";

const CardPainel = ({
  icon,
  totalGeral,
  to,
  marginTop,
  totalPorAno,
}) => {
  return (
    <Card
      style={{
        width: "100%",
        background: "#FFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        padding: 10,
        marginTop: marginTop ? marginTop : 0,
        height: "150px",
      }}
    >
      <Box display={"flex"} flexDirection={"row"} gap={"60px"} alignItems={"center"} width="50%">
        <Box>{icon}</Box>
        <Box>
          <Typography
            style={{ color: "#212529", fontWeight: "bold" }}
            variant={"h1"}
          >
            {totalGeral}
          </Typography>
        </Box>
        {totalPorAno ? (
          <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
            {totalPorAno && totalPorAno.length > 0
              ? totalPorAno.map((ano) => (
                  <Typography sx={{ fontSize: "18px", color: "#212529" }}>
                    {ano}
                  </Typography>
                ))
              : null}
          </Box>
        ) : null}</Box>
        <Box>
          <ActionButton color="olive" title={"Ver mais"} to={to} />
        </Box>
    </Card>
  );
};

export default CardPainel;
