import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import { Delete, Edit, Paid } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import "./style.css";
import ModalEditarPagamentoContrato from "../ModalEditarPagamentoContrato/ModalEditarPagamentoContrato";
import ActionButton from "../ActionButton/ActionButton";
import LogoutIcon from '@mui/icons-material/Logout';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ModalHistoricoPagamentoContrato = ({
  aberto,
  handleClose,
  contratoId,
  atualizarValorPago,
  atualizarValorAposEdicao,
  isReadOnly,
}) => {
  const [pagamentosContrato, setPagamentosContrato] = useState([]);
  const [pagamentoSelecionadoEdicao, setPagamentoSelecionadoEdicao] = useState(
    []
  );
  const [modalAberto, setModalAberto] = useState(aberto);
  const [modalEdicaoAbeto, setModalEdicaoAberto] = useState(false);
  const [idContrato, setIdContrato] = useState();
  const [idPagamento, setIdPagamento] = useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const Swal = useSwalWrapper();

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  // const handleAtualizarValorPagamentoAposEdicao = (idPagamento, valor) => {
  //   setContrato((prevContrato) => ({
  //     ...prevContrato,
  //     pagamentoscontrato: prevContrato.pagamentoscontrato.map((pagamento) =>
  //       pagamento.id === idPagamento ? { ...pagamento, valor } : pagamento
  //     ),
  //   }));
  // };

  const modalAlert = (id, valorPagamento) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeletePagamentoContrato(id, valorPagamento);
      }
    });
  };

  const handleModalEdicaoPagamento = () => {
    setModalEdicaoAberto(!modalEdicaoAbeto);
  };

  const handleEditPagamentoContrato = (idPagamento, dados) => {
    setIdPagamento(idPagamento);
    setPagamentoSelecionadoEdicao(dados);
    handleModalEdicaoPagamento();
  };

  const handleIdPagamento = () => {
    setIdPagamento("");
  };

  const handleDeletePagamentoContrato = useCallback(
    async (id, valorPagamento) => {
      try {
        await ApiService.delete(`/pagamentocontrato/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast(null, "Removido com sucesso!", "success");
              getPagamentosContrato();
              atualizarValorPago(response.data, "remocao");
            }
          })
          .catch((error) => {
            console.log(error);
            toast(null, "Ocorreu um error", "error");
          });
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro ao deletar o pagamento!");
      }
    }
  );

  useEffect(() => {
    setIdContrato(contratoId);
  }, [contratoId]);

  const getPagamentosContrato = useCallback(async () => {
    try {
      await ApiService.get(`/contratos/${contratoId}`).then((response) => {
        const { pagamentoscontrato } = response.data;
        setPagamentosContrato(pagamentoscontrato);
        if (response.status === 201) {
          toast("success", "Pagamento inserido com sucesso!");
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Ocorreu um erro ao obter os dados!");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getPagamentosContrato();
    };
    fetchData();
    setIsDadosCarregados(true);
  }, [modalAberto]);

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={{...style, borderRadius: 4, paddingX: 8, paddingY: 4 }}>
            <Typography variant="h2" component="h2" gutterBottom fontWeight={600}>
              Histórico de Pagamentos
            </Typography>
            <Divider sx={{ backgroundColor: "#4E5526" }} />
            {isDadosCarregados && (
              <Box sx={{ maxHeight: 300, overflow: "auto", marginTop: 3 }}>
                <List component="nav" aria-label="main mailbox folders">
                  {pagamentosContrato?.length > 0 ? (
                    <>
                      {pagamentosContrato.map((pagamento) => (
                        <List key={uuidv4()}>
                          <ListItem>
                            <Card
                              sx={{
                                p: 2,
                                width: '100%',
                                minHeight: 100,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 1,
                              }}
                            >
                              <ListItemIcon>
                                <Paid />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>Valor:</span>{" "}
                                  {pagamento.valor.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>
                                    Data de Pagamento:
                                  </span>{" "}
                                  {moment(pagamento.dataPagamento).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>
                                    Data de Registro:
                                  </span>{" "}
                                  {moment(pagamento.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>
                                    Descrição:
                                  </span>{" "}
                                  {pagamento.descricao ? (
                                    <span>{pagamento.descricao}</span>
                                  ) : (
                                    <span>Nenhuma descrição</span>
                                  )}
                                </Typography>
                              </ListItemText>
                              {isReadOnly === false && (
                                <Box>
                                  <Button
                                    size="small"
                                    color="info"
                                    onClick={() =>
                                      handleEditPagamentoContrato(
                                        pagamento.id,
                                        pagamento
                                      )
                                    }
                                  >
                                    <Edit />
                                  </Button>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() =>
                                      modalAlert(pagamento.id, pagamento.valor)
                                    }
                                  >
                                    <Delete />
                                  </Button>
                                </Box>
                              )}
                            </Card> 
                          </ListItem>
                          <ModalEditarPagamentoContrato
                            dados={pagamentoSelecionadoEdicao}
                            aberto={modalEdicaoAbeto}
                            contratoId={contratoId}
                            handleClose={handleModalEdicaoPagamento}
                            idPagamento={idPagamento}
                            resetarIdPagamento={handleIdPagamento}
                            atualizarListaPagamentos={getPagamentosContrato}
                            atualizarValorPago={atualizarValorPago}
                            atualizarValorAposEdicao={atualizarValorAposEdicao}
                          />
                        </List>
                      ))}
                    </>
                  ) : (
                    <ListItem>
                      <ListItemText>Nenhum pagamento registrado</ListItemText>
                    </ListItem>
                  )}
                </List>
              </Box>
            )}

            <Box mt={2} display="flex" justifyContent="flex-end">
              <ActionButton title="Fechar" color="red" icon={<LogoutIcon />} action={handleClose} />

            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalHistoricoPagamentoContrato;
