import ApiService from "app/services/config";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import mapeamentoNomeFiltros from "../mapeamentoNomeFiltros";
import { fontSize } from "@mui/system";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RESPONSAVEIS = {
  ambiental: "Ambiental",
  cliente: "Cliente",
  orgao: "Órgão",
  protocolado: "Protocolado",
};

const gerarPDFContratosFinanceiro = async (filtros, isPadrao, setIsLoading) => {
  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(process.env.REACT_APP_ROOT_URL + path).then(
      (data) => data.blob()
    );
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  const imagemIcone = await obterUrlImagem("/images/pdf/icone.png");
  const imagemTexto = await obterUrlImagem("/images/pdf/nome.png");

  const getContratos = async () => {
    try {
      setIsLoading(true);
      let url = `/contratos/all?`;
      if (filtros?.startDate && filtros?.endDate) {
        url += `&dataInicio=${moment(filtros?.startDate).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(filtros?.endDate).format("YYYY-MM-DD")}`;
      }

      if (filtros?.tipoContrato && filtros?.tipoContrato !== "selecionar") {
        url += `&tipoContrato=${filtros?.tipoContrato}`;
      }

      if (filtros?.situacao) {
        url += `&situacao=${filtros?.tipoContrato}`;
      }

      if (
        filtros?.statusOrcamento &&
        filtros?.statusOrcamento !== "selecionar"
      ) {
        url += `&statusOrcamento=${filtros?.statusOrcamento}`;
      }

      if (filtros?.elaborador && filtros?.elaborador !== "selecionar") {
        url += `&elaborador=${filtros?.elaborador}`;
      }

      if (filtros?.periodo && filtros?.periodo !== "selecionar") {
        url += `&periodo=${filtros?.periodo}`;
      }

      if (filtros?.tipoOrcamento && filtros?.tipoOrcamento !== "selecionar") {
        url += `&tipoOrcamento=${Number(filtros?.tipoOrcamento)}`;
      }

      if (filtros?.searchTerm && filtros?.searchTerm !== "") {
        url += `&searchTerm=${filtros?.searchTerm}`;
      }

      const response = await ApiService.get(url);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const contratos = await getContratos();

  const documentDefinition = {
    content: [
      {
        columns: [
          {
            image: imagemIcone,
            alignment: "left",
            width: 55,
          },
          {
            text: "",
            width: "*",
          },
          {
            image: imagemTexto,
            alignment: "right",
            width: 150,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: "\n",
      },
      {
        text: "\n",
      },
      {
        text: "Relatório financeiro",
        fontSize: 14,
        bold: true,
      },
      {
        text: "\n",
      },
    ],
    pageOrientation: "portrait",
    fontSize: 10,
    styles: {
      header: {
        bold: true,
        fontSize: 10,
        margin: [0, 10, 0, 5],
        color: "white",
        fillColor: "#024900",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      subheader: {
        bold: true,
        fontSize: 10,
        margin: [0, 5, 0, 5],
        color: "white",
        fillColor: "#0B9300",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      tableHeader: {
        fillColor: "#2BC900",
        color: "white",
        bold: true,
        alignment: "center",
        margin: [0, 5, 0, 5],
        padding: [6, 4],
        fontSize: 10,
      },
      tableCell: {
        margin: [0, 6, 0, 6],
        padding: [8, 4],
        fontSize: 9,
        alignment: "left",
        baseline: "middle",
      },
      bordered: {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["25%", "25%", "25%", "25%"],
          body: [],
        },
        layout: {
          hLineWidth: () => 1,
          vLineWidth: () => 1,
          hLineColor: () => "#000",
          vLineColor: () => "#000",
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 6,
          paddingBottom: () => 6,
        },
      },
    },
  };

  if(filtros?.searchTerm === "") {
    delete filtros.searchTerm;
  }
  if (filtros && Object.keys(filtros).length > 0) {
    documentDefinition.content.push({
      text: "Filtros:",
      fontSize: 11,
      bold: true,
    });
    Object.entries(filtros).forEach((filtro) => {
      if(filtro[0] === "searchTerm" && filtro[1] === "") {
        return;
      }
      if (
        filtro[1] ||
        (filtro[1]?.length > 1 &&
          filtro[1] !== "selecionar" &&
          filtro[1] !== null) ||
        filtro[0] === "searchTerm"
      ) {
        let chaveDinamica = "";
        switch (mapeamentoNomeFiltros.chaves[filtro[0]]) {
          case "Filtro por período":
            chaveDinamica = mapeamentoNomeFiltros.valores[filtro[1]];
            break;
          case "Tipo de Contrato":
            chaveDinamica = mapeamentoNomeFiltros.valores[filtro[1]];
            break;
          case "Situação":
            chaveDinamica = mapeamentoNomeFiltros.valores[filtro[1]];
            break;
          case "Data de início":
            chaveDinamica = moment.utc(filtro[1]).format("DD/MM/YYYY");
            break;
          case "Data final":
            chaveDinamica = moment.utc(filtro[1]).format("DD/MM/YYYY");
            break;
          case "Elaborador":
            const elaborador = contratos[0]?.elaboradores_orcamento.find(
              (elaborador) => elaborador?.usuario?.id === filtro[1]
            );
            chaveDinamica = contratos[0]?.elaboradores_orcamento.find(
              (elaborador) => elaborador?.usuario?.id === filtro[1]
            )?.usuario?.name ?? 'Nenhum contrato encontrado para o elaborador';
            break;
          case "Tipo de Orçamento":
            chaveDinamica = mapeamentoNomeFiltros.tipoOrcamento[filtro[1]];
            break;
          case "Status Orçamentário":
            chaveDinamica = mapeamentoNomeFiltros.statusOrcamento[filtro[1]];
            break;
          case "Termo de busca":
            chaveDinamica =
              filtro[1] !== "" && filtro[1] !== undefined
                ? `'${filtro[1]}'`
                : '" "';
            break;
        }
        documentDefinition.content.push({
          text: [
            {
              text: `${mapeamentoNomeFiltros.chaves[filtro[0]]}: `,
              bold: true,
              fontSize: 9,
            },
            {
              text: chaveDinamica,
              bold: false,
              fontSize: 9,
            },
            "\n",
          ],
        });
      }
    });
    documentDefinition.content.push({
      text: "\n",
    });
  }

  documentDefinition.content.push(
    {
      text: "MATRIZ DE CONTRATOS",
      fontSize: 10,
      bold: true,
    },
    {
      text: "\n",
    }
  );

  let totalPago = 0;
  let totalValorContratos = 0;

  if (contratos?.length > 0) {
    const tableBody = [
      [
        { text: "Contrato", style: "subheader" },
        { text: "Cliente", style: "subheader" },
        { text: "Valor acertado", style: "subheader" },
        { text: "Valor pago", style: "subheader" },
      ],
    ];

    contratos?.forEach((contrato) => {
      const valorTotal =
        contrato?.renegociacoescontrato?.length > 0
          ? Number(contrato?.renegociacoescontrato[0]?.valor)
          : contrato?.processos_contratos?.reduce(
              (totalProcesso, processoContrato) => {
                const totalServicos = processoContrato?.servicos?.reduce(
                  (totalServico, servico) => {
                    return (
                      totalServico +
                      Number(servico.quantidade) * Number(servico.valor)
                    );
                  },
                  0
                );
                return totalProcesso + totalServicos;
              },
              0
            );

      const valorPago =
        contrato.pagamentoscontrato?.reduce((total, pagamento) => {
          return total + Number(pagamento.valor);
        }, 0) || 0;

      const faltaPagar =
        contrato?.renegociacoescontrato?.length > 0
          ? contrato?.renegociacoescontrato[0]?.valor - valorPago
          : valorTotal - valorPago;
      const isFixo = contrato.tipocontratocontrato?.some(
        (tipo) =>
          tipo.tipos_contratos?.descricao?.toLowerCase() === "contrato fixo"
      );
      totalPago += valorPago;
      totalValorContratos += valorTotal;

      tableBody.push([
        { text: contrato?.descricao ?? "N/I", style: "tableCell" },
        { text: contrato?.Cliente?.nome ?? "N/I", style: "tableCell" },
        {
          text: isFixo
            ? "Contrato fixo"
            : Number(valorTotal).toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
              }),
          style: "tableCell",
        },
        {
          text: isFixo
            ? "Contrato fixo"
            : String(contrato?.status_orcamentario?.descricao).toLowerCase() ===
              "aguardando resposta"
            ? "Aguardando resposta"
            : {
                text: [
                  {
                    text: Number(valorPago).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }),
                    fontSize: 10,
                  },
                  {
                    text: "\n",
                  },
                  {
                    text: "Falta: ",
                    fontSize: 8,
                    bold: true,
                  },
                  {
                    text:
                      faltaPagar > 0
                        ? Number(faltaPagar).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00",
                    fontSize: 8,
                  },
                ],
              },
          style: "tableCell",
        },
      ]);
    });

    tableBody.push([
      {
        text: `Total: ${contratos?.length} contrato${
          contratos?.length > 1 ? "s" : ""
        }`,
        alignment: "left",
        bold: true,
        fontSize: 10,
        style: "subheader",
        colSpan: 2,
      },
      {},
      {
        text: `${Number(totalValorContratos).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`,
        alignment: "left",
        fontSize: 10,
        style: "subheader",
      },
      {
        text: [
          {
            text: Number(totalPago).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            fontSize: 10,
          },
          {
            text: "\n",
          },
          {
            text: "Falta: ",
            fontSize: 8,
            bold: true,
          },
          {
            text:
              totalPago > 0
                ? Number(totalValorContratos - totalPago).toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )
                : "R$ 0,00",
            fontSize: 8,
          },
        ],
        alignment: "left",
        style: "subheader",
      },
    ]);

    documentDefinition.content.push({
      style: "bordered",
      table: {
        widths: ["25%", "25%", "25%", "25%"],
        body: tableBody,
      },
    });
  } else {
    documentDefinition.content.push({
      text: "Nenhum contrato disponível",
      style: "subheader",
    });
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  });
};

export default gerarPDFContratosFinanceiro;
