import React from 'react';
import { IconButton, Grid, Typography, Box, TextField, MenuItem } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const PaginationComponent = ({ page, setPage, count, perPage, setPerPage }) => {
  const totalPages = Math.ceil(count / perPage);
  const startRecord = page * perPage + 1;
  const endRecord = Math.min((page + 1) * perPage, count);

  const handlePreviousPage = () => {
    if (page > 0) setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) setPage(page + 1);
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="flex-end" mt={2}>
        <Grid item>
            <Typography>Itens por Página:</Typography>
        </Grid>
        <Grid item>
            <Box>
                <TextField
                    select
                    value={perPage}
                    variant="standard"
                    onChange={(e) => setPerPage(parseInt(e.target.value))}
                    InputProps={{
                        disableUnderline: true, // Remove a linha de baixo
                    }}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                </TextField>
            </Box>
        </Grid>

        <Grid item>
            <Typography>{startRecord}-{endRecord} de {count}</Typography>
        </Grid>
        <Grid item>
            <IconButton onClick={handlePreviousPage} disabled={page === 0} sx={{ padding: 0 }}>
                <ArrowLeftIcon />
            </IconButton>
            <IconButton onClick={handleNextPage} disabled={page === totalPages - 1} sx={{ padding: 0 }}>
                <ArrowRightIcon />
            </IconButton>
        </Grid>
    </Grid>
  );
};

export default PaginationComponent;