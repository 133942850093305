import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import DescriptionIcon from '@mui/icons-material/Description';

const PageHeader = ({ title, subheader, icon = "desc", btn, withBorderBottom = true, paddingBottom = 2 }) => {
    const icons = {
        desc: <DescriptionIcon sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }} />,
    };

    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4,
                width: '100%',
                borderBottom: withBorderBottom ? '1px solid #4E5526' : 'none',
                pb: paddingBottom
            }}
        >
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                {icons[icon] || null}
                <Typography
                    sx={{
                        fontSize: 32,
                        fontWeight: 600,
                        color: "#212529"
                    }}
                >
                    {title}
                </Typography>
                <Typography 
                    variant={"body1"} 
                    color={"text.secondary"}
                    sx={{ mb: 0 }}
                >
                    {subheader}
                </Typography>
            </Div>

            { btn ?? null}
        </Div>

    );
};

export default PageHeader;