import ApiService from "app/services/config";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const exportarContratoPDF = async (contratoId) => {
  let widths = [];

  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(process.env.REACT_APP_ROOT_URL + path).then(
      (data) => data.blob()
    );
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  let urlImagemHeaderPortrait = await obterUrlImagem(
    "/header_ambiental_portrait.png"
  );

  try {
    await ApiService.get(`/contratos/exportar/pdf/${contratoId}`).then(
      (response) => {
        if (response.status === 200) {
          console.log(response.data);
          const { descricao: codigo, servicos, Cliente } = response.data;

          let totalGeral = 0;

          const servicosAgrupados = servicos.reduce((acc, servico) => {
            const processoId = servico.processo_administrativo?.id ?? "N/I";
            if (!acc[processoId]) {
              acc[processoId] = {
                descricao: servico.processo_administrativo?.descricao ?? "N/I",
                servicos: [],
              };
            }
            if (servico.tarefas_servico.length > 0) {
              acc[processoId].servicos.push(servico);
            }
            return acc;
          }, {});

          const bodyTable = [];

          for (const processoId in servicosAgrupados) {
            const { descricao, servicos } = servicosAgrupados[processoId];
            bodyTable.push([
              {
                text: `Processo Administrativo: ${descricao}`,
                colSpan: 6,
                bold: true,
                color: "#FFF",
                fillColor: "#4E5526",
              },
              {},
              {},
              {},
              {},
              {},
            ]);
            bodyTable.push([
              {
                text: "Contrato",
                bold: true,
                color: "#FFF",
                fillColor: "#98A94A",
              },
              {
                text: "Tarefa",
                bold: true,
                color: "#FFF",
                fillColor: "#98A94A",
              },
              {
                text: "Centro de Custos",
                bold: true,
                color: "#FFF",
                fillColor: "#98A94A",
              },
              {
                text: "Quantidade",
                bold: true,
                color: "#FFF",
                fillColor: "#98A94A",
              },
              {
                text: "Valor",
                bold: true,
                color: "#FFF",
                fillColor: "#98A94A",
              },
              {
                text: "Valor Total",
                bold: true,
                color: "#FFF",
                fillColor: "#98A94A",
              },
            ]);

            servicos.forEach((servico) => {
              servico.tarefas_servico.forEach((tarefa) => {
                const valorTotal = servico?.quantidade * servico?.valor;
                totalGeral += valorTotal;
                bodyTable.push([
                  {
                    text: codigo ?? "N/I",
                  },
                  {
                    text: tarefa?.descricao,
                  },
                  {
                    text: servico?.centro_de_custos?.descricao,
                  },
                  {
                    text: `${servico?.quantidade}`,
                  },
                  {
                    text: `${Number(servico?.valor).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}`,
                  },
                  {
                    text: `${Number(
                      servico?.quantidade * servico?.valor
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}`,
                  },
                ]);
              });
            });
          }

          bodyTable.push([
            {
              text: `Total: ${Number(totalGeral).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}`,
              colSpan: 6,
              bold: true,
              color: "#FFF",
              fillColor: "#98A94A",
              alignment: "right",
            },
            {},
            {},
            {},
            {},
            {},
          ]);

          const DEFINICOES_DOCUMENTO = {
            footer: function (currentPage, pageCount) {
              return {
                alignment: "center",
                fontSize: 10,
                bold: true,
                columns: [
                  {
                    text: currentPage.toString() + " de " + pageCount,
                    alignment: "center",
                  },
                  { text: "", width: "*" },
                  {
                    text: `Documento gerado em ${new Date().toLocaleDateString()} às ${new Date().toLocaleTimeString()}`,
                    alignment: "justify",
                    width: 300,
                  },
                  { text: "", width: "*" },
                ],
              };
            },
            header: {
              image: urlImagemHeaderPortrait,
              width: 500,
              margin: [40, 20, 40, 20],
            },
            content: [
              {
                text: `Proposta de orçamento - ${codigo}`,
                bold: true,
                margin: [10, 100, 40, 0],
              },
              {
                text: [
                  {
                    text: "Cliente: ",
                    bold: true,
                  },
                  {
                    text: Cliente?.nome ?? "N/I",
                  },
                ],
                margin: [10, 10, 40, 20],
              },
              {
                table: {
                  headerRows: 1,
                  widths: [60, "*", "*", 50, 80, "*"],
                  body: [
                    [
                      {
                        text: "MATRIZ DE CUSTO",
                        bold: true,
                        fillColor: "#98A94A",
                        colSpan: 6,
                        alignment: "center",
                        color: "#FFF",
                        bold: true,
                      },
                      {},
                      {},
                      {},
                      {},
                      {},
                    ],
                    ...bodyTable,
                  ],
                },
              },
            ],
          };

          pdfMake.createPdf(DEFINICOES_DOCUMENTO).open();
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
};
