import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import { Delete, Edit, Paid } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import "./style.css";
import ModalEditarRenegociacaoContrato from "../ModalEditarRenegociacaoContrato/ModalEditarRenegociacaoContrato";
import ActionButton from "../ActionButton/ActionButton";
import LogoutIcon from '@mui/icons-material/Logout';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ModalHistoricoRenegociacaoContrato = ({
  aberto,
  handleClose,
  contratoId,
  atualizarValorPago,
  atualizarValorRenegociacao,
  valorBase,
  atualizarValorAposEdicao,
}) => {
  const [renegociacoesContrato, setRenegociacoesContrato] = useState([]);
  const [renegociacaoSelecionadaEdicao, setRenegociacaoSelecionadaEdicao] =
    useState([]);
  const [modalAberto, setModalAberto] = useState(aberto);
  const [modalEdicaoAbeto, setModalEdicaoAberto] = useState(false);
  const [idContrato, setIdContrato] = useState();
  const [idRenegociacao, setIdRenegociacao] = useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const Swal = useSwalWrapper();

  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const modalAlert = (id, valorRenegociacao) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteRenegociacaoContrato(id, valorRenegociacao);
      }
    });
  };

  const handleModalEdicaoRenegociacao = () => {
    setModalEdicaoAberto(!modalEdicaoAbeto);
  };

  const handleEditRenegociacaoContrato = (idRenegociacao, dados) => {
    setIdRenegociacao(idRenegociacao);
    setRenegociacaoSelecionadaEdicao(dados);
    handleModalEdicaoRenegociacao();
  };

  const handleIdRenegociacao = () => {
    setIdRenegociacao("");
  };

  const handleDeleteRenegociacaoContrato = useCallback(async (id) => {
    try {
      await ApiService.delete(`/renegociacaocontrato/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const listaAtualizada = renegociacoesContrato.filter(
              (renegociacao) => renegociacao.id !== id
            );
            toast(null, "Removido com sucesso!", "success");
            getRenegociacoesContrato();
            atualizarValorRenegociacao(listaAtualizada, "remocao");
          }
        })
        .catch((error) => {
          console.log(error);
          toast("error", "Ocorreu um error");
        });
    } catch (error) {
      console.log(error);
      toast(null, "Ocorreu um erro ao deletar a renegociação!", "success");
    }
  });

  useEffect(() => {
    setIdContrato(contratoId);
  }, [contratoId]);

  const getRenegociacoesContrato = useCallback(async () => {
    try {
      await ApiService.get(`/contratos/${contratoId}`).then((response) => {
        const { renegociacoescontrato } = response.data;
        setRenegociacoesContrato(renegociacoescontrato);
      });
    } catch (error) {
      console.log(error);
      toast("error", "Ocorreu um erro ao obter os dados!");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getRenegociacoesContrato();
    };
    fetchData();
    setIsDadosCarregados(true);
  }, [modalAberto]);

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={{...style, borderRadius: 4, paddingX: 8, paddingY: 4 }}>
            <Typography variant="h2" component="h2" gutterBottom fontWeight={600}>
              Histórico de Renegociações
            </Typography>
            <Divider sx={{ backgroundColor: "#4E5526" }} />
            <Box width={1} mt={3}>
              <Typography variant="h5" component="h5" gutterBottom fontWeight={600}>
                Valor atual do contrato:{" "}
                {Number(valorBase).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Typography>
            </Box>
            {isDadosCarregados && (
              <Box sx={{ maxHeight: 300, overflow: "auto", marginTop: 3 }}>
                <List component="nav" aria-label="main mailbox folders">
                  {renegociacoesContrato?.length > 0 ? (
                    <>
                      {renegociacoesContrato.map((renegociacao) => (
                        <List key={uuidv4()}>
                          <ListItem>
                            <Card
                              sx={{
                                p: 2,
                                width: '100%',
                                minHeight: 100,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 1,
                              }}
                            >
                              <ListItemIcon>
                                <Paid />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>Valor:</span>{" "}
                                  {renegociacao.valor.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>
                                    Número de parcelas:
                                  </span>{" "}
                                  {`${renegociacao.numeroParcelas}x`}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>
                                    Data de Negociação:
                                  </span>{" "}
                                  {moment(renegociacao.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: 600 }}>
                                    Descrição:
                                  </span>{" "}
                                  {renegociacao.descricao ? (
                                    <span>{renegociacao.descricao}</span>
                                  ) : (
                                    <span>Nenhuma descrição</span>
                                  )}
                                </Typography>
                              </ListItemText>
                              <Box>
                                <Button
                                  size="small"
                                  color="info"
                                  onClick={() =>
                                    handleEditRenegociacaoContrato(
                                      renegociacao.id,
                                      renegociacao
                                    )
                                  }
                                >
                                  <Edit />
                                </Button>
                                <Button
                                  size="small"
                                  color="secondary"
                                  onClick={() =>
                                    modalAlert(
                                      renegociacao.id,
                                      renegociacao.valor
                                    )
                                  }
                                >
                                  <Delete />
                                </Button>
                              </Box>
                            </Card>
                          </ListItem>
                          <ModalEditarRenegociacaoContrato
                            dados={renegociacaoSelecionadaEdicao}
                            aberto={modalEdicaoAbeto}
                            contratoId={contratoId}
                            handleClose={handleModalEdicaoRenegociacao}
                            idRenegociacao={idRenegociacao}
                            resetarIdRenegociacao={handleIdRenegociacao}
                            atualizarListaRenegociacoes={
                              getRenegociacoesContrato
                            }
                            atualizarValorAposEdicao={atualizarValorAposEdicao}
                          />
                        </List>
                      ))}
                    </>
                  ) : (
                    <ListItem>
                      <ListItemText>
                        Nenhuma renegociação registrada
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </Box>
            )}

            <Box mt={2} display="flex" justifyContent="flex-end">
              <ActionButton title="Fechar" color="red" icon={<LogoutIcon />} action={handleClose} />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalHistoricoRenegociacaoContrato;
