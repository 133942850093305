import Div from "@jumbo/shared/Div";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import BotoesExportarDados from "../BotoesExportarDados";

const SearchAndDelete = ({
  title,
  handleDelete,
  selected,
  setInputValue,
  inputValue,
  handleSearch,
  tituloDocumento,
  colunasPlanilha,
  dadosPlanilha,
  nomeArquivoPlanilha
}) => {
  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <TextField
          variant="standard"
          placeholder="Pesquisar..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            sx: {
              borderRadius: 2,
              border: "1px solid #4E5526",
              paddingLeft: 2,
              height: "36px",
              "&:hover": { borderColor: "#3B4220" },
              "&.Mui-focused": { borderColor: "#4E5526" },
              color: "#4E5526",
              "&::placeholder": { color: "red", opacity: 1 },
              width: 350,
            },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  sx={{
                    backgroundColor: "#4E5526",
                    borderRadius: 2,
                    minWidth: "36px",
                    height: "36px",
                    padding: 0,
                    "&:hover": { backgroundColor: "#3B4220" },
                  }}
                  onClick={() => handleSearch(inputValue)}
                >
                  <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        {selected.length > 0 && (
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDelete}
            sx={{
              borderRadius: 2,
              paddingX: 2,
              backgroundColor: "#C84E4D",
              textTransform: "none",
            }}
          >
            Excluir {selected.length} {title}
          </Button>
        )}
        <BotoesExportarDados
          titulo={tituloDocumento}
          colunas_planilha={colunasPlanilha}
          dados_planilha={dadosPlanilha}
          nome_arquivo_planilha={nomeArquivoPlanilha}
        />
      </Box>
    </Div>
  );
};

export default SearchAndDelete;
