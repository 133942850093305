import { Stack, Tooltip } from "@mui/material";
import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PrintIcon from "@mui/icons-material/Print";
import exportarDadosCSV from "app/utils/exportarDadosCSV";

const acessarPropriedade = (obj, caminho) => {
  const partes = caminho.split(".");
  let resultado = obj;
  for (let parte of partes) {
    if (resultado && resultado[parte] !== undefined) {
      resultado = resultado[parte];
    } else if (resultado === null || resultado[parte] === null) {
      return "N/I";
    } else {
      return "Dados não disponíveis";
    }
  }
  return resultado;
};

const BotoesExportarDados = ({
  nome_arquivo_planilha,
  dados_planilha,
  colunas_planilha,
  titulo,
}) => {
  const gerarTabelaHTML = () => {
    let tabelaHTML = "<table style='width:98%; border-collapse: collapse;'>";

    tabelaHTML += "<thead><tr>";
    colunas_planilha.forEach((coluna) => {
      tabelaHTML += `<th style="border: 1px solid black; padding: 8px; text-align: left; color: black; font-family: 'Inter', sans-serif;">${coluna}</th>`;
    });
    tabelaHTML += "</tr></thead>";

    tabelaHTML += "<tbody>";
    dados_planilha.forEach((dado) => {
      tabelaHTML += "<tr>";
      colunas_planilha.forEach((coluna) => {
        let valor = acessarPropriedade(dado, coluna);

        if (Array.isArray(valor)) {
          valor = valor
            .map((item) => {
              if (item.usuario && item.usuario.name) {
                return item.usuario.name;
              }
              if(item.Role && item.Role.name) {
                return item.Role.name;
              }
              if(item.descricao) {
                return item.descricao;
              }
              return "Nome não disponível";
            })
            .join(", ");
        }

        tabelaHTML += `<td style="border: 1px solid black; padding: 8px; background-color: white; color: black; font-family: 'Inter', sans-serif;">${valor}</td>`;
      });
      tabelaHTML += "</tr>";
    });
    tabelaHTML += "</tbody></table>";

    return tabelaHTML;
  };

  const abrirJanelaImpressao = () => {
    const tabelaHTML = gerarTabelaHTML();
  
    const janelaImpressao = window.open("", "_blank");
    janelaImpressao.document.write(`
      <html>
        <head>
          <title>Imprimir Tabela</title>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
            body { 
              font-family: 'Inter', sans-serif; 
              margin: 0;
              padding: 0;
            }
            table { 
              width: 100%; 
              border-collapse: collapse;
            }
            th { 
              border: 1px solid black; 
              padding: 8px; 
              text-align: left; 
              color: black !important;
              font-family: 'Inter', sans-serif !important;
              font-weight: 600 !important;
            }
            td { 
              border: 1px solid black; 
              padding: 8px; 
              background-color: white; 
              color: black;
              font-family: 'Inter', sans-serif;
            }
            h1 {
              text-align: center;
              font-family: 'Inter', sans-serif;
              margin-bottom: 20px;
            }
          </style>
        </head>
        <body>
          <h1>${titulo}</h1>
          ${tabelaHTML}
        </body>
      </html>
    `);
  
    janelaImpressao.document.close();
    janelaImpressao.print();
  };
  

  return (
    <Stack direction={"row"} gap={2}>
      <Tooltip title="Exportar para CSV" style={{ cursor: "pointer" }}>
        <CloudDownloadIcon
          sx={{ color: "#4E5526", fontSize: "30px" }}
          onClick={() => {
            exportarDadosCSV(
              nome_arquivo_planilha,
              dados_planilha,
              colunas_planilha
            );
          }}
        />
      </Tooltip>
      <Tooltip title="Imprimir" style={{ cursor: "pointer" }}>
        <PrintIcon
          sx={{ color: "#4E5526", fontSize: "30px" }}
          onClick={abrirJanelaImpressao}
        />
      </Tooltip>
    </Stack>
  );
};

export default BotoesExportarDados;
