import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Alert,
  InputLabel,
  Breadcrumbs,
  IconButton,
  FormHelperText,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ActionButton from "app/components/ActionButton/ActionButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import AutoCompleteAmbiental from "app/components/AutoCompleteAmbiental";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const style = {
  width: 200,
  height: 54,
  padding: "8px",
  fontSize: "1rem",
  borderRadius: 4,
  border: "1px solid #ced4da",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  marginRight: "10px",
  marginTop: 3,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const servicoSchema = yup.object({
  observacoes: yup
    .array()
    .of(
      yup.object().shape({
        descricao: yup
          .string("Campo obrigatório")
          .required("Campo obrigatório"),
      })
    )
    .optional(),
  processoAdministrativoId: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório"),

  tarefa: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Selecione uma tarefa"),
  setorId: yup.number().required("Campo obrigatório"),
  tipoServicoId: yup.number().required("Campo obrigatório"),
  quantidade: yup
    .number("Campo obrigatório")
    .required("Campo quantidade obrigatório")
    .min(1, "Quantidade mínina é 1"),
  valor: yup.string().required("Campo valor obrigatório"),
  colaboradores: yup
    .array("Insira ao menos 1 colaborador")
    .of(yup.number("Campo obrigatório").required("Campo obrigatório"))
    .optional()
    .typeError("Se houver colaboradores, deve ser escolhido ao menos 1"),
  prazo: yup.date().optional().typeError("Data inválida"),
  contrato_id: yup
    .number()
    .required("Selecione um contrato")
    .typeError("Campo obrigatório"),
  processo_id: yup
    .number()
    .required("Selecione um contrato")
    .typeError("Campo obrigatório"),
});

const ServicosContratoForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));

  const { id, contratoId, processoId, clienteId, servicoId, procadmin } =
    useParams();
  const initialValues = {
    observacoes: [],
    processoAdministrativoId: Number(procadmin),
    tarefa: "",
    setorId: "",
    tipoServicoId: "",
    quantidade: 1,
    valor: 0,
    colaboradores: [],
    is_paralisado: "nao",
    is_em_revisao: "nao",
    prazo: "",
    porcentagem: 0,
    responsavel: "ambiental",
    contrato_id: contratoId !== "null" ? Number(contratoId) : "",
    processo_id: processoId !== "null" ? Number(contratoId) : "",
    clienteId: "",
  };
  const { theme } = useJumboTheme();
  const formRef = useRef();
  const colaboradorId = Number(localStorage.getItem("id"));
  const [servico, setServico] = useState(initialValues);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [centroDeCustos, setCentroDeCustos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [tipoOrcamentos, setTiposOrcamentos] = useState([]);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [setores, setSetores] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [isContratoFixo, setIsContratoFixo] = useState(false);
  const [
    valoresFinaisServicosCadastrados,
    setValoresFinaisServicosCadastrados,
  ] = useState([]);
  const [
    valoresFinaisServicosNaoCadastrados,
    setValoresFinaisNaoServicosCadastrados,
  ] = useState([]);
  const [clienteIdSelecionado, setClienteIdSelecionado] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const Swal = useSwalWrapper();
  const location = useLocation();
  const [valorServico, setValorServico] = useState(0);
  const [listaContratosSelect, setListaContratosSelect] = useState([]);
  const [contratoSelecionadoSelect, setContratoSelecionadoSelect] =
    useState(null);
  const [processosAdminProcesso, setProcessosAdminProcesso] = useState([]);

  const [novoNomeCliente, setNovoNomeCliente] = useState("");
  const [novaObservacao, setNovaObservacao] = useState("");
  const [listaAuxiliarObservacoes, setListaAuxiliarObservacoes] = useState([]);
  const [isCampoValorVisivel, setIsCampoValorVisivel] = useState(false);
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleOpenModal = () => {
    setModalAberto(true);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getServico = useCallback(async () => {
    try {
      const response = await ApiService.get(`/servicos/${servicoId}`);
      if (response.status === 200) {
        const { tarefas_servico, servicocolaborador, valor, quantidade } =
          response.data;

        if (valor > 0) {
          setIsCampoValorVisivel(true);
        }

        handleCalculateValue(valor, quantidade);

        const tarefasObject = tarefas_servico?.map((tarefa) => ({
          id: tarefa?.id,
          descricao: tarefa?.descricao,
        }));

        const idsColaboradores = servicocolaborador?.map(
          (colaborador) => colaborador?.users?.id
        );

        let servicoData = {
          ...response.data,
          contrato_id: response.data?.contratoId,
          processo_id: response.data?.processo_contrato_id,
          tarefa: tarefasObject[0]?.id,
          colaboradores: idsColaboradores,
          prazo: response.data?.prazo
            ? moment.utc(response.data?.prazo).format("YYYY-MM-DD")
            : "",
          processoAdministrativoId: Number(procadmin),
          observacoes: response.data?.observacoes,
        };

        setServico(servicoData);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getCentroDeCustos = useCallback(async () => {
    try {
      await ApiService.get("/centrosdecustos/all").then((response) => {
        setCentroDeCustos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico/all").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento/all").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const filteredStatusOrcamentos = statusOrcamentos?.filter(
    (status) =>
      String(status.descricao).toLowerCase() !== "finalizado" &&
      String(status.descricao).toLowerCase() !== "finalizado contrato" &&
      String(status.descricao).toLowerCase() !== "finalizado serviço"
  );

  const handleCalcularValorServicos = useCallback(async () => {
    try {
      if (servico && servico?.servicos?.length > 0) {
        const { servicos: servicosContrato } = servico;

        const valoresFinaisServico = servicosContrato?.map((servico) => {
          return {
            id: servico.id,
            valorFinal: servico.valor * servico.quantidade,
          };
        });
        setValoresFinaisServicosCadastrados(valoresFinaisServico);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getColaboradores = useCallback(async () => {
    try {
      await ApiService.get("/users/colaboradores").then((response) => {
        setColaboradores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/tipoorcamento/all").then((response) => {
        setTiposOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposContrato = useCallback(async () => {
    try {
      await ApiService.get("/tipocontrato/all").then((response) => {
        setTiposContrato(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getClientes = useCallback(async () => {
    try {
      await ApiService.get("/clientes/all").then((response) => {
        setClientes(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getUnidades = async () => {
    try {
      await ApiService.get(
        `/unidade/cliente/select/${clienteIdSelecionado}`
      ).then((response) => {
        if (response.status === 200) {
          setUnidades(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSetores = useCallback(async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        setSetores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const handleCalculateValue = useCallback(
    (valor, quantidade, id) => {
      const valorFinal = valor * quantidade;

      setValorServico(
        Number(valorFinal).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      );
    },
    [valoresFinaisServicosCadastrados, valoresFinaisServicosNaoCadastrados]
  );

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setFieldValue
  ) => {
    if (Object.entries(formRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }
    if (servicoId && servicoId !== "null") {
      const newValues = {
        ...values,
        contrato_id: Number(contratoId),
        processo_id: Number(processoId),
        is_paralisado: values.is_paralisado === "sim" ? true : false,
        is_em_revisao: values.is_em_revisao === "sim" ? true : false,
        userId,
        valor:
          typeof values.valor === "string"
            ? !isNaN(parseFloat(values.valor.replace(",", ".")))
              ? parseFloat(values.valor.replace(",", "."))
              : 0
            : values.valor,
      };

      delete newValues.servico_contrato_id;
      delete newValues.processo_contrato_id;
      delete newValues.tarefas_servico;
      delete newValues.contratoId;
      delete newValues.centrosDeCustoId;
      delete newValues.Contrato;
      delete newValues.setor_responsavel;
      delete newValues.documentos;
      delete newValues.processo_contrato;

      if (
        values.tipocontratocontrato?.every(
          (tipoContrato) =>
            tipoContrato.descricao !== "RENOVAÇÃO" &&
            (!tipoContrato.tipos_contratos ||
              tipoContrato.tipos_contratos.descricao !== "RENOVAÇÃO")
        )
      ) {
        delete values.dataVencimento;
        delete values.dataRenovacao;
      }

      delete newValues.Cliente;
      delete newValues.cliente_id;
      delete newValues.tipo_orcamento;
      delete newValues.elaborador_orcamento;
      delete newValues.Imovel;
      delete newValues.id;
      delete newValues.createdAt;
      delete newValues.updatedAt;
      delete newValues.deletedAt;
      delete newValues.colaborador_que_criou_id;
      delete newValues.status_andamento_servico;
      delete newValues.data_inicio;
      delete newValues.data_conclusao;

      try {
        await ApiService.put(`/servicos/${servicoId}`, newValues).then(
          (response) => {
            if (response.status === 200) {
              toast("success", "Atualizado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
              );
            }
          }
        );
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    } else {
      const newValues = {
        ...values,
        contrato_id:
          contratoId !== "null"
            ? Number(contratoId)
            : Number(values.contrato_id),
        processo_id:
          processoId !== "null"
            ? Number(processoId)
            : Number(values.processo_id),
        is_paralisado: values.is_paralisado === "sim" ? true : false,
        is_em_revisao: values.is_em_revisao === "sim" ? true : false,
        userId,
        valor:
          typeof values.valor === "string"
            ? !isNaN(parseFloat(values.valor.replace(",", ".")))
              ? parseFloat(values.valor.replace(",", "."))
              : 0
            : values.valor,
      };
      try {
        await ApiService.post("/servicos", newValues)
          .then((response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso");
              if (
                contratoId === "null" &&
                processoId === "null" &&
                procadmin === "null"
              ) {
                navigate(
                  `/app/contrato/${values.contrato_id}/cliente/${values.clienteId}/processos/${values.processo_id}/procadmin/${values.processoAdministrativoId}/servicos`
                );
              } else {
                navigate(
                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
                );
              }
              resetForm();
            }
          })
          .catch((error) => {
            console.log(error);
            let message = error;
            toast("error", "Ocorreu um erro ao criar o serviço!");

            if (error.response.data) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    }
  };

  const handleDeleteServico = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${processoId}`)
        .then((response) => {
          toast("success", "Removido com sucesso!");
          const servicosFiltrados = servicos?.filter(
            (servico) => servico.id !== id
          );
          setServico((prevContrato) => ({
            ...prevContrato,
            servicos: servicosFiltrados,
          }));
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleDeleteContrato = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${servicoId}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso!");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
            );
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Criação de novos itens no CreateSelect

  const handleCreateCliente = (value) => {
    setNovoNomeCliente(value);
  };

  useEffect(() => {
    if (novoNomeCliente !== "") {
      handleOpenModal();
    }
  }, [novoNomeCliente]);

  const handleCreateTipoOrcamento = useCallback(async (descricao) => {
    const response = await ApiService.post("/tipoorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTiposOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateCentroDeCustos = useCallback(async (descricao) => {
    const response = await ApiService.post("/centrosdecustos", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getCentroDeCustos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateTipoServico = useCallback(async (descricao) => {
    const response = await ApiService.post("/tiposservico", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTiposServicos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateTarefa = useCallback(async (descricao) => {
    const response = await ApiService.post("/tarefas", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTarefas();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateSetor = useCallback(async (descricao) => {
    const response = await ApiService.post("/setores", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getSetores();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const modalAlertDeleteServico = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o serviço?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteContrato(id);
      }
    });
  };

  const handleDesconectarTarefaServico = async (tarefaId, servicoId) => {
    try {
      await ApiService.put(
        `/servicos/tarefa/${tarefaId}/desconectar/${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          console.log("Tarefa desconectada do serviço!");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const modalAlert = (idObservacao, values) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteObservacao(idObservacao, values);
      }
    });
  };

  const handleDeleteObservacao = async (observacaoId, values) => {
    try {
      const response = await ApiService.delete(
        `/servicos/deletar/observacao/${observacaoId}`
      );

      if (response.status === 200) {
        toast(null, "Removido com sucesso!", "success");

        const observacoesFiltradas = values?.observacoes?.filter(
          (observacao) => observacao.id !== observacaoId
        );
        setServico(() => ({
          ...values,
          observacoes: observacoesFiltradas,
        }));
      }
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const getContratosSelect = async () => {
    try {
      await ApiService.get("/contratos/all").then((response) => {
        if (response.status === 200) {
          setListaContratosSelect(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChangeContratoSelecionadoSelect = (id) => {
  //   const contrato = listaContratosSelect.find(
  //     (contrato) => contrato.id === id
  //   );
  //   setContratoSelecionadoSelect(contrato);
  // };

  const getProcessosAdminProcessoContrato = useCallback(
    async (processoId) => {
      try {
        await ApiService.get(
          `/processosadmin/processo-contrato/procadmins/${processoId}`
        ).then((response) => {
          if (response.status === 200) {
            setProcessosAdminProcesso(response.data);
            console.log(response.data);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    [contratoSelecionadoSelect]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (servicoId) {
        getServico();
        handleCalcularValorServicos();
      }
      await Promise.all([
        getCentroDeCustos(),
        getTiposServicos(),
        getProcessosAdministrativos(),
        getTarefas(),
        getStatusOrcamento(),
        getColaboradores(),
        getClientes(),
        getTiposContrato(),
        getTiposOrcamento(),
        getSetores(),
      ]);
      setDadosCarregados(true);
    };
    fetchData();
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
    if (
      processoId === "null" &&
      contratoId === "null" &&
      procadmin === "null"
    ) {
      getContratosSelect();
    }
  }, [id]);

  useEffect(() => {
    if (clienteIdSelecionado) {
      getUnidades();
    }
  }, [clienteIdSelecionado]);

  useEffect(() => {
    if (dadosCarregados && servico) {
      const temPermissaoDeAcessarAPagina = hasPermission("Contratos", "read");
      const contratoCriadoPeloUsuarioAtual =
        servico.colaborador_que_criou_id === userId;
      console.log(servico.colaborador_que_criou_id, userId);
      if (!temPermissaoDeAcessarAPagina && !contratoCriadoPeloUsuarioAtual) {
        navigate("/app");
      }
      if (breadCrumbsInfo) {
        const isFixo = breadCrumbsInfo?.tipocontratocontrato?.some(
          (tipoContrato) =>
            String(tipoContrato?.tipos_contratos?.descricao)
              .toLowerCase()
              .includes("fixo")
        );
        setIsContratoFixo(isFixo);
      }
    }
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [dadosCarregados, servico, breadCrumbsInfo]);

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&servicoId=${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId && contratoId !== "null") {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {dadosCarregados && (
        <JumboContentLayout layoutOptions={layoutOptions}>
          {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
          <BotaoVoltarBreadCrumbs
            handleBack={() => {
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
              );
            }}
          />
          <BadgeNomeCliente
            nome={breadCrumbsInfo?.descricao}
            linkTo={`/app/contrato/${contratoId}/detalhes`}
          />
          {breadCrumbsInfo ? (
            <Typography
              variant={"h4"}
              fontWeight={600}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`
                );
              }}
            >
              {
                breadCrumbsInfo?.processos_contratos?.[0]
                  ?.processo_administrativo?.descricao
              }
            </Typography>
          ) : null}
          <PageHeader
            title={servicoId ? `Editar serviço` : "Novo serviço"}
            icon={
              <DescriptionIcon
                sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
              />
            }
          />

          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o (s) serviço (s) abaixo:
              </Alert>
            </Stack>
          )}

          <Formik
            innerRef={formRef}
            initialValues={servico}
            validationSchema={servicoSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleBlur,
              isSubmitting,
              handleChange,
              setFieldValue,
              setSubmitting,
              resetForm,
              errors,
              setFieldTouched,
              touched,
            }) => (
              <Form style={{ width: "100%" }}>
                <Grid container width="100%" spacing={2}>
                  <Grid item xs={12}>
                    <AutoCompleteAmbiental
                      fullWidth={true}
                      name="tarefa"
                      setFieldValue={setFieldValue}
                      handleCreateData={handleCreateTarefa}
                      nameOptionDescription={"descricao"}
                      nameOptionValue={"id"}
                      label={"Tarefa"}
                      value={values.tarefa}
                      options={tarefas}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("tarefa", true);
                      }}
                      placeholder={"Selecione uma tarefa"}
                    />
                    {touched.tarefa && errors.tarefa && (
                      <div style={{ color: "red" }}>{errors.tarefa}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={6}>
                    <AutoCompleteAmbiental
                      fullWidth={true}
                      name="tipoServicoId"
                      setFieldValue={setFieldValue}
                      handleCreateData={handleCreateTipoServico}
                      nameOptionDescription={"descricao"}
                      nameOptionValue={"id"}
                      label={"Tipo de serviço"}
                      value={values.tipoServicoId}
                      options={tiposServicos}
                      placeholder={"Selecione um tipo de serviço"}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("tipoServicoId", true);
                      }}
                    />
                    {touched.tipoServicoId && errors.tipoServicoId && (
                      <div style={{ color: "red" }}>{errors.tipoServicoId}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteAmbiental
                      fullWidth={true}
                      name="setorId"
                      setFieldValue={setFieldValue}
                      handleCreateData={handleCreateSetor}
                      nameOptionDescription={"descricao"}
                      nameOptionValue={"id"}
                      label={"Setor responsável"}
                      value={values.setorId}
                      options={setores}
                      placeholder={"Selecione um setor"}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("setorId", true);
                      }}
                    />
                    {touched.setorId && errors.setorId && (
                      <div style={{ color: "red" }}>{errors.setorId}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={6}>
                    <InputAmbiental
                      label={"Prazo"}
                      name={"prazo"}
                      fullWidth={false}
                      value={values.prazo}
                      onChange={handleChange}
                      type={"date"}
                      width={300}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("prazo", true);
                      }}
                    />
                    {touched.prazo && errors.prazo && (
                      <div style={{ color: "red" }}>{errors.prazo}</div>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <InputAmbiental
                      label={"Quantidade"}
                      name={"quantidade"}
                      adornment={"Qtde."}
                      fullWidth={true}
                      value={values.quantidade}
                      onChange={(event) => {
                        const quantidade = Number(event.target.value);
                        if (quantidade < 0) {
                          setFieldValue(`quantidade`, Math.abs(quantidade));
                        } else if (quantidade === "") {
                          setFieldValue(`quantidade`, 1);
                        } else {
                          setFieldValue(`quantidade`, quantidade);
                        }
                        handleCalculateValue(quantidade, values.valor);
                      }}
                      type={"number"}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("quantidade", true);
                      }}
                    />
                    {touched.quantidade && errors.quantidade && (
                      <div style={{ color: "red" }}>{errors.quantidade}</div>
                    )}
                  </Grid>
                  {isCampoValorVisivel || values.valor !== 0 ? (
                    <>
                      <Grid item xs={2}>
                        <InputAmbiental
                          label={"Valor"}
                          name={"valor"}
                          adornment={"R$"}
                          fullWidth={true}
                          value={values.valor}
                          onChange={(event, value) => {
                            const valor = parseFloat(
                              value.replace(/\./g, "").replace(",", ".")
                            );
                            console.log(`Valor: ${valor}`);
                            if (
                              value === undefined ||
                              value === null ||
                              value === ""
                            ) {
                              setFieldValue("valor", 0);
                            } else {
                              setFieldValue("valor", value);
                            }
                            handleCalculateValue(
                              values.quantidade,
                              Number(valor)
                            );
                          }}
                          hasMask={true}
                          maskType={"dinheiro"}
                          handleBlur={(e) => {
                            handleBlur(e);
                            setFieldTouched("valor", true);
                          }}
                        />
                        {touched.valor && errors.valor && (
                          <div style={{ color: "red" }}>{errors.valor}</div>
                        )}
                      </Grid>
                      <Grid item xs={2} my={3.9}>
                        <div
                          style={{
                            backgroundColor: "#A9A9A9",
                            width: "100%",
                            height: "40px",
                            borderRadius: "10px",
                            color: "#FFF",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            fontWeight: 500,
                          }}
                        >
                          {valorServico}
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={2} my={3.6}>
                      <ActionButton
                        title={"Adicionar valor"}
                        color={"green"}
                        isSubmitAction={true}
                        action={() => setIsCampoValorVisivel(true)}
                        borderRadius={20}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container width="100%" spacing={2}>
                  <Grid item xs={12}>
                    <SelectAmbiental
                      label={"Colaboradores"}
                      options={colaboradores}
                      name={"colaboradores"}
                      fullWidth={true}
                      value={values.colaboradores}
                      onChange={(values) => {
                        setFieldValue("colaboradores", values);
                      }}
                      nameOptionValue={"id"}
                      nameOptionDescription={"name"}
                      isMulti={true}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("colaboradores", true);
                      }}
                    />
                    {touched.colaboradores && errors.colaboradores && (
                      <div style={{ color: "red" }}>{errors.colaboradores}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={12}>
                    <label style={{ fontWeight: "bold" }}>Observações</label>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name="observacoes">
                      {({ push, remove }) => (
                        <Box my={1} width={"100%"}>
                          {values.observacoes &&
                          values.observacoes.length === 0 ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              height={150}
                            >
                              <Typography my={1}>
                                Nenhuma observação registrada
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              {values.observacoes &&
                                values.observacoes.map((observacao, index) => (
                                  <>
                                    <Box
                                      key={index}
                                      my={2}
                                      display="flex"
                                      alignItems="center"
                                      width={"100%"}
                                    >
                                      <InputAmbiental
                                        name={`observacoes.${index}.descricao`}
                                        value={
                                          values.observacoes[index].descricao
                                        }
                                        onChange={(e) => {
                                          if (!observacao.id) {
                                            const observacaoPorIndex =
                                              values.observacoes[index];
                                            const valueDescricao =
                                              e.target.value;
                                            const observacaoAtualizada = {
                                              ...observacaoPorIndex,
                                              descricao: valueDescricao,
                                            };
                                            setFieldValue(
                                              `observacoes[${index}]`,
                                              observacaoAtualizada
                                            );
                                          }
                                          if (observacao.id) {
                                            const observacaoPorId =
                                              values.observacoes?.find(
                                                (obs) =>
                                                  obs.id === observacao.id
                                              );
                                            const indiceObservacao =
                                              values.observacoes?.findIndex(
                                                (obs) =>
                                                  obs.id === observacao.id
                                              );
                                            const valueDescricao =
                                              e.target.value;
                                            const observacaoAtualizada = {
                                              ...observacaoPorId,
                                              descricao: valueDescricao,
                                            };
                                            setFieldValue(
                                              `observacoes[${indiceObservacao}]`,
                                              observacaoAtualizada
                                            );
                                          }
                                        }}
                                        label={`Observação ${index + 1}`}
                                        fullWidth={false}
                                        width={1150}
                                      />
                                      <Box>
                                        <IconButton
                                          variant="outlined"
                                          color="error"
                                          onClick={() =>
                                            observacao.id
                                              ? modalAlert(
                                                  observacao.id,
                                                  values
                                                )
                                              : remove(index)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                    {observacao.id && (
                                      <>
                                        <Stack
                                          px={1}
                                          direction={"row"}
                                          justifyContent={"space-between"}
                                        >
                                          <Typography color="#909FAE">
                                            Usuário:{" "}
                                            {observacao.usuario?.name ?? "N/I"}
                                          </Typography>
                                          <Typography color="#909FAE">
                                            Criado em:{" "}
                                            {moment(
                                              observacao.createdAt
                                            ).format("DD/MM/YY HH:MM")}
                                          </Typography>
                                        </Stack>
                                        <Divider my={4} />
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                          )}

                          <Grid
                            container
                            width="100%"
                            spacing={1}
                            alignItems={"center"}
                          >
                            <Grid item xs={9}>
                              <InputAmbiental
                                onChange={(e) => {
                                  setNovaObservacao(e.target.value);
                                }}
                                placeholder="Nova observação"
                                value={novaObservacao}
                                fullWidth={true}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                marginTop: "7px",
                              }}
                            >
                              <ActionButton
                                color="green"
                                title={"Adicionar nova observação"}
                                action={() => {
                                  if (novaObservacao.trim()) {
                                    push({
                                      descricao: novaObservacao,
                                      userId: Number(userId),
                                    });
                                    setListaAuxiliarObservacoes(
                                      (prevLista) => ({
                                        ...prevLista,
                                        descricao: novaObservacao,
                                        userId: Number(userId),
                                      })
                                    );
                                    setNovaObservacao("");
                                  }
                                }}
                                isSubmitAction={true}
                                fullWidth={true}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <input
                  type="number"
                  name="userId"
                  value={Number(userId)}
                  hidden
                />
                <input
                  type="number"
                  name="roleId"
                  value={Number(roleId)}
                  hidden
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  my={3}
                  sx={{
                    position: "sticky",
                    right: 0,
                    bottom: 10,
                  }}
                >
                  {isDeleteMode ? (
                    <Stack direction="row" gap={2} display={"flex"}>
                      <Box>
                        <ActionButton
                          color="red"
                          title={"Excluir serviço"}
                          action={() => {
                            modalAlertDeleteServico(servicoId);
                          }}
                          isSubmitAction={true}
                          icon={<DeleteIcon />}
                        />
                      </Box>
                      <Box>
                        <ActionButton
                          color={"blue"}
                          title={"Cancelar"}
                          to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`}
                          icon={<ArrowBackIcon />}
                        />
                      </Box>
                    </Stack>
                  ) : (
                    <Stack
                      width="100%"
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                    >
                      <ActionButton
                        title={servicoId ? "Atualizar" : "Criar"}
                        to={null}
                        color="blue"
                        icon={<BorderColorIcon />}
                        isSubmitAction={true}
                        action={() => {
                          handleSubmit(
                            values,
                            setSubmitting,
                            resetForm,
                            setFieldValue
                          );
                        }}
                      />
                    </Stack>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </JumboContentLayout>
      )}
    </Box>
  );
};
export default ServicosContratoForm;
