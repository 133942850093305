import React from "react";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Tooltip } from "@mui/material";

const OrdenacaoLista = ({
  column,
  sortOrder,
  sortBy,
  setSortOrder,
  setSortBy,
}) => {
  const isColunaIgualSortBy = column === sortBy;

  const handleOrdenacaoClick = () => {
    setSortBy(column); 
    setSortOrder(sortOrder === "desc" ? "asc" : "desc"); 
  };

  return (
    <>
      {!isColunaIgualSortBy ? (
        <SwapVertIcon
          sx={{ color: "#868686", cursor: "pointer" }}
          onClick={handleOrdenacaoClick}
        />
      ) : (
        <>
          {sortOrder === "asc" ? (
            <Tooltip title="Ordenado crescente">
              <ArrowDropUpIcon
                sx={{ color: "#95A64A", cursor: "pointer" }}
                onClick={handleOrdenacaoClick}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Ordenado decrescente">
              <ArrowDropDownIcon
                sx={{ color: "#95A64A", cursor: "pointer" }}
                onClick={handleOrdenacaoClick}
              />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default OrdenacaoLista;
