import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const ContratoDetalhesServicos = ({
  contrato,
  mostrarSomenteTarefaEStatus = false,
}) => {
  const uniqueProcessos = new Set();

  return (
    <>
      <Grid item xs={12} marginTop={6}>
        <Paper
          width="100%"
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            gap: 1,
            backgroundColor: "#4E5526",
            paddingLeft: 4,
          }}
        >
          <Typography
            variant={"h2"}
            fontWeight={600}
            color="#FFF"
            margin={0}
            fontSize={32}
          >
            Processos Administrativos
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {contrato?.processos_contratos &&
        contrato.processos_contratos.length > 0 ? (
          <>
            {contrato.processos_contratos.map((processo, index) => {
              return processo?.processo_administrativo?.Servico?.map(
                (servico, indexServico) => {
                  const processoServicoId = `${processo.id}-${servico.id}`;

                  if (!uniqueProcessos.has(processoServicoId)) {
                    uniqueProcessos.add(processoServicoId);

                    return (
                      <>
                        {indexServico === 0 && (
                          <>
                            <Grid container spacing={1}>
                              <Grid item xs={8}>
                                <Paper
                                  width="100%"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    p: 1,
                                    gap: 1,
                                    backgroundColor: "#95A64A",
                                    paddingLeft: 4,
                                  }}
                                >
                                  <Typography
                                    variant={"h2"}
                                    color="#FFF"
                                    margin={0}
                                    fontSize={20}
                                  >
                                    {processo?.processo_administrativo
                                      ?.descricao || "Descrição indisponível"}
                                  </Typography>
                                </Paper>
                              </Grid>
                              <Grid item xs={4}>
                                <Paper
                                  width="100%"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    p: 1,
                                    gap: 1,
                                    backgroundColor: "#95A64A",
                                    paddingLeft: 4,
                                  }}
                                >
                                  <Typography
                                    variant={"h2"}
                                    color="#FFF"
                                    margin={0}
                                    fontSize={20}
                                  >
                                    {processo?.imovel?.descricao}
                                  </Typography>
                                </Paper>
                              </Grid>
                            </Grid>

                            <Card
                              sx={{
                                p: 2,
                                width: "100%",
                                minHeight: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  item
                                  lg={mostrarSomenteTarefaEStatus ? 9 : 5}
                                >
                                  <Box>
                                    <Typography fontSize={20} fontWeight={600}>
                                      Tarefa
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  lg={mostrarSomenteTarefaEStatus ? 3 : 2}
                                >
                                  <Box>
                                    <Typography fontSize={20} fontWeight={600}>
                                      Status
                                    </Typography>
                                  </Box>
                                </Grid>
                                {mostrarSomenteTarefaEStatus === false ? (
                                  <>
                                    <Grid item lg={3}>
                                      <Box>
                                        <Typography
                                          fontSize={20}
                                          fontWeight={600}
                                        >
                                          Setor responsável
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item lg={2}>
                                      <Box>
                                        <Typography
                                          fontSize={20}
                                          fontWeight={600}
                                        >
                                          Colaboradores
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </>
                                ) : null}
                              </Grid>
                            </Card>
                          </>
                        )}

                        <Card
                          sx={{
                            p: 2,
                            width: "100%",
                            minHeight: 100,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item lg={mostrarSomenteTarefaEStatus ? 9 : 5}>
                              <Box>
                                <Typography fontSize={16}>
                                  {servico?.tarefas_servico?.[0]?.descricao ??
                                    "Nenhuma tarefa"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={mostrarSomenteTarefaEStatus ? 3 : 2}>
                              <Box>
                                <Typography fontSize={16}>
                                  {servico?.status_andamento_servico
                                    ?.descricao || "Status não disponível"}
                                </Typography>
                              </Box>
                            </Grid>
                            {mostrarSomenteTarefaEStatus === false ? (
                              <>
                                <Grid item lg={3}>
                                  <Box>
                                    <Typography fontSize={16}>
                                      {servico?.setor_responsavel?.descricao ||
                                        "Setor não disponível"}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item lg={2}>
                                  <Box>
                                    <Typography fontSize={16}>
                                      {servico?.servicocolaborador?.length > 0
                                        ? servico.servicocolaborador
                                            .map((colab) => colab.users?.name)
                                            .join(", ")
                                        : "Colaboradores não disponíveis"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </Card>
                      </>
                    );
                  }

                  return null;
                }
              );
            })}
          </>
        ) : (
          <Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
            Nenhum serviço cadastrado para esse contrato.
          </Stack>
        )}
      </Grid>
    </>
  );
};

export default ContratoDetalhesServicos;
