import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { v4 } from "uuid";
import ModalEditarDocumentoLicenca from "../ModalEditarDocumentoLicenca/ModalEditarDocumentoLicenca";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const DocumentoLicencaWrapper = ({
  documento,
  atualizarUnidade,
  handleDeleteDocumentoUnidade,
  userId,
}) => {
  const [documentosComModalAberto, setDocumentosComModalAberto] = useState({});
  const [
    isModalAdicionarDocumentosAberto,
    setIsModalAdicionarDocumentosAberto,
  ] = useState(false);
  const [idDocumentoSelecionado, setIdDocumentoSelecionado] = useState([]);
  const Swal = useSwalWrapper();

  const modalAlert = (id, acao, item) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "excluir" : "arquivar"
      }?`,
      text: `Não será póssível reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "deletar" && item === "documento") {
          handleDeleteDocumentoUnidade(id);
        }
      }
    });
  };

  const handleOpenModalEdicaoDocumento = (idDocumento) => {
    setIdDocumentoSelecionado(idDocumento);
    setDocumentosComModalAberto({
      ...documentosComModalAberto,
      [idDocumento]: true,
    });
  };

  const handleCloseModalEdicaoDocumento = (idDocumento) => {
    setDocumentosComModalAberto({
      ...documentosComModalAberto,
      [idDocumento]: false,
    });
  };
  return (
    <>
      <Box
        key={v4()}
        width={"100%"}
        p={2}
        my={1}
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          border: "1px solid #D7D7D7",
          cursor: "pointer",
          "&:hover": {
            background: "#F9F9F9",
          },
        }}
      >
        <Stack direction={"column"}>
          <Typography sx={{ fontWeight: 600 }}>
            {documento.identificacao}
          </Typography>
          <Typography sx={{ color: "#A5A5A5" }}>
            {" "}
            {documento.observacao ?? ""}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <EditIcon
            color="info"
            onClick={() => handleOpenModalEdicaoDocumento(documento.id)}
          />
          <DeleteIcon
            color="error"
            onClick={() => {
              modalAlert(documento.id, "deletar", "documento");
            }}
          />
        </Stack>
      </Box>
      <ModalEditarDocumentoLicenca
        aberto={documentosComModalAberto[documento.id] || false}
        atualizarUnidade={atualizarUnidade}
        documentoId={documento.id}
        handleClose={() => handleCloseModalEdicaoDocumento(documento.id)}
        documento={documento}
        userId={userId}
      />
    </>
  );
};

export default DocumentoLicencaWrapper;
