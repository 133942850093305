import React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
const BotaoVoltarBreadCrumbs = ({ handleBack }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "2px",
        margin: "10px 0",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 500,
        color: "#525252",
      }}
      onClick={handleBack}
    >
      <ArrowLeftIcon
        style={{
          color: "#95A64A",
          fontSize: "32px",
        }}
      />{" "}
      Voltar
    </div>
  );
};

export default BotaoVoltarBreadCrumbs;
