import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import authServices from "../../services/auth-services";

import backgroundLogin from "../../assets/img/bg_login_ambiental.png";
import logoAmbiental from "../../assets/img/login_logo_ambiental.png";

const validationSchema = yup.object({
  email: yup
    .string("Insira um email válido")
    .email("Email inválido")
    .required("Preenchimento obrigatório"),
  senha: yup.string().required("Preenchimento obrigatório"),
});

const Login = ({ disableSmLogin }) => {
  const { setAuthToken } = useJumboAuth();

  const navigate = useNavigate();

  const Swal = useSwalWrapper();

  const [showPassword, setShowPassword] = useState(false);

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
    });
  };

  const onSignIn = async (email, password, setSubmitting) => {
    try {
      const data = await authServices.signIn({ email, password });
      setAuthToken(
        data?.access_token,
        data.username,
        data.email,
        data.id,
        data.avatarUrl,
        data.permissoes,
        data.role_id,
        data.cliente_id
      );
      navigate("/");

      setSubmitting(false);
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.message
      ) {
        toast("error", err.response.data.message);
      } else if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        // Aqui você pode tratar outros erros ou exibir uma mensagem padrão
        console.log(err);
        toast("error", "Ocorreu um erro ao fazer login.");
      }

      setSubmitting(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Div
      style={{
        backgroundImage: `url(${backgroundLogin})`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      />
      <Div
        sx={{
          width: 600,
          maxWidth: "100%",
          margin: "auto",
          p: 2,
          zIndex: 10,
        }}
      >
        <Card
          sx={{
            display: "flex",
            minWidth: 0,
            flexDirection: "column",
            backgroundColor: "#4E5526",
            zIndex: 10,
            padding: 2,
            borderRadius: 5
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              zIndex: 3,
            }}
          >
            <img
              src={logoAmbiental}
              alt="Ambiental Gestão"
              width={"250px"}
              height={"auto"}
            />
          </CardContent>
          <CardContent sx={{ flex: 1, p: 2 }}>
            <Formik
              validateOnChange={true}
              initialValues={{
                emailOrCpf: "",
                senha: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                onSignIn(data.email, data.senha, setSubmitting);
              }}
            >
              {({
                isSubmitting,
                handleChange,
                values,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Div sx={{ mt: 1, mb: 3 }}>
                    <JumboTextField
                      fullWidth
                      name="email"
                      label="Email"
                      variant="filled"
                      sx={{
                        background: "#ffffff",
                        borderRadius: "10px",
                        "::placeholder": {
                            color: 'red'
                        }
                      }}
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      id="senha"
                      type={showPassword ? "text" : "password"}
                      name="senha"
                      value={values.senha}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{
                                marginRight: "10px",
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff style={{color: '#000000'}} />
                              ) : (
                                <Visibility style={{color: '#000000'}} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        background: "#ffffff",
                        borderRadius: "10px",
                        "&::placeholder": {
                            color: 'red'
                        }
                      }}
                      label="Senha"
                      variant="filled"
                    />
                  </Div>
                  {/* 
                                    <Div
                                        sx={{
                                            mb: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Link
                                            to="/esqueci-senha"
                                            style={{ color: "#fff" }}
                                        >
                                            Esqueceu a senha? Clique aqui
                                        </Link>
                                    </Div>
                                 */}
                  <Typography
                    my={2}
                    sx={{
                      color: "#fff",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      navigate("/esqueceu-senha");
                    }}
                  >
                    Esqueceu sua senha?
                  </Typography>
                  <LoadingButton
                    color="info"
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      mb: 3,
                      backgroundColor: "#95A64A",
                      borderRadius: "10px",
                      padding: 1,
                      width: "300px",
                      height: "60px",
                      display: "block",
                      margin: "0 auto",
                      "&:hover": {
                        backgroundColor: "#7C8B3F",
                      },
                    }}
                    loading={isSubmitting}
                  >
                    Acessar
                  </LoadingButton>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Div>
    </Div>
  );
};

export default Login;
