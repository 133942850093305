import React, { useState, useCallback, useEffect, useContext } from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
  TextField,
  InputAdornment,
  Card,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ApiService } from "app/services/config";
import { Link, useLocation } from "react-router-dom";
import UserItem from "./UserItem";
import MUIDataTable from "mui-datatables";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import PaginationComponent from "app/components/Pagination/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import BotoesExportarDados from "app/components/BotoesExportarDados";

const UserList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isTodosUsuariosSelecionados, setIsTodosUsuariosSelecionados] =
    useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const navigate = useNavigate();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [inputValue, setInputValue] = useState("");
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const ROLE_ID = Number(localStorage.getItem("role_id"));
  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Colaboradores", "read")) {
    navigate("/app");
  }
  const permission = hasPermission("Colaboradores", "create");
  const permissionUpdate = hasPermission("Colaboradores", "update");
  const permissionDelete = hasPermission("Colaboradores", "delete");
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const usuariosQuePodemSerSelecionadosPorFinanceiro = [6, 7];
  const usuariosQuePodemSerSelecionadosPorAdministrador = [6];
  const usuariosQuePodemSerSelecionadosPorColaborador = [];
  const isUsuarioFinanceiro = ROLE_ID === 5;
  const isUsuarioAdministrador = ROLE_ID === 7;
  const isUsuarioColaborador = ROLE_ID === 6;

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteUser(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const deleteUser = useCallback(async (id) => {
    try {
      ApiService.delete(`/users/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            setPerPage(10);
            setPage(0);
            setForceUpdate((prev) => !prev);
            getUsers();
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getUsers = useCallback(async () => {
    try {
      let apiUrl = `/users?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.users.map((user) => ({
            ...user,
            isSelected: selectedUserIds.includes(user.id),
          }));
          setUsers(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedUserIds]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleActivate = (userId) => {
    try {
      ApiService.post("/users/active", { values: userId }).then(() => {
        toast("success", "Ativado com sucesso");
        getUsers();
      });
    } catch (error) {
      toast("error", error.response.data.message);
    }
  };

  const handleInactivate = (userId) => {
    try {
      ApiService.post("/users/inactive", { values: userId }).then(() => {
        toast("success", "Inativado com sucesso");
        getUsers();
      });
    } catch (error) {
      toast("error", error.response.data.message);
    }
  };

  /**
   * Esta função é chamada quando o checkbox associado a um usuário específico é alterado.
   * 1. Atualiza o estado dos usuários:
   *    - Para o usuário com o ID correspondente, inverte o valor da propriedade 'isSelected' (de true para false ou vice-versa).
   * 2. Gerencia os IDs dos usuários selecionados:
   *    - Se o ID do usuário já estiver na lista de IDs selecionados, ele é removido da lista.
   *    - Caso contrário, o ID do usuário é adicionado à lista de IDs selecionados.
   * Dessa forma, esta função garante que, ao marcar ou desmarcar um usuário, tanto a visualização da seleção na UI quanto a lista interna de IDs selecionados sejam atualizadas corretamente.
   */
  const handleCheckboxChange = (userId) => {
    const userChecked = users.find((user) => user.id === userId);
    const userCheckedRoleId = userChecked?.roles[0]?.Role?.id;

    if (
      ROLE_ID === 1 ||
      (isUsuarioAdministrador &&
        usuariosQuePodemSerSelecionadosPorAdministrador.includes(
          userCheckedRoleId
        )) ||
      (isUsuarioFinanceiro &&
        usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
          userCheckedRoleId
        )) ||
      (isUsuarioColaborador &&
        usuariosQuePodemSerSelecionadosPorColaborador.includes(
          userCheckedRoleId
        ))
    ) {
      const updatedUsers = users.map((user) =>
        user.id === userId ? { ...user, isSelected: !user.isSelected } : user
      );
      setUsers(updatedUsers);

      const selectedUsers = updatedUsers.filter((user) => user.isSelected);
      setSelectedUserIds(selectedUsers.map((user) => user.id));

      setMostrarBotaoDeletarMuitos(selectedUsers.length > 0);
      setIsTodosUsuariosSelecionados(
        selectedUsers.length === users.filter((user) => user.isSelected).length
      );
    }
  };

  const handleDeleteMuitosUsuarios = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os usuários selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosUsuarios();
      }
    });
  };

  const deleteMuitosUsuarios = useCallback(async () => {
    try {
      await ApiService.put("/users/delete/many/users", selectedUserIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Usuários removidos com sucesso!");
            getUsers();
            setSelectedUserIds([]);
            setPage(0);
            setMostrarBotaoDeletarMuitos(false);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar os usuários selecionados");
      console.log(error);
    }
  }, [selectedUserIds]);

  const handleSelecionarTodosUsuarios = () => {
    const allSelected = !isTodosUsuariosSelecionados;

    let usuariosFiltrados = users;
    if (ROLE_ID !== 1) {
      switch (ROLE_ID) {
        case 5:
          usuariosFiltrados = users?.filter((usuario) =>
            usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
              usuario?.roles[0]?.Role?.id
            )
          );
          break;
        case 7:
          usuariosFiltrados = users?.filter((usuario) =>
            usuariosQuePodemSerSelecionadosPorAdministrador.includes(
              usuario?.roles[0]?.Role?.id
            )
          );
          break;
        default:
          usuariosFiltrados = [];
      }
    }

    const updatedUsers = users.map((user) => ({
      ...user,
      isSelected:
        ROLE_ID === 1
          ? allSelected
          : usuariosFiltrados.some(
              (filteredUser) => filteredUser.id === user.id
            ),
    }));

    setUsers(updatedUsers);

    const todosIdsUsers = allSelected
      ? usuariosFiltrados.map((user) => user.id)
      : [];
    setSelectedUserIds(todosIdsUsers);
    setMostrarBotaoDeletarMuitos(todosIdsUsers.length > 0);
    setIsTodosUsuariosSelecionados(allSelected);
  };

  return (
    <JumboContentLayout
      header={
        <PageHeader
          title={"Colaboradores"}
          icon="desc"
          btn={
            <ActionButton
              title="Novo colaborador"
              to={permission ? "/app/novo-colaborador" : null}
              color="green"
              icon={<QueueIcon />}
              disabled={!permission}
            />
          }
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <React.Fragment>
          <Grid
            container
            gap={1}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Div
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Pesquisar..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: 2,
                      border: "1px solid #4E5526",
                      paddingLeft: 2,
                      height: "36px",
                      "&:hover": { borderColor: "#3B4220" },
                      "&.Mui-focused": { borderColor: "#4E5526" },
                      color: "#4E5526",
                      "&::placeholder": { color: "red", opacity: 1 },
                      width: 350,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            backgroundColor: "#4E5526",
                            borderRadius: 2,
                            minWidth: "36px",
                            height: "36px",
                            padding: 0,
                            "&:hover": { backgroundColor: "#3B4220" },
                          }}
                          onClick={() => handleSearch(inputValue)}
                        >
                          <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {selectedUserIds.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteMuitosUsuarios}
                    sx={{
                      borderRadius: 2,
                      paddingX: 2,
                      backgroundColor: "#C84E4D",
                      textTransform: "none",
                    }}
                  >
                    Excluir {selectedUserIds.length} usuário(s)
                  </Button>
                )}
                <BotoesExportarDados
                  titulo={"Colaboradores"}
                  colunas_planilha={[
                    "id",
                    "name",
                    "email",
                    "status",
                    "roles",
                    "createdAt",
                  ]}
                  dados_planilha={users}
                  nome_arquivo_planilha={"colaboradores_"}
                />
              </Box>
            </Div>

            <Card
              sx={{
                p: 2,
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <Grid
                container
                // justifyContent="space-between"
                alignItems="center"
              >
                <Grid item lg={1}>
                  <Checkbox
                    sx={{
                      color: "#696969",
                      "&.Mui-checked": {
                        color: "#95A64A",
                      },
                    }}
                    onChange={handleSelecionarTodosUsuarios}
                  />
                </Grid>
                <Grid item lg={3} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Nome
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      E-mail
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Tipo de usuário
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={1} sx={{ display: "flex" }}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Status
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} sx={{ display: 'flex' }} pl={2}>
                  <Box>
                    <Typography fontSize={24} fontWeight={600}>
                      Ações
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <React.Fragment>
            <Grid container gap={1}>
              {users.length > 0 ? (
                <>
                  {users.map((c, key) => (
                    <Card
                      sx={{
                        p: 2,
                        width: "100%",
                        minHeight: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={key}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        // gap={2}
                      >
                        <Grid item lg={1}>
                          <Checkbox
                            sx={{
                              color: "#696969",
                              "&.Mui-checked": {
                                color: "#95A64A",
                              },
                            }}
                            key={c.id}
                            checked={c.isSelected}
                            onChange={() => handleCheckboxChange(c.id)}
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18} sx={{ display: "flex" }}>
                              {c.name}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item lg={3}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18} sx={{ display: "flex" }}>
                              {c.email}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item lg={2}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography fontSize={18} sx={{ display: "flex" }}>
                              {c.roles.map((role) => role.Role.name).join(", ")}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item lg={1}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* <Typography fontSize={18} sx={{ display: 'flex' }}>
                            {c.status}
                          </Typography> */}
                            <Chip
                              label={
                                c.status == "ativo" || c.status == true
                                  ? "Ativo"
                                  : "Inativo"
                              }
                              color={
                                c.status == "ativo" || c.status == true
                                  ? "success"
                                  : "error"
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={2} sx={{ display: 'flex' }} pl={2}>
                          <Box gap={"1rem"}>
                            {c.status === "inativo" ? (
                              <Tooltip title="Ativar usuário">
                                <CheckIcon
                                  color="info"
                                  variant="contained"
                                  size="small"
                                  sx={{ cursor: "pointer" }}
                                  disableElevation
                                  onClick={() => {
                                    const hasPermission =
                                      ROLE_ID === 1 ||
                                      (isUsuarioFinanceiro &&
                                        usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
                                          c.roles[0]?.Role?.id
                                        )) ||
                                      (isUsuarioAdministrador &&
                                        usuariosQuePodemSerSelecionadosPorAdministrador.includes(
                                          c.roles[0]?.Role?.id
                                        )) ||
                                      (isUsuarioColaborador &&
                                        usuariosQuePodemSerSelecionadosPorColaborador.includes(
                                          c.roles[0]?.Role?.id
                                        ));

                                    if (hasPermission) {
                                      handleActivate([c.id]);
                                    }
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Desativar usuário">
                                <BlockIcon
                                  color="error"
                                  variant="contained"
                                  size="small"
                                  sx={{ cursor: "pointer" }}
                                  disableElevation
                                  onClick={() => {
                                    const hasPermission =
                                      ROLE_ID === 1 ||
                                      (isUsuarioFinanceiro &&
                                        usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
                                          c.roles[0]?.Role?.id
                                        )) ||
                                      (isUsuarioAdministrador &&
                                        usuariosQuePodemSerSelecionadosPorAdministrador.includes(
                                          c.roles[0]?.Role?.id
                                        )) ||
                                      (isUsuarioColaborador &&
                                        usuariosQuePodemSerSelecionadosPorColaborador.includes(
                                          c.roles[0]?.Role?.id
                                        ));

                                    if (hasPermission) {
                                      handleInactivate([c.id]);
                                    }
                                  }}
                                />
                              </Tooltip>
                            )}

                            <Tooltip title="Editar">
                              <EditIcon
                                cursor={
                                  permissionUpdate ? "pointer" : "not-allowed"
                                }
                                color="success"
                                onClick={() => {
                                  if (permissionUpdate) {
                                    navigate(`/app/editar-colaborador/${c.id}`);
                                  }
                                }}
                              />
                            </Tooltip>

                            <Tooltip title="Excluir">
                              <DeleteIcon
                                cursor={
                                  permissionDelete ? "pointer" : "not-allowed"
                                }
                                color="error"
                                style={{ opacity: permissionDelete ? 1 : 0.5 }}
                                onClick={() => {
                                  if (permissionDelete) {
                                    modalAlert(c.id);
                                  }
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <Grid container>
                  <Grid item style={{ width: "100%" }}>
                    <Box my={5} textAlign={"center"}>
                      <Typography>Nenhum colaborador cadastrado.</Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <PaginationComponent
                page={page}
                setPage={setPage}
                count={count}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Grid>
          </React.Fragment>
        </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};

export default UserList;
