import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Alert,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import { Select as SelectMUI } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CreatableSelect from "react-select/creatable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ModalCadastroCliente from "app/components/ModalCadastroCliente/ModalCadastroCliente";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CurrencyInput from "react-currency-input-field";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import { v4 as uuidv4 } from "uuid";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ActionButton from "app/components/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutoCompleteAmbiental from "app/components/AutoCompleteAmbiental";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchemaEdit = yup.object().shape({
  descricao: yup.string().nullable(),
  situacao: yup.string().required("Campo obrigatório"),
  clienteId: yup.number().required("Campo obrigatório"),
  tipocontratocontrato: yup.array().optional(),
  elaboradores_orcamento_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 elaborador"),
  orientadores_contrato_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 orientador"),
  status_orcamento_id: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório"),
  unidades_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 unidade"),
  tipoOrcamentoId: yup.number().required("Campo obrigatório"),
  situacao: yup.string().required("Campo obrigatório"),
  dataVencimento: yup.date().optional().typeError("Campo obrigatório"),
  prazoRenovacao: yup.date().optional().typeError("Campo obrigatório"),
});

const validationSchema = yup.object().shape({
  descricao: yup.string().nullable(),
  situacao: yup.string().required("Campo obrigatório"),
  clienteId: yup.number().required("Campo obrigatório"),
  tipocontratocontrato: yup.array().optional(),
  elaboradores_orcamento_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 elaborador"),
  orientadores_contrato_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 orientador"),
  unidades_id: yup
    .array("")
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 unidade"),
  status_orcamento_id: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório"),
  tipoOrcamentoId: yup.number().required("Campo obrigatório"),
  dataVencimento: yup.date().optional().typeError("Data inválida"),
  prazoRenovacao: yup.date().optional().typeError("Data inválida"),
  data_aprovacao: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .when("status_orcamento_id", {
      is: (status) => status === 1,
      then: yup.date().optional().typeError("Data inválida"),
      otherwise: yup.date().nullable(),
    }),
});

const ContratoForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const initialValues = {
    descricao: "",
    clienteId: "",
    elaboradores_orcamento_id: [],
    orientadores_contrato_id: [],
    unidades_id: [],
    status_orcamento_id: 5, // O status padrão informado pela Ambiental é 'Aguardando Resposta'
    tipoOrcamentoId: "",
    situacao: "padrao",
    tipocontratocontrato: [],
    dataVencimento: "",
    prazoRenovacao: "",
    colaborador_que_criou_id: Number(userId),
    data_aprovacao: "",
  };

  const { id } = useParams();
  const { theme } = useJumboTheme();
  const formRef = useRef();
  const colaboradorId = Number(localStorage.getItem("id"));
  const [contrato, setContrato] = useState(initialValues);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [centroDeCustos, setCentroDeCustos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [tipoOrcamentos, setTiposOrcamentos] = useState([]);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [setores, setSetores] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [isContratoFixo, setIsContratoFixo] = useState(false);
  const [clienteIdSelecionado, setClienteIdSelecionado] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const Swal = useSwalWrapper();
  const location = useLocation();

  const [novoNomeCliente, setNovoNomeCliente] = useState("");
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleOpenModal = () => {
    setModalAberto(true);
  };

  const handleCloseModal = (idClienteCriado) => {
    setContrato((prevContrato) => ({
      ...prevContrato,
      clienteId: idClienteCriado,
    }));
    setModalAberto(false);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getContrato = useCallback(async () => {
    try {
      await ApiService.get(`/contratos/${id}`).then((response) => {
        if (response.status === 200) {
          const {
            Cliente,
            descricao,
            dataVencimento,
            prazoRenovacao,
            situacao,
            tipocontratocontrato,
            elaboradores_orcamento,
            orientadores,
            unidades,
            tipo_orcamento,
            colaborador_que_criou,
            status_orcamentario,
            data_aprovacao,
          } = response.data;

          const clienteId = Cliente?.id;
          const colaboradorId = colaborador_que_criou?.id;
          const elaboradoresOrcamentoId = elaboradores_orcamento?.map(
            (elaborador) => elaborador?.usuario?.id
          );
          const orientadoresId = orientadores?.map(
            (orientador) => orientador?.usuario?.id
          );
          const unidadesId = unidades?.map((unidade) => unidade.id);
          const tipoOrcamentoId = tipo_orcamento?.id;

          let contratoAtualizacao = {
            descricao,
            clienteId: Number(clienteId),
            elaboradores_orcamento_id: elaboradoresOrcamentoId,
            orientadores_contrato_id: orientadoresId,
            unidades_id: unidadesId,
            status_orcamento_id: status_orcamentario?.id,
            tipoOrcamentoId,
            situacao,
            tipocontratocontrato,
            dataVencimento: dataVencimento
              ? moment
                  .parseZone(response?.data?.dataVencimento)
                  .format("yyyy-MM-DD")
              : "",
            prazoRenovacao: prazoRenovacao
              ? moment
                  .parseZone(response?.data?.prazoRenovacao)
                  .format("yyyy-MM-DD")
              : "",
            colaborador_que_criou_id: Number(colaboradorId),
            data_aprovacao: data_aprovacao
              ? moment.parseZone(data_aprovacao).format("yyyy-MM-DD")
              : null,
          };

          setClienteIdSelecionado(Cliente?.id);

          if (
            response.data?.tipocontratocontrato?.some(
              (tipoContrato) =>
                tipoContrato.descricao === "CONTRATO FIXO" ||
                (tipoContrato.tipos_contratos &&
                  tipoContrato.tipos_contratos.descricao === "CONTRATO FIXO")
            )
          ) {
            setIsContratoFixo(true);
          }
          if (
            response.data?.tipocontratocontrato?.some(
              (tipoContrato) =>
                tipoContrato.descricao === "RENOVAÇÃO" ||
                (tipoContrato.tipos_contratos &&
                  tipoContrato.tipos_contratos.descricao === "RENOVAÇÃO")
            )
          ) {
            contratoAtualizacao = {
              ...contratoAtualizacao,
              dataVencimento: dataVencimento
                ? moment
                    .parseZone(response?.data?.dataVencimento)
                    .format("yyyy-MM-DD")
                : "",
              prazoRenovacao: prazoRenovacao
                ? moment
                    .parseZone(response?.data?.prazoRenovacao)
                    .format("yyyy-MM-DD")
                : "",
            };
          }
          setContrato(contratoAtualizacao);
        }
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getCentroDeCustos = useCallback(async () => {
    try {
      await ApiService.get("/centrosdecustos/all").then((response) => {
        setCentroDeCustos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico/all").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento/all").then((response) => {
        if (response.status === 200) {
          const status = response.data?.filter(
            (statusOrcamento) =>
              String(statusOrcamento?.descricao).toLowerCase() !==
              "finalizado completo"
          );
          setStatusOrcamentos(status);
        }
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getColaboradores = useCallback(async () => {
    try {
      await ApiService.get(
        "/users/colaboradores?filtroRoles=financeiro_admin"
      ).then((response) => {
        setColaboradores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/tipoorcamento/all").then((response) => {
        setTiposOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposContrato = useCallback(async () => {
    try {
      await ApiService.get("/tipocontrato/all").then((response) => {
        setTiposContrato(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getClientes = useCallback(async () => {
    try {
      await ApiService.get("/clientes/all").then((response) => {
        setClientes(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getUnidades = async () => {
    try {
      await ApiService.get(
        `/unidade/cliente/select/${clienteIdSelecionado}`
      ).then((response) => {
        if (response.status === 200) {
          setUnidades(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSetores = useCallback(async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        setSetores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setFieldValue
  ) => {
    if (Object.entries(formRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }

    if (values.tipocontratocontrato?.length === 0) {
      toast("warning", "Selecione um tipo de contrato!");
      return;
    }

    if (id) {
      const newValues = {
        ...values,
        colaborador_que_criou_id: colaboradorId,
      };
      if (
        values.tipocontratocontrato?.every(
          (tipoContrato) =>
            tipoContrato.descricao !== "RENOVAÇÃO" &&
            (!tipoContrato.tipos_contratos ||
              tipoContrato.tipos_contratos.descricao !== "RENOVAÇÃO")
        )
      ) {
        delete values.dataVencimento;
        delete values.dataRenovacao;
      }

      delete newValues.Cliente;
      delete newValues.cliente_id;
      delete newValues.tipo_orcamento;
      delete newValues.elaborador_orcamento;
      delete newValues.Imovel;
      delete newValues.id;
      delete newValues.createdAt;
      delete newValues.updatedAt;
      delete newValues.deletedAt;

      try {
        await ApiService.put(`/contratos/${id}`, newValues).then((response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso!");
            navigate("/app/listar-contratos");
          }
        });
      } catch (error) {
        console.log(error);
        toast("error", error.response?.data?.message);
      }
    } else {
      let newValues = {
        ...values,
        colaborador_que_criou_id: colaboradorId,
      };
      if (
        !id &&
        values?.tipocontratocontrato?.some((tipoContrato) =>
          String(tipoContrato?.descricao).toLowerCase().includes("fixo")
        )
      ) {
        newValues = {
          ...values,
          status_orcamento_id: 1,
        };
      }
      try {
        await ApiService.post("/contratos", newValues)
          .then((response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso");
              navigate("/app/listar-contratos");
              resetForm();
            }
          })
          .catch((error) => {
            console.log(error);

            toast("error", "Ocorreu um erro ao criar o contrato!");

            if (error.response.data) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    }
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteServico(id);
      }
    });
  };

  const handleDeleteServico = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso!");
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Criação de novos itens no CreateSelect

  const handleCreateCliente = (value) => {
    setNovoNomeCliente(value);
  };

  useEffect(() => {
    if (novoNomeCliente !== "") {
      handleOpenModal();
    }
  }, [novoNomeCliente]);

  const handleCreateTipoOrcamento = useCallback(async (descricao) => {
    const response = await ApiService.post("/tipoorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTiposOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const modalAlertDeleteContrato = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o contrato?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteContrato(id);
      }
    });
  };

  const deleteContrato = useCallback(async (id) => {
    try {
      ApiService.delete(`/contratos/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            navigate(`/app/listar-contratos`);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        getContrato();
      }
      await Promise.all([
        getCentroDeCustos(),
        getTiposServicos(),
        getProcessosAdministrativos(),
        getTarefas(),
        getStatusOrcamento(),
        getColaboradores(),
        getClientes(),
        getTiposContrato(),
        getTiposOrcamento(),
        getSetores(),
      ]);
      setDadosCarregados(true);
    };
    fetchData();
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  useEffect(() => {
    if (dadosCarregados) {
      setContrato((prevContrato) => ({
        ...prevContrato,
      }));
    }
  }, [dadosCarregados]);

  useEffect(() => {
    if (clienteIdSelecionado) {
      getUnidades();
    }
  }, [clienteIdSelecionado]);

  useEffect(() => {
    if (dadosCarregados && contrato) {
      const temPermissaoDeAcessarAPagina = hasPermission("Financeiro", "read");
      const contratoCriadoPeloUsuarioAtual =
        contrato?.colaborador_que_criou_id === userId;
      if (!temPermissaoDeAcessarAPagina && !contratoCriadoPeloUsuarioAtual) {
        navigate("/app");
      }
    }
  }, [dadosCarregados, contrato]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {dadosCarregados && (
        <JumboContentLayout layoutOptions={layoutOptions}>
          <BotaoVoltarBreadCrumbs
            handleBack={() => {
              navigate(`/app/listar-contratos`);
            }}
          />
          <PageHeader
            title={id ? `Editar contrato` : "Contratos > Cadastro de Contrato"}
            icon={
              <DescriptionIcon
                sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
              />
            }
          />
          {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o contrato abaixo:
              </Alert>
            </Stack>
          )}

          <Formik
            innerRef={formRef}
            initialValues={contrato}
            validationSchema={id ? validationSchemaEdit : validationSchema}
            enableReinitialize
            validateOnChange={false}
            validanteOnBlur
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleBlur,
              isSubmitting,
              handleChange,
              setFieldValue,
              resetForm,
              setSubmitting,
              errors,
              setFieldTouched,
              touched,
            }) => (
              <Form style={{ width: "100%" }} noValidate autoComplete="off">
                <Grid container width="100%" spacing={2}>
                  <Grid item xs={12}>
                    <FieldArray name="tipocontratocontrato" validateOnChange>
                      {({ form, push, remove }) => (
                        <>
                          <div>
                            <label style={{ fontWeight: "bold" }}>
                              Tipo de contrato*
                            </label>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {tiposContrato?.map((opcao) => {
                              const isChecked =
                                values?.tipocontratocontrato?.some(
                                  (item) => item.tipoContratoId === opcao.id
                                );

                              let isFixo = values.tipocontratocontrato?.some(
                                (tipoContrato) =>
                                  tipoContrato?.descricao === "CONTRATO FIXO" ||
                                  (tipoContrato?.tipos_contratos &&
                                    tipoContrato.tipos_contratos?.descricao ===
                                      "CONTRATO FIXO")
                              );
                              setIsContratoFixo(isFixo);

                              return (
                                <div key={uuidv4()}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled={isDeleteMode}
                                        checked={isChecked}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          setFieldTouched(
                                            "tipocontratocontrato",
                                            true
                                          );
                                        }}
                                        onChange={(e) => {
                                          const checked = e.target.checked;

                                          if (checked) {
                                            push({
                                              tipoContratoId: opcao.id,
                                              descricao: opcao.descricao,
                                            });
                                          } else {
                                            const idx =
                                              values.tipocontratocontrato.findIndex(
                                                (item) =>
                                                  item.tipoContratoId ===
                                                  opcao.id
                                              );
                                            if (idx !== -1) {
                                              remove(idx);
                                            }
                                          }
                                        }}
                                        sx={{
                                          "&.Mui-checked": {
                                            color: "#95A64A",
                                          },
                                        }}
                                      />
                                    }
                                    label={opcao.descricao}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </FieldArray>
                    {touched.tipocontratocontrato &&
                      errors.tipocontratocontrato && (
                        <div style={{ color: "red" }}>
                          {errors.tipocontratocontrato}
                        </div>
                      )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={6}>
                    <InputAmbiental
                      label={"Código do Contrato"}
                      required={true}
                      value={values.descricao}
                      onChange={handleChange}
                      handleBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("descricao", true);
                      }}
                      name={"descricao"}
                      fullWidth={true}
                    />
                    {touched.descricao && errors.descricao && (
                      <div style={{ color: "red" }}>{errors.descricao}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteAmbiental
                      fullWidth={true}
                      name="tipoOrcamentoId"
                      setFieldValue={setFieldValue}
                      handleCreateData={handleCreateTipoOrcamento}
                      nameOptionDescription={"descricao"}
                      nameOptionValue={"id"}
                      label={"Tipo do orçamento"}
                      value={values.tipoOrcamentoId}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("tipoOrcamentoId", true);
                      }}
                      options={tipoOrcamentos}
                      placeholder={"Selecione um tipo de orçamento"}
                    />
                    {touched.tipoOrcamentoId && errors.tipoOrcamentoId && (
                      <div style={{ color: "red" }}>
                        {errors.tipoOrcamentoId}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={6}>
                    <SelectAmbiental
                      name={"clienteId"}
                      value={values.clienteId}
                      onChange={(event) => {
                        const id = Number(event.target.value);
                        setClienteIdSelecionado(id);
                        setFieldValue("clienteId", id);
                        setFieldValue("unidades_id", []);
                        getUnidades();
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("clienteId", true);
                      }}
                      label={"Cliente"}
                      nameOptionDescription={"nome"}
                      nameOptionValue={"id"}
                      options={clientes}
                      fullWidth={true}
                    />
                    {touched.clienteId && errors.clienteId && (
                      <div style={{ color: "red" }}>{errors.clienteId}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <SelectAmbiental
                      name={"unidades_id"}
                      value={values.unidades_id}
                      onChange={(values) => {
                        setFieldValue("unidades_id", values);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("unidades_id", true);
                      }}
                      label={"Unidades"}
                      options={unidades}
                      nameOptionValue={"id"}
                      nameOptionDescription={"nomeUnidade"}
                      fullWidth={true}
                      isMulti={true}
                      required={true}
                    />
                    {touched.unidades_id && errors.unidades_id && (
                      <div style={{ color: "red" }}>{errors.unidades_id}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={6}>
                    <SelectAmbiental
                      name={"elaboradores_orcamento_id"}
                      value={values.elaboradores_orcamento_id}
                      onChange={(values) => {
                        setFieldValue("elaboradores_orcamento_id", values);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("elaboradores_orcamento_id", true);
                      }}
                      label={"Elaboradores do orçamento"}
                      nameOptionDescription={"name"}
                      nameOptionValue={"id"}
                      options={colaboradores}
                      fullWidth={true}
                      required={true}
                      isMulti={true}
                    />
                    {touched.elaboradores_orcamento_id &&
                      errors.elaboradores_orcamento_id && (
                        <div style={{ color: "red" }}>
                          {errors.elaboradores_orcamento_id}
                        </div>
                      )}
                  </Grid>
                  <Grid item xs={6}>
                    <SelectAmbiental
                      name={"orientadores_contrato_id"}
                      value={values.orientadores_contrato_id}
                      onChange={(values) => {
                        setFieldValue("orientadores_contrato_id", values);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("orientadores_contrato_id", true);
                      }}
                      label={"Orientadores"}
                      nameOptionDescription={"name"}
                      nameOptionValue={"id"}
                      options={colaboradores}
                      fullWidth={true}
                      required={true}
                      isMulti={true}
                    />
                    {touched.orientadores_contrato_id &&
                      errors.orientadores_contrato_id && (
                        <div style={{ color: "red" }}>
                          {errors.orientadores_contrato_id}
                        </div>
                      )}
                  </Grid>
                </Grid>
                <Grid container width="100%" spacing={2} my={1}>
                  <Grid item xs={6}>
                    <SelectAmbiental
                      name={"status_orcamento_id"}
                      value={
                        !id &&
                        values?.tipocontratocontrato?.some((tipoContrato) =>
                          String(tipoContrato?.descricao)
                            .toLowerCase()
                            .includes("fixo")
                        )
                          ? 1
                          : values.status_orcamento_id
                      }
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched("status_orcamento_id", true);
                      }}
                      onChange={(event) => {
                        setFieldValue(
                          "status_orcamento_id",
                          Number(event.target.value)
                        );
                      }}
                      label={"Status orçamentário"}
                      nameOptionDescription={"descricao"}
                      nameOptionValue={"id"}
                      options={statusOrcamentos}
                      fullWidth={true}
                      disabled={
                        isDeleteMode ||
                        (!id &&
                          values?.tipocontratocontrato?.some((tipoContrato) =>
                            String(tipoContrato?.descricao)
                              .toLowerCase()
                              .includes("fixo")
                          ))
                      }
                    />
                    {touched.status_orcamento_id &&
                      errors.status_orcamento_id && (
                        <div style={{ color: "red" }}>
                          {errors.status_orcamento_id}
                        </div>
                      )}
                  </Grid>
                  {(id && [1, 3, 7, 8].includes(values.status_orcamento_id)) ||
                  values.tipocontratocontrato?.some(
                    (tipo) =>
                      String(tipo?.descricao?.toLowerCase()) === "contrato fixo"
                  ) ? (
                    <Grid item xs={6}>
                      <InputAmbiental
                        name={"data_aprovacao"}
                        value={values.data_aprovacao}
                        onChange={handleChange}
                        handleBlur={(e) => {
                          handleBlur(e);
                          setFieldTouched("data_aprovacao", true);
                        }}
                        label={"Data de aprovação"}
                        fullWidth={true}
                        type="date"
                      />
                      {touched.data_aprovacao && errors.data_aprovacao && (
                        <div style={{ color: "red" }}>
                          {errors.data_aprovacao}
                        </div>
                      )}
                    </Grid>
                  ) : null}
                </Grid>
                {(Array.isArray(values.tipocontratocontrato) &&
                  values.tipocontratocontrato.some(
                    (tipoContrato) => tipoContrato.descricao === "RENOVAÇÃO"
                  )) ||
                values.tipocontratocontrato?.some(
                  (tipoContrato) =>
                    tipoContrato.tipos_contratos &&
                    tipoContrato.tipos_contratos.descricao === "RENOVAÇÃO"
                ) ? (
                  <>
                    <Box my={1}>
                      <h2>Dados de renovação</h2>
                      <Divider sx={{ my: 2 }} />
                    </Box>
                    <Grid container width="100%" spacing={2} my={1}>
                      <Grid item xs={3}>
                        <InputAmbiental
                          name={"dataVencimento"}
                          value={values.dataVencimento}
                          onChange={handleChange}
                          handleBlur={(e) => {
                            handleBlur(e);
                            setFieldTouched("dataVencimento", true);
                          }}
                          label={"Data de vencimento"}
                          fullWidth={true}
                          type="date"
                        />
                        {touched.dataVencimento && errors.dataVencimento && (
                          <div style={{ color: "red" }}>
                            {errors.dataVencimento}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputAmbiental
                          name={"prazoRenovacao"}
                          value={values.prazoRenovacao}
                          onChange={handleChange}
                          handleBlur={(e) => {
                            handleBlur(e);
                            setFieldTouched("prazoRenovacao", true);
                          }}
                          label={"Prazo de renovação"}
                          fullWidth={true}
                          type="date"
                        />
                        {touched.prazoRenovacao && errors.prazoRenovacao && (
                          <div style={{ color: "red" }}>
                            {errors.prazoRenovacao}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  my={3}
                  sx={{
                    position: "sticky",
                    right: 0,
                    bottom: 10,
                  }}
                >
                  {isDeleteMode ? (
                    <Stack direction="row" gap={2} display={"flex"}>
                      <Box>
                        <ActionButton
                          title="Excluir contrato"
                          to={null}
                          action={() => {
                            modalAlertDeleteContrato(id);
                          }}
                          isSubmitAction={false}
                          color="red"
                          icon={<DeleteIcon />}
                        />
                      </Box>
                      <Box>
                        <ActionButton
                          title="Cancelar"
                          to="/app/listar-contratos"
                          color="blue"
                          icon={<ArrowBackIcon />}
                        />
                      </Box>
                    </Stack>
                  ) : (
                    <ActionButton
                      title={id ? "Atualizar" : "Criar"}
                      to={null}
                      color="blue"
                      icon={<BorderColorIcon />}
                      isSubmitAction={true}
                      action={() => {
                        handleSubmit(
                          values,
                          setSubmitting,
                          resetForm,
                          setFieldValue
                        );
                      }}
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </JumboContentLayout>
      )}
    </Box>
  );
};

export default ContratoForm;
