import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import BadgeNomeCliente from "@jumbo/components/BadgeNomeCliente/BadgeNomeCliente";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import DescriptionIcon from "@mui/icons-material/Description";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ActionButton from "app/components/ActionButton/ActionButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import AutoCompleteAmbiental from "app/components/AutoCompleteAmbiental";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  data_submissao: yup
    .date("Campo obrigatório")
    .optional()
    .typeError("Data inválida"),
  processo_administrativo_id: yup
    .number()
    .required("Campo obrigatório")
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
  imovel_id: yup
    .number()
    .required("Campo obrigatório")
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
});

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ProcessosContratoForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const { contratoId, processoId, id, clienteId } = useParams();
  const initialValues = {
    status: 1, // Processo padrão é 'Não iniciado' conforme a RN da Ambiental
    contrato_id: Number(contratoId),
    processo_administrativo_id: "",
    imovel_id: "",
  };
  const [processoAdministrativo, setProcessosAdministrativo] =
    useState(initialValues);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [statusProcessosAdministrativos, setStatusProcessosAdministrativos] =
    useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [imoveis, setImoveis] = useState([]);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const location = useLocation();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const formRef = useRef();

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getImoveis = async () => {
    try {
      await ApiService.get(`/imoveis/cliente/${clienteId}/${contratoId}`).then(
        (response) => {
          if (response.status === 200) {
            setImoveis(response.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getProcessosAdministrativo = useCallback(async () => {
    try {
      ApiService.get(`/servicos/processo/${processoId}`)
        .then((response) => {
          setProcessosAdministrativo({
            ...response.data,
            data_submissao: response.data?.data_submissao
              ? moment.utc(response.data?.data_submissao).format("YYYY-MM-DD")
              : "",
            processo_administrativo_id:
              response.data?.processo_administrativo?.id,
            status:
              response.data?.processo_administrativo?.status[0]
                ?.status_processo_id,
            status_orcamentario:
              response.data?.contrato?.status_orcamentario?.descricao,
            imovel_id: response.data?.imovel?.id,
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getStatusProcessosAdministrativos = async () => {
    try {
      await ApiService.get("/status/processos/admin").then((response) => {
        if (response.status === 200) {
          setStatusProcessosAdministrativos(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateProcessoAdministrativo = useCallback(async (descricao) => {
    const response = await ApiService.post("/processosadmin", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getProcessosAdministrativos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const modalAlertDeleteProcesso = (processoId) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o processo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProcesso(processoId);
      }
    });
  };

  const deleteProcesso = useCallback(async (processoId) => {
    try {
      ApiService.delete(`/servicos/processo/${processoId}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(
            `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
          );
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (processoId) {
      getProcessosAdministrativo();
    }
    getImoveis();
    getProcessosAdministrativos();
    getStatusProcessosAdministrativos();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
    setIsDadosCarregados(true);
  }, [processoId]);

  const handleSubmit = async (
    values,
    setSubmitting,
    resetForm,
    setFieldValue
  ) => {
    if (Object.entries(formRef.current.errors)?.length > 0) {
      toast("warning", "Corrija os erros no formulário!");
      return;
    }
    const { processo_administrativo_id } = values;
    const newValues = {
      ...values,
      contrato_id: Number(contratoId),
      processo_administrativo_id,
      userId,
    };
    if (values.data_submissao && values.data_submissao !== "") {
      newValues.data_submissao = new Date(values.data_submissao).toISOString();
    }
    delete newValues.id;
    delete newValues.processo_administrativo;
    delete newValues.status_orcamentario;
    delete newValues.contrato;
    delete newValues.prioridade;
    delete newValues.descricao;
    delete newValues.imovel;
    if (processoId) {
      await ApiService.put(`/servicos/processo/${processoId}`, newValues).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso!");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
            );
          }
        }
      );
    } else {
      await ApiService.post("/servicos/processo", newValues)
        .then((response) => {
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.log(error);
          let message = error;
          toast("error", "Ocorreu um erro ao criar o processo!");

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
          const { data_aprovacao } = response.data;
          const dataAprovacao = moment.utc(data_aprovacao).format("YYYY-MM-DD");
          setProcessosAdministrativo((prevState) => ({
            ...prevState,
            data_submissao: data_aprovacao ? dataAprovacao : "",
          }));
          if (
            String(
              response.data?.status_orcamentario?.descricao
            ).toLowerCase() === "aprovado"
          ) {
            setProcessosAdministrativo((prevState) => ({
              ...prevState,
              status_orcamentario:
                response.data?.status_orcamentario?.descricao,
              data_submissao: dataAprovacao
                ? moment.utc(data_aprovacao).format("YYYY-MM-DD")
                : response?.data?.data_submissao
                ? moment.utc(response.data?.data_submissao).format("YYYY-MM-DD")
                : "",
            }));
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <Box sx={{ position: "relative" }}>
      <JumboContentLayout layoutOptions={layoutOptions}>
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}

        <BotaoVoltarBreadCrumbs
          handleBack={() => {
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos`
            );
          }}
        />
        <BadgeNomeCliente
          nome={breadCrumbsInfo?.descricao}
          linkTo={`/app/contrato/${contratoId}/detalhes`}
        />
        <PageHeader
          title={
            id && processoAdministrativo
              ? `${processoAdministrativo?.descricao} > Editar`
              : "Processos Administrativos > Adicionar Processo"
          }
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />

        {isDeleteMode && (
          <Stack width={"100%"} p={1}>
            <Alert severity="error">
              Você está prestes a excluir o processo administrativo abaixo:
            </Alert>
          </Stack>
        )}
        <Formik
          initialValues={processoAdministrativo}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            resetForm,
            setSubmitting,
            setFieldTouched,
            touched,
          }) => (
            <Form
              style={{
                width: "100%",
                minHeight: "50vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              noValidate
              autoComplete="off"
            >
              <Box>
                <Grid container alignContent={"center"} spacing={2}>
                  <Grid item xs={12}>
                    <AutoCompleteAmbiental
                      fullWidth={true}
                      handleCreateData={handleCreateProcessoAdministrativo}
                      label={"Processo administrativo"}
                      name={"processo_administrativo_id"}
                      nameOptionValue={"id"}
                      nameOptionDescription={"descricao"}
                      setFieldValue={setFieldValue}
                      options={processosAdministrativos}
                      placeholder={"Selecione um processo administrativo"}
                      value={values.processo_administrativo_id}
                      onBlur={(e) => {
                        setFieldTouched("processo_administrativo_id", true);
                        handleBlur(e);
                      }}
                    />
                    {touched.processo_administrativo_id &&
                      errors.processo_administrativo_id && (
                        <div style={{ color: "red" }}>
                          {errors.processo_administrativo_id}
                        </div>
                      )}
                  </Grid>
                </Grid>
                <Grid container alignContent={"center"} spacing={2} my={1}>
                  <Grid item xs={12}>
                    <SelectAmbiental
                      name={"imovel_id"}
                      options={imoveis}
                      label={"Imóvel"}
                      nameOptionValue={"id"}
                      nameOptionDescription={"descricao"}
                      onChange={(event) => {
                        setFieldValue("imovel_id", Number(event.target.value));
                      }}
                      onBlur={(e) => {
                        setFieldTouched("imovel_id", true);
                        handleBlur(e);
                      }}
                      value={values.imovel_id}
                      fullWidth={false}
                      width={100}
                    />
                    {touched.imovel_id && errors.imovel_id && (
                      <div style={{ color: "red" }}>{errors.imovel_id}</div>
                    )}
                  </Grid>
                </Grid>
                <Box my={2}>
                  <Typography>
                    <strong style={{ fontWeight: "bold" }}>
                      Data de Aprovação do Contrato:
                    </strong>{" "}
                    {breadCrumbsInfo?.status_orcamentario?.id === 1
                      ? moment
                          .utc(breadCrumbsInfo?.data_aprovacao)
                          .format("DD/MM/YYYY")
                      : "Contrato não aprovado"}
                  </Typography>
                </Box>
                <Box my={2} width={"100%"}>
                  {breadCrumbsInfo?.status_orcamentario?.id === 1 ? (
                    <>
                      <InputAmbiental
                        name={"data_submissao"}
                        type="date"
                        value={values.data_submissao}
                        onChange={handleChange}
                        handleBlur={(e) => {
                          setFieldTouched("data_submissao", true);
                          handleBlur(e);
                        }}
                        label={"Data de submissão"}
                        fullWidth={true}
                        width={20}
                      />
                      {touched.data_submissao && errors.data_submissao && (
                        <div style={{ color: "red" }}>
                          {errors.data_submissao}
                        </div>
                      )}
                    </>
                  ) : null}
                </Box>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                my={3}
                sx={{
                  position: "sticky",
                  right: 0,
                  bottom: 10,
                }}
              >
                {isDeleteMode ? (
                  <Stack direction="row" gap={2} display={"flex"}>
                    <Box>
                      <ActionButton
                        title={"Excluir processo"}
                        to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
                        color="red"
                        icon={<DeleteIcon />}
                        isSubmitAction={true}
                        action={() => {
                          modalAlertDeleteProcesso(processoId);
                        }}
                      />
                    </Box>
                    <Box>
                      <ActionButton
                        title={"Cancelar"}
                        to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
                        color="blue"
                        icon={<ArrowBackIcon />}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <Stack
                    width="100%"
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <ActionButton
                      title={processoId ? "Atualizar" : "Criar"}
                      to={null}
                      color="blue"
                      icon={<BorderColorIcon />}
                      isSubmitAction={true}
                      action={() => {
                        handleSubmit(
                          values,
                          setSubmitting,
                          resetForm,
                          setFieldValue
                        );
                      }}
                    />
                  </Stack>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </JumboContentLayout>
    </Box>
  );
};

export default ProcessosContratoForm;
