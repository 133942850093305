import { LoadingButton } from "@mui/lab";
import { darken, width } from "@mui/system";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const ActionButton = ({
  title = null,
  to = null,
  color = 'blue',
  icon = null,
  isSubmitAction = false,
  disabled = false,
  borderRadius = null,
  action = null,
  sx = null,
  fullWidth = false,
  width = null,
  height = null,
  maxWidth = null,
  marginTop = null
}) => {
  const location = useLocation();

  const buttonColor = {
    green: "#28A745",
    blue: "#027BFF",
    red: "#DC3545",
    pink: "#FFB6C2",
    gray: "#7D7D7D",
    yellow: "#FFCB3C",
    teal: "#4E8B93",
    olive: "#4E5526",
    khaki: "#B89E42",
    tomato: "#CC4D4C",
    sky: "#2FB5C9",
    dustyTeal: "#4C8D92",
    pdf: "#F30744"
  };

  const textColor = {
    pink: '#212529'
  }

  let buttonStyles = {
    maxWidth: { md: maxWidth ? `${maxWidth}` : "max-content", mt: "1rem" },
    width: fullWidth ? "100%" : width ? `${width}px` : "auto",
    textTransform: "none",
    borderRadius: borderRadius ? `${borderRadius}px` : 2,
    gap: 1,
    fontSize: 14,
    backgroundColor: disabled ? buttonColor['gray'] : buttonColor[color],
    "&:hover": {
      backgroundColor: disabled ? buttonColor['gray'] : darken(buttonColor[color], 0.2),
    },
    marginTop: marginTop ? `${marginTop}px` : 'auto',
    color: textColor[color] ?? '#fff',
    ...sx,
    ...(fullWidth && { width: "100%" }),
  };

  if(!title) {
    buttonStyles = {
      ...buttonStyles,
      width: width ? `${width}px` : '40px',
      height: height ? `${height}px` : '40px',
      minWidth: width ? `${width}px` : '40px',
      padding: 0,
      minWidth: 'unset',
    }
  }

  const buttonContent = (
    <>
      {title}
      {icon && React.cloneElement(icon, { sx: { fontSize: 16 } })}
    </>
  );

  if (to && !isSubmitAction) {
    return (
      <Link
        to={to}
        state={{ backUrl: location.pathname }}
        style={{ textDecoration: "none" }}
        sx={sx}
      >
        <LoadingButton type="submit" variant="contained" size="large" sx={buttonStyles}>
          {buttonContent}
        </LoadingButton>
      </Link>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      size="large"
      sx={buttonStyles}
      onClick={action}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {buttonContent}
    </LoadingButton>
  );
};

export default ActionButton;
