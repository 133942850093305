import { Box, Chip, Typography } from "@mui/material";


function Tag({ label, sx, color = "#4E5526", fontWeight = 600 }) {
   return (
    <Chip
        label={<Typography sx={{ fontWeight }}>{label}</Typography>}
        sx={{
            color: "white",
            background: color,
            borderRadius: 2,
            ...sx
        }}
    />
   )
}

export default Tag;
